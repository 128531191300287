import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ComponentExtend from '../ComponentExtend'


class MonthFilter extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      value: moment().format('MM/YYYY')
    }
    this.onChange = this.onChange.bind(this)
  }


  onChange(event) {
    this.setState({ value: event.target.value })
    this.props.onChange(event.target.value)
  }

  render() {
    const { userInfo } = this.props
    const { createdAt = '' } = userInfo
    // let length = 0
    // if (createdAt !== '') {
    //   length = moment().diff(moment(createdAt), 'months', true)
    // }
    console.log('createdAt', createdAt)
    const option = []
    let i = 0
    do {
      option.push(<option value={moment().subtract(i, 'month').format('MM/YYYY')} key={i}>{moment().subtract(i, 'month').format('MM/YYYY')}</option>)
      i++
      console.log(moment().subtract(i, 'months').format('YYYY-MM-DD'), moment(createdAt).format('YYYY-MM-DD'))
      console.log(moment().subtract(i, 'months').isAfter(moment(createdAt), 'month'))
    }
    while (i !== 6)
    return (
      <select value={this.state.value} onChange={this.onChange} name="month">
        {option}
      </select>
    )
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo
  }
}, mapDispatchToProps)(MonthFilter)
