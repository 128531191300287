import React from 'react'
import PropTypes from 'prop-types'
import ForgotSuccess from './ForgotSuccess'

class ForgotSuccessContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(prop) {
    super(prop)
    this.state = {}
  }

  render() {
    return (
      <ForgotSuccess
        translate={this.context.t}
      />
    )
  }
}


export default ForgotSuccessContainer
