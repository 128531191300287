/* GENERAL */
export const POST_REGISTER_CLIENT_SUCCESS = 1000
export const POST_CAPTCHA_SUCCESS = 1000

/* ACCOUNT */
export const POST_ACCOUNT_FORGOT_PASSWORD_SUCCESS = 1000
export const POST_ACCOUNT_LOGIN_SUCCESS = 1000

export const POST_ACCOUNT_LOGIN_NEXT_STEP = 2000

export const POST_ACCOUNT_LOGIN_STEP2_SUCCESS = 1000
export const POST_ACCOUNT_LOGOUT_SUCCESS = 1000
export const POST_ACCOUNT_REGISTER_SUCCESS = 1000
export const REQUEST_SUCCESS = 1000
export const REQUEST_FAIL = 1001

/* Error 401 */
export const VALID_ACCESS_TOKEN = 401
