import React from 'react'

const RefCode = (locals) => {
  const hasError = locals.hasError === true ? 'has-error' : ''
  const msg = locals.error ? <span className="help-block error-block">{locals.error}</span> : ''
  const label = locals.label ? locals.label : locals.attrs.name

  return (
    <div className={`eform-input eform-input-${locals.attrs.name} ${hasError} row`}>
      <div className="eform-label col-12 col-lg-4 nopadding">{label} <span>:</span></div>
      <div className="eform-desc col-12 col-lg-8 nopadding">
        <div className="eform-desc-inner">
          <input
            className="eform-input-item form-control placeholder-no-fix"
            autoComplete="off"
            value={locals.value}
            onChange={event => locals.onChange(event.target.value)}
            {...locals.attrs}
          />
        </div>
        {msg}
      </div>
      <div className="clearfix" />
    </div>
  )
}

export default RefCode
