import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as GeneralAction from '../../redux/general/general.actions'
import ComponentExtend from '../ComponentExtend'
import SidebarLeft from './SidebarLeft'

class SidebarLeftContainer extends ComponentExtend {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  componentWillMount() {
    console.log(11111111111)
    const { generalAct } = this.props
    generalAct.promotionList({
      limit: 5,
      page: 1
    }, true)
    generalAct.bannerList({
      limit: 2
    })
  }

  constructor(prop) {
    super(prop)
    this.state = {}
  }

  render() {
    return (
      <SidebarLeft
        translate={this.context.t}
        listHotPromotion={this.props.listHotPromotion}
        bannerList={this.props.bannerList}
      />
    )
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    listHotPromotion: rootState.generalReducer.listHotPromotion,
    bannerList: rootState.generalReducer.bannerList
  }
}, mapsDispatchToProp)(SidebarLeftContainer)
