import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
// import { Images } from '../../ui/assets/images'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    // const { translate } = this.props
    return (
      <footer className="clearfix">

        <div id="footer" className="footer-wrapper">
          <div className="row">

            <div id="copyright" className="copyright col-12 nopadding">
              <span>©Alo88</span>
            </div>

          </div>
        </div>

      </footer>
    )
  }
}

export default Footer
