import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Images } from '../../ui/assets/images'
import * as AccountsAction from '../../redux/accounts/accounts.actions'

class ActiveEmailContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      hasResult: false,
      isSucess: false
    }
  }

  renderSuccess = () => {
    const translate = this.context.t
    return (
      <div className="account-page register-page register-success-page clearfix">
        <div className="wrapper-inner">
          <div className="box-info">
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="info-left col-12 col-md-7 nopadding">
                <div className="info-title">
                  <ul>
                    <li>{translate('ActiveSuccess')}</li>
                  </ul>
                </div>
                <div className="info-content">
                  <div className="box-msg">
                    <div className="box-img"><img src={Images.logo} alt="" /></div>
                    <div className="box-info">Chúc mừng bạn đã kích hoạt tài khoản thành công.</div>
                    <div className="box-link">
                      <Link className="btn" to="/"><span className="text-uppercase">{translate('Home')}</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderFail = () => {
    const translate = this.context.t
    return (
      <div className="account-page register-page register-success-page clearfix">
        <div className="wrapper-inner">
          <div className="box-info">
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="info-left col-12 col-md-7 nopadding">
                <div className="info-title">
                  <ul>
                    <li>{translate('ActiveFail')}</li>
                  </ul>
                </div>
                <div className="info-content">
                  <div className="box-msg">
                    <div className="box-img"><img src={Images.logo} alt="" /></div>
                    <div className="box-info">Kích hoạt thất bại.</div>
                    <div className="box-link">
                      <Link className="btn" to="/"><span className="text-uppercase">{translate('Home')}</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  setResult = (status) => {
    if (status) {
      this.setState({
        hasResult: true,
        isSucess: true
      })
    } else {
      this.setState({
        hasResult: true,
        isSucess: false
      })
    }
  }

  componentDidMount() {
    const { AccountsAct } = this.props
    const { code } = this.props.computedMatch.params
    AccountsAct.sendLinkActiveEmail({ token: code }, this.setResult)
  }

  render() {
    //
    return (
      <div style={{ textAlign: 'center ', marginTop: 30 }}>
        {
          this.state.hasResult
            ? this.state.isSucess
              ? this.renderSuccess() : this.renderFail()
            : null
        }
      </div>
    )
  }
}


function mapsDispatchToProp(dispatch) {
  return {
    AccountsAct: bindActionCreators(AccountsAction, dispatch)
  }
}


export default connect((rootState) => {
  return {
    lang: rootState.i18nState.lang
  }
}, mapsDispatchToProp)(ActiveEmailContainer)
