export const CLEAR_API_RESPONSE = 'CLEAR_API_RESPONSE'
export const INIT_APPLICATION = 'INIT_APPLICATION'
export const INIT_APPLICATION_SUCCESS = 'INIT_APPLICATION_SUCCESS'
export const SERVER_ERROR = 'SERVER_ERROR'
export const REQUIRE_LOGIN = 'REQUIRE_LOGIN'
export const STORE_API_MESSAGE = 'STORE_API_MESSAGE'
export const STORE_API_PAYLOAD = 'STORE_API_PAYLOAD'
export const API_RESPONSE = 'API_RESPONSE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'
export const USER_LOGOUT = 'USER_LOGOUT'

export const THEME_UPDATE = 'THEME_UPDATE'
export const SIDEBAR_UPDATE = 'SIDEBAR_UPDATE'
