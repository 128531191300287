import React from 'react'
import { Link } from 'react-router-dom'

import { Images } from '../../../ui/assets/images'

class ForgotSuccess extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { translate } = this.props
    return (
      <div className="account-page register-page register-success-page clearfix">
        <div className="wrapper-inner">
          <div className="box-info">
            <div className="row">
              <div className="info-left col-12 col-md-7 nopadding">
                <div className="info-title">
                  <ul>
                    <li>{translate('ForgotPassword')}</li>
                  </ul>
                </div>
                <div className="info-content">
                  <div className="box-msg">
                    <div className="box-img"><img src={Images.logo} alt="" /></div>
                    <div className="box-info">
                      <p>Chúng tôi đã gửi 1 email tới địa chỉ email của bạn.</p>
                      <p>Vui lòng kiểm tra email để thự hiện các bước tiếp theo để tạo mật khẩu mới</p>
                    </div>
                    <div className="box-link">
                      <Link className="btn" to="/"><span className="text-uppercase">{translate('Home')}</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-right col-12 col-md-5 nopadding">
                <div className="orange-box">
                  <div className="box-title text-uppercase">Đăng ký đại lý Alo88 !</div>
                  <div className="box-highlight">Cùng biến đam mê thành lợi nhuận</div>
                  <ul>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;100% đăng ký miễn phí</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;50% chiết khấu hoa hồng</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;Cam kết an toàn &amp; bảo mật</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;Luôn thanh toán đúng hạn</li>
                  </ul>
                  <div className="box-img"><img src={Images.imgReg} alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotSuccess
