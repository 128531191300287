import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import DateRange from '../blocks/dateRange/DateRange'
import ComponentExtend from '../ComponentExtend'
import { copyText } from '../../helpers/commonHelpers'


class Dashboard1 extends ComponentExtend {
  constructor(props) {
    super(props)

    this.state = {
      fromDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      status: null
    }
    this.onSubmitSearchAgency = this.onSubmitSearchAgency.bind(this)
    this.callBackApproveAgency = this.callBackApproveAgency.bind(this)
  }

  componentWillMount() {
    this.props.submitSearchAgency(this.state.fromDate, this.state.toDate, 1, this.limit, null)
  }

  onSubmitSearchAgency(event) {
    event.preventDefault()
    const formData = {}
    for (let i = 0; i < event.target.elements.length; i++) {
      if (event.target[i].name) {
        formData[event.target[i].name] = event.target[event.target[i].name].value
      }
    }
    this.setState({
      fromDate: formData.fromDate,
      toDate: formData.toDate,
      status: Number(formData.status) || null
    })
    this.props.submitSearchAgency(formData.fromDate, formData.toDate, 1, this.limit, formData.status)
  }

  callBackApproveAgency(status, data) {
    if (status) {
      this.props.submitSearchAgency(this.state.fromDate, this.state.toDate, 1, this.limit)
    } else {
      alert(data.message)
    }
  }

  render() {
    const { registerAgencyList = {}, overview = {}, userInfo = {} } = this.props
    const { data = [] } = registerAgencyList
    const maxDate = moment().format('YYYY-MM-DD')
    return (
      <div className="cls-aflg-profile cls-aflg-dashboard row">
        <div className="row">
          <div className="col-md-12 col-lg-4 block-firstrow-dashboard">
            <div className="bgWhite count-member">
              <h5>{this.l('Số lượng thành viền của tôi')} <Link to="/members"><i className="fa fa-chevron-right icon-right" aria-hidden="true" /></Link></h5>
              <div>
                <img src="/assets/img/img-count-memeber.png" alt={this.l('Số lượng thành viền của tôi')} />
                <span>{numeral(overview.numMember || 0).format('0,0')}</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 block-firstrow-dashboard">
            <div className="bgWhite count-agency2">
              <h5>{this.l('Đại lý cấp 2')} <Link to="/agency"><i className="fa fa-chevron-right icon-right" aria-hidden="true" /></Link></h5>
              <div>
                <img src="/assets/img/icon-agency-2.png" alt={this.l('Đại lý cấp 2')} />
                <span>{numeral(overview.numAgency || 0).format('0,0')}</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 block-firstrow-dashboard">
            <div className="bgWhite count-member-agency2">
              <h5>{this.l('Thành viên đại lý cấp 2')} </h5>
              <div>
                <img src="/assets/img/img-count-member-agency-2.png" alt={this.l('Thành viên đại lý cấp 2')} />
                <span>{numeral(overview.numMemberOfAgency || 0).format('0,0')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6 block-info-invite-reg">
            <div>
              <i className="icon-invite-member" />{this.l('Link mời thành viên')}: <span className="cls-link-invite-member">{`${window.location.origin.toString()}/i/${userInfo.linkId}`}</span>
              <button className="btn btn-primary-cs" onClick={() => { copyText(`${window.location.origin.toString()}/i/${userInfo.linkId}`, this.l('Đã sao chép thành công')) }} type="button">{this.l('Sao chép')}</button>
            </div>

          </div>
          <div className="col-md-12 col-lg-6 block-info-invite-reg">
            <div>
              <i className="icon-invite-agency" />{this.l('Link mời đại lý')}:<span>{`${window.location.origin.toString()}/r/${this.props.userInfo.accountId}`}</span>
              <button className="btn btn-primary-cs" onClick={() => { copyText(`${window.location.origin.toString()}/r/${this.props.userInfo.accountId}`, this.l('Đã sao chép thành công')) }} type="button">{this.l('Sao chép')}</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-4 nopadding block-revenue">
            <div className="revenue-memeber">
              <h5>{this.l('Doanh thu từ thành viên')}</h5>
              <div>{numeral(overview.revenueFromMember || 0).format('0,0')} {this.l('VND')}</div>
            </div>
            <div className="revenue-agency2">
              <h5>{this.l('Doanh thu từ đại lý cấp 2')}</h5>
              <div>{numeral(overview.revenueFromAgency || 0).format('0,0')} {this.l('VND')}</div>
            </div>
            <div className="revenue-total">
              <h5>{this.l('Tổng doanh thu')}</h5>
              <div>{numeral(overview.totalRevenue || 0).format('0,0')} {this.l('VND')}</div>
            </div>
          </div>
          <div className="col-md-12 col-lg-8 nopadding block-agency">
            <div className="cls-block-info-commisstion block-content-afterlogin">
              <div className="head-afterlogin">
                <span>{this.l('Đại lý cấp 2 đăng ký mới')}</span>
                <form className="header-afterlogin-form" onSubmit={this.onSubmitSearchAgency}>
                  <select type="text" name="status">
                    <option value="">{this.l('Tất cả')}</option>
                    <option value="-1">{this.l('Đã từ chối')}</option>
                    <option value="1">{this.l('Đã đồng ý')}</option>
                    <option value="0">{this.l('Đang chờ')}</option>
                  </select>
                  <DateRange
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    maxDate={maxDate}
                  />
                  <button type="submit" className="btn btn-primary-cs">{this.l('Xem')}</button>
                </form>
              </div>
              <div className="contain-block-afterlogin" style={{ clear: 'both' }}>
                <div className="table-reponsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{this.l('Mã người dùng')}</th>
                        <th scope="col">{this.l('Họ tên')}</th>
                        <th scope="col">{this.l('Số điện thoại')}</th>
                        <th scope="col">{this.l('Email')}</th>
                        <th scope="col">{this.l('Tương tác')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(data.length > 0) ? (
                        data.map((item, k) => {
                          const key = k
                          return (
                            <tr key={key} className={(Number(item.approveStatus) === -1) ? 'haslock' : ''}>
                              <td>{item.accountId}</td>
                              <td><span>{item.displayName}</span></td>
                              <td>{item.phone}</td>
                              <td><span>{item.email}</span></td>
                              {(Number(item.approveStatus) === 0) ? (
                                <td style={{ whiteSpace: 'nowrap' }}>
                                  <button type="button" className="btn btn-success" onClick={() => { this.props.confirmAproveAgency(item.accountId, 1, this.callBackApproveAgency) }}>{this.l('Đồng ý')}</button>
                                  <button type="button" className="btn btn-danger" onClick={() => { this.props.confirmAproveAgency(item.accountId, -1, this.callBackApproveAgency) }}>{this.l('Từ chối')}</button>
                                </td>
                              ) : (
                                <td style={{ whiteSpace: 'nowrap' }}>{(Number(item.approveStatus) === 1) ? this.l('Đã đồng ý') : this.l('Đã từ chối')}</td>
                              )}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" className="empty-record">{this.l('Empty record')}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard1
