import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import reverse from 'lodash/reverse'

import * as GeneralAction from '../../redux/general/general.actions'

import Home from './Home'


class HomeContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  componentWillMount() {
    const { generalAct } = this.props
    generalAct.FAQList({})
    generalAct.promotionList({
      isHot: true,
      limit: 3,
      page: 1
    })
  }

  constructor(prop) {
    super(prop)
    this.state = {}
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.value !== state.value) {
  //     return {
  //       value: props.value
  //     }
  //   }
  //   return null
  // }


  render() {
    return (
      <Home
        translate={this.context.t}
        faq={this.props.faq}
        listPromotion={this.props.listPromotion}
        userInfo={this.props.userInfo}
        lang={this.props.lang}
      />
    )
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralAction, dispatch)

  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    lang: rootState.i18nState.lang,
    faq: rootState.generalReducer.faq,
    listPromotion: rootState.generalReducer.listPromotion
  }
}, mapsDispatchToProp)(HomeContainer)
