
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import * as AccountsAction from '../../redux/accounts/accounts.actions'
import * as GeneralAction from '../../redux/general/general.actions'

import Profile from './Profile'

class ProfileContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(prop) {
    super(prop)
    this.state = {}
    this.submitChangePass = this.submitChangePass.bind(this)
    this.sendLinkActiveEmail = this.sendLinkActiveEmail.bind(this)
    this.getCaptcha = this.getCaptcha.bind(this)
    this.cleanSendLinkActiveEmail = this.cleanSendLinkActiveEmail.bind(this)
  }

  componentWillMount() {
    const { accountAct, userInfo } = this.props
    accountAct.commissionConfig({ accountId: userInfo.accountId })
  }

  submitChangePass(value, callBack) {
    const { accountAct } = this.props
    accountAct.changePass(value, callBack)
  }

  sendLinkActiveEmail(params, callBack) {
    const { accountAct } = this.props
    accountAct.sendLinkActiveEmail(params, callBack)
  }

  cleanSendLinkActiveEmail() {
    const { accountAct } = this.props
    accountAct.cleanSendLinkActiveEmail()
  }

  getCaptcha() {
    const { generalAct } = this.props
    generalAct.postCaptcha()
  }

  render() {
    return (
      <Profile
        translate={this.context.t}
        userInfo={this.props.userInfo}
        commissionConfig={this.props.commissionConfig}
        isSendLinkActiveEmail={this.props.isSendLinkActiveEmail}
        submitChangePass={this.submitChangePass}
        sendLinkActiveEmail={this.sendLinkActiveEmail}
        captcha={this.props.captcha}
        getCaptcha={this.getCaptcha}
        cleanSendLinkActiveEmail={this.cleanSendLinkActiveEmail}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch),
    generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    isSendLinkActiveEmail: rootState.accountsReducer.isSendLinkActiveEmail,
    commissionConfig: rootState.accountsReducer.commissionConfig,
    captcha: rootState.generalReducer.captcha,
    lang: rootState.i18nState.lang
  }
}, mapDispatchToProps)(ProfileContainer)
