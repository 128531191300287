
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import * as AccountsAction from '../../redux/accounts/accounts.actions'

import Members from './Members'

class MembersContainer extends React.Component {
  constructor(prop) {
    super(prop)
    this.state = {}
    this.getListUser = this.getListUser.bind(this)
  }

  getListUser(month, page, limit, searchText) {
    const data = {
      month,
      page,
      limit
    }
    if (searchText !== '') {
      data.searchText = searchText
    }
    const { accountAct } = this.props
    accountAct.getUserListByAgency(data)
  }

  render() {
    const params = queryString.parse(this.props.location.search)
    const { page = 1 } = params
    return (
      <Members
        page={Number(page)}
        userListByAgency={this.props.userListByAgency}
        getListUser={this.getListUser}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch)
    // userListByAgency: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    userListByAgency: rootState.accountsReducer.userListByAgency
  }
}, mapDispatchToProps)(MembersContainer)
