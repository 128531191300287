import numeral from 'numeral'
import Decimal from 'decimal.js/decimal'
import includes from 'lodash/includes'
import split from 'lodash/split'
// import { Decimal } from 'decimal.js'

Decimal.set({ rounding: 1 })

const _ = {
  includes, split
}

function formatCurrency(price) {
  if (price) {
    const result = price.toString() || '0'
    return numeral(result).format('0,0')
  }
  return ''
}

function insertCurrency(str) {
  let tmp = str || ''

  if (tmp !== '') {
    tmp = tmp.replace(/[^\d.]+/g, '')
    if (tmp.indexOf('.') >= 0) {
      tmp = tmp.replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      tmp = numeral(tmp).format('0,0')
    }
  }
  // tmp = tmp.replace(/\d(?=(\d{3})+\.)/g, '$&,')
  // tmp = parseFloat(tmp.replace(new RegExp(',', 'g'), ''), 10)

  return tmp
}

function convertNumber(str) {
  let value = str || 0
  if (value === 0) return 0
  value = parseFloat(value.replace(new RegExp(',', 'g'), ''), 10)
  return value
}

function decimalPrice(price, format) {
  const result = price || 0
  let len = 8
  if (format) { //  0.0001
    const str = (format.toString()).split('.')[1]
    if (str) {
      len = str.length
      return Decimal(result).toFixed(len, Decimal.ROUND_DOWN).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
    if (result >= format) return numeral(result).format('0,0', Math.floor)
    return 0
  }
  return Decimal(result).toFixed(len, Decimal.ROUND_DOWN).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

function validDecimal(value, minTrade = '0.00000001') {
  const result = `${value}`
  if (_.includes(result, '.')) { // check insert dot
    const plit = _.split(result, '.')
    if (plit.length > 2) { // check multi dots
      return `${plit[0]}.${plit[1]}`
    }
    const str = (minTrade.toString()).split('.')[1]
    if (str) {
      const len = str.length

      if (plit[1].length <= 0) {
        return result
      }
      if (plit[1].length <= len) {
        return result
      }
      const demal = plit[1].substring(0, len)
      return `${plit[0]}.${demal}`
    }
    return plit[0]
  }
  return result
}

export {
  formatCurrency,
  insertCurrency,
  convertNumber,
  decimalPrice,
  validDecimal
}
