
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as AccountsAction from '../../redux/accounts/accounts.actions'
import * as GeneralAction from '../../redux/general/general.actions'

import Banner from './Banner'

class BannerContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(prop) {
    super(prop)
    this.state = {}
  }

  componentWillMount() {
    const { generalAct } = this.props
    generalAct.getBannerCategories()
  }

  render() {
    return (
      <Banner
        categories={this.props.categories}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch),
    generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    categories: rootState.generalReducer.categories
  }
}, mapDispatchToProps)(BannerContainer)
