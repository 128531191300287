import React from 'react'

const ListItem = (locals) => {
  const hasError = locals.hasError === true ? 'has-error' : ''
  const msg = locals.error ? <span className="help-block error-block">{locals.error}</span> : ''
  const cls = locals.value ? 'checkbox checked' : 'checkbox'

  const style = {
    marginBottom: '0px'
  }
  return (
    <div className={`eform-input form-group eform-checkbox-${locals.attrs.name} ${hasError} row`} style={style}>
      <div className="eform-label col-12 col-lg-12 nopadding">{locals.attrs.title} :</div>
      {
        locals.attrs.items.map((item, i) => {
          const key = i
          return (
            <div className="eform-desc col-12 col-lg-12 nopadding" key={key}>
              <div className={cls} checked>
                <label htmlFor={item.id}>
                  <input
                    data={item}
                    type="checkbox"
                    className="eform-checkbox-item"
                    onChange={(event) => {
                      const keyData = event.currentTarget.attributes.data.value
                      locals.onChange(`${keyData}|${event.target.checked}`)
                    }}
                    {...locals.attrs}
                  />
                &nbsp;&nbsp;&nbsp;
                  <span dangerouslySetInnerHTML={{ __html: item }} />
                </label>
                {msg}
              </div>
            </div>
          )
        })
      }
      <div className="clearfix" />
    </div>
  )
}

export default ListItem
