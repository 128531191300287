import React, { Component } from 'react'
import { connect } from 'react-redux'

class Loading extends Component {
  render() {
    const { payload: { apiResponse } } = this.props
    // if (apiResponse && apiResponse.showLoading && apiResponse.showLoading === false) {
    if (apiResponse && (!apiResponse.showLoading || apiResponse.showLoading === false)) {
      return false
    }
    return (
      <div className="e-loading">
        <img src="/assets/img/loading.svg" alt="loading" />
      </div>
    )
  }
}

export default connect((state) => { return { payload: state } })(Loading)
