import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as AccountsAction from '../../redux/accounts/accounts.actions'

class InviteUserContainer extends React.Component {
  constructor(prop) {
    super(prop)
    const { linkId = null } = this.props.computedMatch.params
    this.state = { linkId }
    document.title = 'Thành viên Alo88'
    this.callBackGetUrLInvite = this.callBackGetUrLInvite.bind(this)
  }

  componentWillMount() {
    const { accountAct } = this.props
    accountAct.getUrlInviteOfLinkId({ linkId: this.state.linkId }, this.callBackGetUrLInvite)
  }

  callBackGetUrLInvite(status, data) {
    if (status) {
      window.location.href = data.urlInviteMember
    } else {
      alert(data.message)
    }
  }

  render() {
    return (
      <div style={{ marginTop: '100px', textAlign: 'center' }}>
        <title>GG</title>
        Watting
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch)
  }
}

export default connect(() => {
  return {}
}, mapDispatchToProps)(InviteUserContainer)
