import React from 'react'
import { Link } from 'react-router-dom'

// import { isMobile } from 'react-device-detect'

// import size from 'lodash/size'

// import { NotificationManager } from 'react-notifications'

import { Images } from '../../ui/assets/images'

// const _ = {
//   size, map, uniqueId, toUpper, get, replace
// }

import CommissionEstimate from '../commissionEstimate/CommissionEstimate.container'
import { COMMISSION_URL } from '../../configs/constants'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      estimateBox: false
    }

    this.onCloseEstimate = this.onCloseEstimate.bind(this)
    this.onOpenEstimate = this.onOpenEstimate.bind(this)
  }

  onCloseEstimate() {
    this.setState({ estimateBox: false })
  }

  onOpenEstimate() {
    this.setState({ estimateBox: true })
  }

  renderBlock2() {
    const { translate } = this.props
    return (
      <div className="block-2">
        <div className="block-inner">
          <div className="row">
            <div className="block-right col-12 col-md-6 nopadding isMobile">
              <div className="block-text">
                <div className="block-title">{translate('WhatIsAlo88Club')}</div>
                <div className="block-desc">{translate('block2Text')}</div>
                {/* <div className="block-btn">
                  <a className="btn btn-2" target="_blank" rel="noopener noreferrer" href="https://alo88.bet/"><span className="text-uppercase">{translate('HomePage')}</span></a>
                </div> */}
              </div>
            </div>
            <div className="block-left col-12 col-md-6 nopadding"><img src={Images.block2Img} alt="" /></div>
            <div className="block-right col-12 col-md-6 nopadding isWeb">
              <div className="block-text">
                <div className="block-title">{translate('WhatIsAlo88Club')}</div>
                <div className="block-desc">{translate('block2Text')}</div>
                {/* <div className="block-btn">

                  <a className="btn btn-2" target="_blank" rel="noopener noreferrer" href="https://alo88.bet/"><span className="text-uppercase">{translate('HomePage')}</span></a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderBlock3() {
    const { translate } = this.props
    return (
      <div className="block-3" style={{ color: '#fff' }}>
        <div className="block-inner">
          <div className="row">
            <div className="block-left col-12 col-md-6 nopadding">
              <div className="block-text">
                <div className="block-title">{translate('block3Title')}</div>
                <div className="block-desc">
                  <div className="items row">
                    <div className="item-l nopadding"><img src={Images.block3Icon} alt="" /></div>
                    <div className="item-r nopadding" dangerouslySetInnerHTML={{ __html: translate('block3Text00') }} />
                  </div>
                  <div className="items row">
                    <div className="item-l nopadding"><img src={Images.block3Icon} alt="" /></div>
                    <div className="item-r nopadding" dangerouslySetInnerHTML={{ __html: translate('block3Text01') }} />
                  </div>
                  <div className="items row">
                    <div className="item-l nopadding"><img src={Images.block3Icon} alt="" /></div>
                    <div className="item-r nopadding">{translate('block3Text')}</div>
                  </div>
                  <div className="items row">
                    <div className="item-l nopadding"><img src={Images.block3Icon} alt="" /></div>
                    <div className="item-r nopadding">{translate('block3Text2')}</div>
                  </div>
                  <div className="items row">
                    <div className="item-l nopadding"><img src={Images.block3Icon} alt="" /></div>
                    <div className="item-r nopadding">{translate('block3Text3')}</div>
                  </div>
                  <div className="items row">
                    <div className="item-l nopadding"><img src={Images.block3Icon} alt="" /></div>
                    <div className="item-r nopadding">{translate('block3Text4')}</div>
                  </div>
                  <div className="items row">
                    <div className="item-l nopadding"><img src={Images.block3Icon} alt="" /></div>
                    <div className="item-r nopadding">{translate('block3Text5')}</div>
                  </div>
                  <div className="items row">
                    <div className="item-l nopadding"><img src={Images.block3Icon} alt="" /></div>
                    <div className="item-r nopadding">{translate('block3Text6')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block-right col-12 col-md-6 nopadding"><img src={Images.block3Img} alt="" /></div>
          </div>
        </div>
      </div>
    )
  }

  renderBlock4() {
    const { translate } = this.props
    return (
      <div className="block-4">
        <div className="block-inner">
          <div className="block-title">{translate('block4Title')}</div>
          <div className="block-content row">
            <div className="items col-12 col-md-4">
              <div className="item-img"><img src={Images.block4Icon} alt="" /></div>
              <div className="item-desc">{translate('block4Text')}</div>
            </div>
            <div className="items col-12 col-md-4">
              <div className="item-img"><img src={Images.block4Icon2} alt="" /></div>
              <div className="item-desc">{translate('block4Text2')}</div>
            </div>
            <div className="items col-12 col-md-4">
              <div className="item-img"><img src={Images.block4Icon3} alt="" /></div>
              <div className="item-desc">{translate('block4Text3')}</div>
            </div>
            <div className="items col-12 col-md-4">
              <div className="item-img"><img src={Images.block4Icon3} alt="" /></div>
              <div className="item-desc">{translate('block4Text4')}</div>
            </div>
            <div className="items col-12 col-md-4">
              <div className="item-img"><img src={Images.block4Icon3} alt="" /></div>
              <div className="item-desc">{translate('block4Text5')}</div>
            </div>
            <div className="items col-12 col-md-4">
              <div className="item-img"><img src={Images.block4Icon2} alt="" /></div>
              <div className="item-desc">{translate('block4Text6')}</div>
            </div>
            <div className="items col-12 col-md-4">
              <div className="item-img"><img src={Images.block4Icon2} alt="" /></div>
              <div className="item-desc">{translate('block4Text7')}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderBlock5() {
    const { translate } = this.props
    const { estimateBox } = this.state
    return (
      <div className="block-5" style={{ color: '#fff' }}>
        <div className={`block-inner ${estimateBox ? 'active' : ''}`}>
          <div className="row">
            <div className="block-left col-12 col-md-6 nopadding isWeb"><img src={Images.block5Img} alt="" /></div>
            <div className="block-right col-12 col-md-6 nopadding">
              <div className="block-text">
                <div className="block-title">{translate('block5Title')}</div>
                <div className="block-desc">{translate('block5Text')}</div>
                <div className="block-btn">
                  <a className="btn btn-1" href={COMMISSION_URL}><span className="text-uppercase">{translate('ReadMore')}</span></a>
                  <Link className="btn btn-2" to={{}} onClick={() => this.onOpenEstimate()}><span className="text-uppercase">{translate('CommissionEstimate')}</span></Link>
                </div>
                <div className={`block-emulator ${estimateBox ? 'show' : ''}`}>
                  <CommissionEstimate />
                  <Link className="btn-remove" to={{}} onClick={() => this.onCloseEstimate()}>
                    <i className="fa fa-times" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="block-left col-12 col-md-6 nopadding isMobile"><img src={Images.block5Img} alt="" /></div>
          </div>
        </div>
      </div>
    )
  }

  renderBlock6() {
    const { translate } = this.props
    return (
      <div className="block-6" id="idTerms">
        <div className="block-inner">
          <div className="block-title">{translate('block6Title')}</div>
          <div className="block-content row" />
          <div className="block-bottom block-btn">
            <Link className="btn" to="/pages/dieu-kien-va-dieu-khoan"><span className="text-uppercase">{translate('ReadMore')}</span></Link>
          </div>

        </div>
      </div>
    )
  }

  renderBlock7() {
    const { translate, faq } = this.props
    return (
      <div className="block-7" id="idFAQ">
        <div className="block-inner">
          <div className="block-text col-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2 nopadding">
            <div className="block-title">{translate('block7Title')}</div>
            <div className="block-desc">

              <div className="accordion" id="accordionExample">
                {faq.list.map((item, k) => {
                  const key = `faq${k}`
                  return (
                    <div className="card" key={key}>
                      <div className="card-header" id="heading1">
                        <h2 className="mb-0">
                          <button className="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse${key}`} aria-expanded="true" aria-controls="collapse1">
                            {item.question}
                          </button>
                        </h2>
                      </div>

                      <div id={`collapse${key}`} className={`collapse ${(key === 0) ? 'show' : ''}`} aria-labelledby="heading1" data-parent="#accordionExample">
                        <div className="card-body" dangerouslySetInnerHTML={{ __html: item.answer }} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderBlock8() {
    const { translate } = this.props
    return (
      <div className="block-8" id="idContactus">
        <div className="block-inner">
          <div className="row">
            <div className="block-left col-12 col-md-7 nopadding">
              <div className="block-text">
                <div className="block-title">{translate('block8Title')}</div>
                <div className="block-desc">{translate('block8Text')}</div>
                <div className="block-tool">
                  {/* <div className="row">
                    <div className="icon-left nopadding">
                      <img className="img-3" src={Images.block8Icon3} alt="" />
                      &nbsp;&nbsp;
                      <img className="img-2" src={Images.block8Icon2} alt="" />
                      &nbsp;&nbsp;&nbsp;
                    </div>
                    <div className="phone-right">(+84) 794 593 530</div>
                  </div> */}
                  <div className="row">
                    <div className="icon-left nopadding">
                      <img className="img-1" src={Images.block8Icon} alt="" />
                      &nbsp;&nbsp;
                      <img className="img-3" src={Images.block8Icon4} alt="" />
                    </div>
                    <div className="phone-right">(+63) 94 6887 9999</div>
                  </div>
                </div>
                <div className="contact-email-chat">
                  <a className="mail-contact" href="mailto:cskh.alo88@gmail.com">cskh.alo88@gmail.com</a>
                  <a target="_blank" rel="noopener noreferrer" href="https://tawk.to/chat/5e840db069e9320caabf1205/default" className="live-chat">Live Chat</a>
                </div>
              </div>
            </div>
            <div className="block-right col-12 col-md-5 nopadding"><img src={Images.block8Img} alt="" /></div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    // const { translate } = this.props
    return (
      <div className="homepage clearfix">

        <div className="block-1">
          <div className="block-inner">

            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active" />
                <li data-target="#carouselExampleIndicators" data-slide-to="1" />
                {/* <li data-target="#carouselExampleIndicators" data-slide-to="2" /> */}
              </ol>
              <div className="carousel-inner">
                {/* <div className="carousel-item active"> */}
                {/*  <Link to="/commission"><img src={Images.banner2Img} className="d-block w-100" alt="" /></Link> */}
                {/* </div> */}
                {/* <div className="carousel-item active"> */}
                {/*  <Link to="/register"><img src={(isMobile) ? Images.blockImgM : Images.blockImg} className="d-block w-100" alt="" /></Link> */}
                {/* </div> */}
                <div className="carousel-item active">
                  <Link to="/register"><img src={Images.banner1Img} className="d-block w-100" alt="" /></Link>
                </div>
              </div>
            </div>

          </div>
        </div>

        {this.renderBlock2()}
        {this.renderBlock3()}
        {this.renderBlock4()}
        {this.renderBlock5()}
        {this.renderBlock6()}
        {this.renderBlock7()}
        {this.renderBlock8()}
      </div>
    )
  }

  componentDidMount() {
    // isMounted = true

    // if (isMounted) {
    //   this.setState({
    //     search: this.props.search
    //   })
    // }
  }

  componentWillUnmount() {
    // isMounted = false
  }
}

export default Home
