
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import * as AccountsAction from '../../redux/accounts/accounts.actions'

import Withdraw from './Withdraw'

class WithdrawContainer extends React.Component {
  constructor(prop) {
    super(prop)
    this.state = {}
    this.submitChangePass = this.submitChangePass.bind(this)
    this.getHistory = this.getHistory.bind(this)
    this.submitWithdraw = this.submitWithdraw.bind(this)
    this.updateBalance = this.updateBalance.bind(this)
    this.getBank = this.getBank.bind(this)
    this.getProvince = this.getProvince.bind(this)
    this.getRemeber = this.getRemeber.bind(this)
  }

  getHistory(fromDate, toDate, page, limit) {
    const { accountAct } = this.props
    accountAct.withdrawHistory({
      fromDate,
      toDate,
      page,
      limit
    })
  }

  submitWithdraw(bank, province, branch, bankAccount, bankNumber, amount, remember, callBack) {
    const { accountAct } = this.props
    accountAct.withdraw({
      bank,
      province,
      branch,
      bankAccount,
      bankNumber,
      amount,
      remember
    }, callBack)
  }

  submitChangePass(value, callBack) {
    const { accountAct } = this.props
    accountAct.changePass(value, callBack)
  }

  updateBalance() {
    const { accountAct } = this.props
    accountAct.getBalance({})
  }

  getBank() {
    const { accountAct } = this.props
    accountAct.getBank({})
  }

  getProvince() {
    const { accountAct } = this.props
    accountAct.getProvince({})
  }

  getRemeber(type, method) {
    const { accountAct } = this.props
    accountAct.getRemember({ type, method })
  }

  render() {
    const params = queryString.parse(this.props.location.search)
    const { page = 1 } = params
    return (
      <Withdraw
        userInfo={this.props.userInfo}
        getHistory={this.getHistory}
        submitWithdraw={this.submitWithdraw}
        listWithdrawHistory={this.props.listWithdrawHistory}
        rsWithdraw={this.props.rsWithdraw}
        page={Number(page)}
        updateBalance={this.updateBalance}
        getBank={this.getBank}
        getProvince={this.getProvince}
        listBank={this.props.listBank}
        listProvince={this.props.listProvince}
        getRemeber={this.getRemeber}
        remember={this.props.remember}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch)
    // generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    listWithdrawHistory: rootState.accountsReducer.listWithdrawHistory,
    rsWithdraw: rootState.accountsReducer.rsWithdraw,
    listBank: rootState.accountsReducer.listBank,
    listProvince: rootState.accountsReducer.listProvince,
    remember: rootState.accountsReducer.remember
  }
}, mapDispatchToProps)(WithdrawContainer)
