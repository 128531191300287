import React, { Component } from 'react'
// import { connect } from 'react-redux'
import {
  BrowserRouter, Route, Switch, withRouter  // eslint-disable-line
} from 'react-router-dom'
import map from 'lodash/map'

import PublicLayout from './public.router'  // eslint-disable-line

import routes from '../configs/router.config'

const _ = { map }

class AppRoutes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>

          { _.map(routes, (routeItem, key) => {
            const { component, ...rest } = routeItem
            return (
              <routeItem.layout key={key} component={component} {...rest} {...this.props}>
                <PublicLayout component={component} route={routeItem} {...rest} {...this.props} />
              </routeItem.layout>
            )
          }) }
        </Switch>
      </BrowserRouter>
    )
  }
}

export default AppRoutes
