import * as types from './accounts.types'

const initialState = {
  userInfo: {},
  commissionConfig: {},
  agencyList: {}, // danh sách đại lý
  registerAgencyList: {}, // danh sách đại lý cấp mới đăng ký
  confirmApproveAgency: {},
  overview: {}, // danh sách các dữ liệu trên dashboard
  userListByAgency: {}, // danh sách user của đại lý đang đăng nhập
  commissionConfigAgency: {}, // danh sách hoa hồng của đại lý
  updateConfigCommisstionAgency: {}, // kết quả cập nhật cấu hình hoa hồng
  reportGeneral: {}, // các thông số thống kê chung
  chartMember: {}, // dữ liệu chart member
  chartRevenue: {}, // dữ liệu chart revenue
  reportRevenue: {}, // dữ liệu bảng thống kê
  listWithdrawHistory: {}, // danh sách lịch sử rút tiền
  rsWithdraw: {}, // kết quả rút tiền
  listBank: [], // danh sách ngân hàng
  remember: {},
  listProvince: [],
  isSendLinkActiveEmail: false,
  refcodeAgency: '',
  accessToken: '',
  agentBannerList: null
}

export default function accounts(state = initialState, action) {
  switch (action.type) {
    case types.POST_ACCOUNT_LOGIN_SUCCESS:
    case types.POST_ACCOUNT_LOGIN_STEP2_SUCCESS:
      // case types.POST_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
        refcodeAgency: ''
      }

    case types.POST_ACCOUNT_GET_PROFILE_SUCCESS:
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload }
      }

    case types.POST_ACCOUNT_GET_PROFILE_FAIL:
      return {
        ...state,
        userInfo: {}
      }
    case types.ACCOUNT_GET_BALANCE_SUCCESS:
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload }
      }
    case types.ACCOUNT_GET_BALANCE_FAIL:
      return {
        ...state,
        userInfo: {}
      }

    case types.POST_ACCOUNT_LOGOUT:
    case types.ERROR_401:
      return {
        ...initialState
      }

    case types.COMMISSION_CONFIG_SUCCESS:
      return {
        ...state,
        commissionConfig: { ...action.payload }
      }

    case types.COMMISSION_CONFIG_FAIL:
      return {
        ...state,
        commissionConfig: {}
      }
    case types.GET_AGENCY_LIST:
    case types.GET_AGENCY_LIST_FAIL:
      return {
        ...state,
        agencyList: {}
      }
    case types.GET_AGENCY_LIST_SUCCESS:
      return {
        ...state,
        agencyList: action.payload
      }
    case types.GET_REGISTER_AGENCY_LIST:
    case types.GET_REGISTER_AGENCY_LIST_FAIL:
      return {
        ...state,
        registerAgencyList: {}
      }
    case types.GET_REGISTER_AGENCY_LIST_SUCCESS:
      return {
        ...state,
        registerAgencyList: { ...action.payload }
      }

    case types.CONFIRM_APPROVE_AGENCY:
    case types.CONFIRM_APPROVE_AGENCY_FAIL:
      return {
        ...state,
        confirmApproveAgency: {}
      }
    case types.CONFIRM_APPROVE_AGENCY_SUCCESS:
      return {
        ...state,
        confirmApproveAgency: { ...action.payload }
      }

    case types.GET_OVERVIEW:
    case types.GET_OVERVIEW_FAIL:
      return {
        ...state,
        overview: {}
      }
    case types.GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        overview: action.payload
      }

    case types.GET_USER_LIST_BY_AGENCY:
    case types.GET_USER_LIST_BY_AGENCY_FAIL:
      return {
        ...state,
        userListByAgency: {}
      }
    case types.GET_USER_LIST_BY_AGENCY_SUCCESS:
      return {
        ...state,
        userListByAgency: action.payload
      }

    case types.GET_COMMISSION_CONFIG_AGENCY:
    case types.GET_COMMISSION_CONFIG_AGENCY_FAIL:
      return {
        ...state,
        commissionConfigAgency: {}
      }
    case types.GET_COMMISSION_CONFIG_AGENCY_SUCCESS:
      return {
        ...state,
        commissionConfigAgency: action.payload
      }

    case types.UPDATE_CONFIG_COMMISSION_AGENCY:
    case types.UPDATE_CONFIG_COMMISSION_AGENCY_FAIL:
      return {
        ...state,
        updateConfigCommisstionAgency: {}
      }
    case types.UPDATE_CONFIG_COMMISSION_AGENCY_SUCCESS:
      return {
        ...state,
        updateConfigCommisstionAgency: action.payload
      }

    case types.REPORT_GENERAL:
    case types.REPORT_GENERAL_FAIL:
      return {
        ...state,
        reportGeneral: {}
      }
    case types.REPORT_GENERAL_SUCCESS:
      return {
        ...state,
        reportGeneral: action.payload
      }

    case types.REPORT_GET_CHART_MEMBER:
    case types.REPORT_GET_CHART_MEMBER_FAIL:
      return {
        ...state,
        chartMember: {}
      }
    case types.REPORT_GET_CHART_MEMBER_SUCCESS:
      return {
        ...state,
        chartMember: action.payload
      }

    case types.REPORT_GET_CHART_REVENUE:
    case types.REPORT_GET_CHART_REVENUE_FAIL:
      return {
        ...state,
        chartRevenue: {}
      }
    case types.REPORT_GET_CHART_REVENUE_SUCCESS:
      return {
        ...state,
        chartRevenue: action.payload
      }


    case types.REPORT_REVENUE:
    case types.REPORT_REVENUE_FAIL:
      return {
        ...state,
        reportRevenue: {}
      }
    case types.REPORT_REVENUE_SUCCESS:
      return {
        ...state,
        reportRevenue: action.payload
      }

    case types.WITHDRAW_HISTORY:
    case types.WITHDRAW_HISTORY_FAIL:
      return {
        ...state,
        listWithdrawHistory: {}
      }
    case types.WITHDRAW_HISTORY_SUCCESS:
      return {
        ...state,
        listWithdrawHistory: action.payload
      }

    case types.WITHDRAW:
    case types.WITHDRAW_FAIL:
      return {
        ...state,
        rsWithdraw: {}
      }
    case types.WITHDRAW_SUCCESS:
      return {
        ...state,
        rsWithdraw: action.payload
      }

    case types.GET_BANK:
    case types.GET_BANK_FAIL:
      return {
        ...state,
        listBank: []
      }
    case types.GET_BANK_SUCCESS:
      return {
        ...state,
        listBank: action.payload.data
      }
    case types.GET_AGENT_BANNER_LIST:
    case types.GET_AGENT_BANNER_LIST_FAIL:
      return {
        ...state,
        agentBannerList: null
      }
    case types.GET_AGENT_BANNER_LIST_SUCCESS:
      return {
        ...state,
        agentBannerList: action.payload.data
      }

    case types.GET_REMEMBER:
    case types.GET_REMEMBER_FAIL:
      return {
        ...state,
        remember: {}
      }
    case types.GET_REMEMBER_SUCCESS:
      return {
        ...state,
        remember: action.payload.config
      }
    case types.GET_PROVINCE:
    case types.GET_PROVINCE_FAIL:
      return {
        ...state,
        listProvince: []
      }
    case types.GET_PROVINCE_SUCCESS:
      return {
        ...state,
        listProvince: action.payload.data
      }
    case types.SEND_LINK_ACTIVE_EMAIL:
    case types.SEND_LINK_ACTIVE_EMAIL_FAIL:
      return {
        ...state,
        isSendLinkActiveEmail: false
      }
    case types.SEND_LINK_ACTIVE_EMAIL_SUCCESS:
      return {
        ...state,
        isSendLinkActiveEmail: true
      }

    case types.UPDATE_REFCODE_AGENCY:
      return {
        ...state,
        refcodeAgency: action.payload.refcode
      }
    case types.POST_ACCOUNT_REGISTER_SUCCESS:
      return {
        ...state,
        refcodeAgency: ''
      }
    case types.UPDATE_ACCESS_TOKEN:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          accessToken: action.payload.accessToken
        }
      }
    default:
      return state
  }
}
