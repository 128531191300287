import React from 'react'
import t from 'tcomb-form'

import isEmpty from 'lodash/isEmpty'
import md5 from 'md5'

// import { NotificationManager } from 'react-notifications'
import { TCombForm } from '../../../ui'

import { Images } from '../../../ui/assets/images'
// import { change_alias } from '../../../helpers'

const _ = {
  isEmpty
}

class NewPass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: {},
      errorsMsg: ''
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCallback = this.onCallback.bind(this)
  }

  onChange(value) {
    this.setState({ value, errorsMsg: '' })
  }

  onSubmit(e) {
    e.preventDefault()
    const { translate } = this.props
    const value = this.profileForm.getValue()

    if (value) {
      if (value.newPass.localeCompare(value.renewPass) !== 0) {
        this.setState({ errorsMsg: translate('compareNewPassErr') })
        return
      }
      this.setState({ errorsMsg: '' })
      const params = {
        password: md5(value.newPass),
        token: this.props.code
      }
      this.props.onSubmit(params, this.onCallback)
    }
  }

  onCallback(status, data) {
    if (status) {
      this.props.redirectSuccess()
    } else {
      this.props.refreshCaptcha()
      this.setState({ errorsMsg: data.message })
    }
  }

  renderErrors() {
    if (!_.isEmpty(this.state.errorsMsg)) {
      return (
        <div className="alert alert-danger">{this.state.errorsMsg}</div>
      )
    }
    return null
  }

  render() {
    const { translate } = this.props

    const formatPass = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d/!/@/#/$/%/^/&/*/(/)/=/_/+/?]{8,16}$/
    const pass = t.refinement(t.String, (s) => {
      return formatPass.test(s)
    })

    const newPassword = t.struct({
      newPass: pass,
      renewPass: pass
    })

    const options = {
      auto: 'placeholders',
      fields: {
        newPass: {
          label: translate('newPassword'),
          error: translate('validPassword'),
          attrs: {
            type: 'password',
            placeholder: translate('placePassword')
          },
          template: TCombForm.TextRegister
        },
        renewPass: {
          label: translate('renewPassword'),
          error: translate('validPassword'),
          attrs: {
            type: 'password',
            placeholder: translate('placePassword')
          },
          template: TCombForm.TextRegister
        }
      }
    }
    return (
      <div className="account-page register-page clearfix">
        <div className="wrapper-inner">
          <div className="box-info">
            <div className="row">
              <div className="info-left col-12 col-md-7 nopadding">
                <div className="info-title">
                  <ul>
                    <li>{translate('NewPassword')}</li>
                  </ul>
                </div>
                <div className="info-content">
                  <form className="frm-register" onSubmit={this.onSubmit}>
                    {this.renderErrors()}
                    <t.form.Form
                      ref={(element) => { this.profileForm = element }}
                      type={newPassword}
                      value={this.state.value}
                      options={options}
                      onChange={this.onChange}
                    />

                    <div className="form-group text-center">
                      <button type="submit" className="btn btn-register text-uppercase">{translate('ok')}</button>
                    </div>

                  </form>
                </div>
              </div>
              <div className="info-right col-12 col-md-5 nopadding">
                <div className="orange-box">
                  <div className="box-title text-uppercase">Đăng ký đại lý Alo88 !</div>
                  <div className="box-highlight">Cùng biến đam mê thành lợi nhuận</div>
                  <ul>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;100% đăng ký miễn phí</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;50% chiết khấu hoa hồng</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;Cam kết an toàn &amp; bảo mật</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;Luôn thanh toán đúng hạn</li>
                  </ul>
                  <div className="box-img"><img src={Images.imgReg} alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewPass
