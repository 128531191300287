import axios from 'axios'
import store from '../../configs/store.config'
// import store from '../../configs/store.config'

const Request = {
  callAPI(method, url, args) {
    return new Promise(async (resolve, reject) => {
      try {
        let Authorization = ''
        const stateReducer = store.getState()
        const lang = stateReducer.generalReducer.lang || 'vi'
        if (args && args.accessToken) {
          Authorization = `${args.accessToken}`
          delete args.accessToken
        }
        const response = await axios({
          method,
          url,
          data: args,
          headers: {
            // 'Access-Control-Allow-Origin': '*',
            Authorization,
            Language: lang
          }
        })
        resolve(response.data)
      } catch (error) {
        if (error) console.log(error)
        reject(error)
      }
    })
  },
  callAccountAPI(method, url, args) {
    return new Promise(async (resolve, reject) => {
      try {
        let Authorization = ''
        const stateReducer = store.getState()
        const lang = stateReducer.generalReducer.lang || 'vi'
        if (args && args.accessToken) {
          Authorization = `${args.accessToken}`
          delete args.accessToken
        }
        const response = await axios({
          method,
          url,
          data: args,
          headers: {
            // 'Access-Control-Allow-Origin': '*',
            Authorization,
            Language: lang
          }
        })
        resolve(response.data)
      } catch (error) {
        if (error) console.log(error)
        reject(error)
      }
    })
  },
  callAPIWithAccessToken(method, url, args, hasClientId = false) {
    const stateReducer = store.getState()
    const lang = stateReducer.generalReducer.lang || 'vi'
    let Authorization = stateReducer.accountsReducer.userInfo.accessToken || ''
    if (args && args.accessToken) {
      Authorization = `${args.accessToken}`
      delete args.accessToken
    }
    if (hasClientId) {
      // args.clientId = stateReducer.generalReducer.clientInfo.clientId || ''
    }
    return new Promise(async (resolve, reject) => {
      try {
        let response = null
        if (method === 'get') {
          response = await axios({
            method,
            url,
            params: args,
            headers: {
              // 'Access-Control-Allow-Origin': '*',
              Authorization,
              Language: lang
            }
          })
        } else {
          response = await axios({
            method,
            url,
            data: args,
            headers: {
              // 'Access-Control-Allow-Origin': '*',
              Authorization,
              Language: lang
            }
          })
        }
        resolve(response.data)
      } catch (error) {
        if (error) console.log(error)
        reject(error)
      }
    })
  }
}

export default Request
