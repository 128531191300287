import React from 'react'

import GGCaptcha from './GGCaptcha'

const Captcha = (locals) => {
  return (
    <GGCaptcha
      locals={locals}
      modal={false}
    />
  )
}

export default Captcha
