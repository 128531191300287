import React from 'react'
import { Link } from 'react-router-dom'

class NotFound extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'center ', marginTop: 30 }}>
        Not found
        {' '}
        <Link to="/" style={{ color: 'blue' }}>back to home</Link>
      </div>
    )
  }
}

export default NotFound
