/*  config data */
export const REFRESH_ACCESSTOKEN_TIME = 8 // unit: 1 minutes

export const KEY_RESET_PASS = 'KWJOznJBjlT55roHQKWbfe2'

export const BannerData = [
  {
    name: 'Proms 1',
    list: [
      {
        name: 'Size 16x161',
        url: 'https://www.vultr.com/media/banners/banner_160x600.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x162',
        url: 'https://www.vultr.com/media/banners/banner_300x250.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x163',
        url: 'https://www.vultr.com/media/banners/banner_468x60.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x164',
        url: 'https://www.vultr.com/media/banners/banner_628x628.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x165',
        url: 'https://www.vultr.com/media/banners/banner_728x90.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x166',
        url: 'https://www.vultr.com/media/banners/banner_1200x600.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x167',
        url: 'https://www.vultr.com/media/banners/banner_1200x628.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 1200x800px',
        url: 'https://www.vultr.com/media/banners/banner_1200x800.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x169',
        url: 'https://www.vultr.com/media/banners/banner_800x800.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      }
    ]
  },
  {
    name: 'Proms 2',
    list: [
      {
        name: 'Size 16x161',
        url: 'https://www.vultr.com/media/banners/banner_160x600.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x162',
        url: 'https://www.vultr.com/media/banners/banner_320x250.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x163',
        url: 'https://www.vultr.com/media/banners/banner_468x60.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x164',
        url: 'https://www.vultr.com/media/banners/banner_628x628.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x165',
        url: 'https://www.vultr.com/media/banners/banner_728x90.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x166',
        url: 'https://www.vultr.com/media/banners/banner_1200x600.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      },
      {
        name: 'Size 16x167',
        url: 'https://www.vultr.com/media/banners/banner_1200x628.png',
        code: '<a href="https://www.vultr.com/?ref=8490067"><img src="https://www.vultr.com/media/banners/banner_160x600.png" width="160" height="600"></a>'
      }
    ]
  },
  {
    name: 'PROMS 3',
    list: []
  }
]


let commisionUrl = 'http://localhost:3000/commission'
switch (process.env.REACT_APP_ENV) {
  case 'dev':
    commisionUrl = 'http://localhost:3000/commission'
    break
  case 'test':
    commisionUrl = 'https://agentweb.alo88.xyz/commission'
    break
  case 'prod':
  case 'production':
  case 'production ':
    commisionUrl = 'https://daily.alo88.bet/commission'
    break
  default:
    commisionUrl = 'https://daily.alo88.bet/commission'
}
export const COMMISSION_URL = `${commisionUrl}`

let affRuleUrl = 'http://localhost:3000/dai-ly-chinh-hieu'
switch (process.env.REACT_APP_ENV) {
  case 'dev':
    affRuleUrl = 'http://localhost:3000/dai-ly-chinh-hieu'
    break
  case 'test':
    affRuleUrl = 'https://agentweb.alo88.xyz/dai-ly-chinh-hieu'
    break
  case 'prod':
  case 'production':
  case 'production ':
    affRuleUrl = 'https://daily.alo88.bet/dai-ly-chinh-hieu'
    break
  default:
    // affRuleUrl = 'https://daily.alo88.bet/dai-ly-chinh-hieu'
    affRuleUrl = 'http://localhost:3000/dai-ly-chinh-hieu'
}
export const AFF_RULE_URL = `${affRuleUrl}`
