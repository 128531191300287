import React from 'react'

import ElmCaptcha from './ElmCaptcha'

const CaptchaModal = (locals) => {
  return (
    <ElmCaptcha
      locals={locals}
      modal={true}
    />
  )
}

export default CaptchaModal
