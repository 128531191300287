// const apiUrl = '10.0.70.86:1312'

// const method = 'http://' // http:// or https:// or // (auto)
let apiUrl = 'http://10.0.70.86:1312'

switch (process.env.REACT_APP_ENV) {
  case 'dev':
    apiUrl = 'http://localhost:8520/api'
    break
  case 'test':
    apiUrl = '/api'
    break
  case 'prod':
  case 'production':
  case 'production ':
    apiUrl = '/api'
    break
  default:
    // apiUrl = 'https://apilive.alo88.bet'
    apiUrl = 'http://localhost:8520/api'
}


export const API_URL = apiUrl

export const KEY_HEADER_THREE = 'sum'
