import React from 'react'

const Custom = (locals) => {
  const hasError = locals.hasError === true ? 'has-error' : ''
  const msg = locals.error ? <span className="help-block error-block">{locals.error}</span> : ''
  const label = locals.label ? locals.label : locals.attrs.name
  const { config: { icon, img } } = locals

  if (label !== 'disabled') {
    return (
      <div className={`eform-input eform-input-${locals.attrs.name} ${hasError}`}>
        <div className="eform-label">{label}</div>
        <div className="eform-desc">
          <div className="input-group-currency">
            {
                locals.config ? img
                  ? <span className="input-group-img"><img src={img} alt="" /></span>
                  : null : null
                }
            <input
              className="eform-input-item form-control placeholder-no-fix e-radius"
              autoComplete="off"
              value={locals.value}
              onChange={event => locals.onChange(event.target.value)}
              {...locals.attrs}
            />
            {
                locals.config ? icon
                  ? <span className="input-group-addon"><i className={icon} /></span>
                  : null : null
                }
          </div>
          {locals.config ? locals.config.sublabel ? <p className="name">{locals.config.sublabel}</p> : null : null }
          {msg}
        </div>
      </div>
    )
  }
  return (
    <div className={`eform-input eform-input-${locals.attrs.name} ${hasError}`}>
      <div className="eform-desc">
        <div className="input-group-currency">
          {
                locals.config ? img
                  ? <span className="input-group-img"><img src={img} alt="" /></span>
                  : null : null
                }
          <input
            className="eform-input-item form-control placeholder-no-fix e-radius"
            autoComplete="off"
            value={locals.value}
            onChange={event => locals.onChange(event.target.value)}
            {...locals.attrs}
          />
          {
                locals.config ? icon
                  ? <span className="input-group-addon"><i className={icon} /></span>
                  : null : null
                }
        </div>
        {locals.config ? locals.config.sublabel ? <p className="name">{locals.config.sublabel}</p> : null : null }
        {msg}
      </div>
      <div className="clearfix" />
    </div>
  )
}

export default Custom
