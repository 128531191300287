import React from 'react'
// import numeral from 'numeral'
// import SidebarLeft from '../sidebar/SidebarLeft.container'
// import CommissionEstimate from '../commissionEstimate/CommissionEstimate.container'
import ComponentExtend from '../ComponentExtend'

class Commission extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderCommissionTable() {
    // const { commissionConfig: { config = [] } } = this.props
    // return config.map((item, k) => {
    //   const key = k
    //   if (item.maxProfit === null) {
    //     return (
    //       <div className="table-items last row" key={key}>
    //         <div className="table-item first col-4 nopadding text-uppercase">Từ {numeral(item.minProfit).format('0,0')} trở lên</div>
    //         <div className="table-item col-4 nopadding text-uppercase">{numeral(item.numMember).format('0,0')}</div>
    //         <div className="table-item last col-4 nopadding text-uppercase">{item.commission * 100}%</div>
    //       </div>
    //     )
    //   }
    //   return (
    //     <div className="table-items row" key={key}>
    //       <div className="table-item first col-4 nopadding text-uppercase">{numeral(item.minProfit).format('0,0')} - {numeral(item.maxProfit).format('0,0')}</div>
    //       <div className="table-item col-4 nopadding text-uppercase">{numeral(item.numMember).format('0,0')}</div>
    //       <div className="table-item last col-4 nopadding text-uppercase">{item.commission * 100}%</div>
    //     </div>
    //   )
    // })
    return (
      <table style={{ width: '100%' }}>
        <tr>
          <th>Chính sách</th>
          <th>Cụ thể</th>
          <th>Đại lý đạt tiêu chuẩn</th>
          <th>Đại lý chưa đạt tiêu chuẩn</th>
        </tr>
        <tr>
          <td rowSpan="7">
            Hoa hồng
            <span style={{ display: 'inline-block', fontWeight: 'bold' }}> Hoa hồng= % x ((Tổng tiền thắng-thua cược)-các
            khoản phí)
            </span>
                  Các khoản phí bao gồm: phí bản quyền, phí gửi-rút tiền và tổng khuyến mãi khách đã nhận...
          </td>
          <td>Từ 10 triệu đến 25 triệu</td>
          <td rowSpan="3">30.0%</td>
          <td>5.0%</td>
        </tr>
        <tr>
          <td>Từ 25 triệu đến 75 triệu</td>
          <td>7.5%</td>
        </tr>
        <tr>
          <td>Từ 75 triệu đến 100 triệu VND</td>
          <td>10.0%</td>
        </tr>
        <tr>
          <td>Từ 100 đến 500 triệu VND</td>
          <td>35.0%</td>
          <td>15.0%</td>
        </tr>
        <tr>
          <td>Từ 500 triệu đến 1 tỷ</td>
          <td>37.5%</td>
          <td>30.0%</td>
        </tr>
        <tr>
          <td>Trên 1 tỷ</td>
          <td>40.0%</td>
          <td>40.0%</td>
        </tr>
        <tr>
          <td>VIP</td>
          <td>&gt;40%</td>
          <td />
        </tr>
        <tr>
          <td>Tiêu chuẩn cơ bản của đại lý</td>
          <td />
          <td colSpan="2">
            <span style={{ display: 'inline-block' }}>- Có tối thiểu 5 thành viên hoạt động trong tháng</span>
            <span style={{ display: 'inline-block' }}>- Có số lượng hoa hồng đạt được từ 10 triệu trở lên</span>
          </td>
        </tr>
      </table>
    )
  }

  render() {
    // const { translate } = this.props
    const { page } = this.props
    return (
      <div className="news-page commission-page clearfix">
        <div className="wrapper-inner">
          <div className="row">
            {/* <div className="info-left col-12 col-md-4 col-lg-3 nopadding">
              <SidebarLeft />
            </div> */}
            <div className="info-content col-12 col-md-12 col-lg-12 nopadding">
              <div className="white-box">
                <div className="box-title">Chính sách hoa hồng</div>
                <div className="box-content">
                  {
                    page && page.list
                    && <div className="item-desc" dangerouslySetInnerHTML={{ __html: page.list['chinh-sach-hoa-hong'] ? page.list['chinh-sach-hoa-hong'].content : '' }} />
                  }
                  {/* <div className="item-details">
                    <h3>Các mức doanh thu dành cho đại lý như sau: :</h3>
                    <div className="table-box col-12 col-md-10 offset-md-1 nopadding">
                      <div className="table-title first row">
                        <div className="table-item first col-4 nopadding text-uppercase">Lợi nhuận Alo88</div>
                        <div className="table-item col-2 nopadding text-uppercase">Hoa hồng %</div>
                        <div className="table-item  col-3 nopadding text-uppercase">Số thành viên hoạt động tối thiểu</div>
                        <div className="table-item last col-3 nopadding text-uppercase">Số thành viên mới tối thiểu</div>
                      </div>
                      {this.renderCommissionTable()}
                    </div>
                    {this.renderCommissionTable()}
                    Đơn vị tính: 1,000 VND
                  </div> */}
                  {/*
                  <div className="item-estimate col-12 col-md-10 offset-md-1 nopadding">
                    <div className="title text-uppercase text-center">Công cụ tính hoa hồng dự kiến</div>
                     <CommissionEstimate/>
                  </div>
                 */}
                  {/* <div className="item-info">
                    <p><strong>Số thành viên:</strong> là số người chơi mà đại lý có được thông qua các link mời người chơi tham gia game. Và các người chơi này có phát sinh ít nhất 1 ván đánh ở các bàn cược VND từ ngày 1 đến ngày cuối cùng của tháng hiện tại</p>
                    <p><strong>Tiền lãi:</strong> là số doanh thu từ phí của ván đánh phát sinh bởi tất cả các thành viên của đại lý đó, tính từ ngày 1 đến ngày cuối cùng của tháng hiện tại</p>
                    <p><strong>CÔNG CỤ TÍNH HOA HỒNG DỰ KIẾN:</strong> dùng để ước tính số tiền thực nhận của 1 đại lý khi có các định mức Tiền Lãi, Số Thành Viên</p>
                    <p className="text-center">Hoa hồng = Tiền Lãi Ròng x % hoa hồng</p>
                    <p><strong>Ví dụ:</strong> Fusce nec gravida massa. Quisque at leo a eros egestas consequat et nec nisi. Aliquam rhoncus blandit odio, non faucibus augue tristique non.</p>

                    <h3>3. Công thức hoa hồng đại lý :</h3>

                    <p>Vestibulum tempor arcu quam, nec hendrerit tortor accumsan vel. Sed a elit faucibus, vestibulum lectus sed, varius ante. Nullam sodales dolor in condimentum ultrices. Nulla faucibus, turpis in rutrum consequat, metus leo venenatis dolor, vel aliquam sem urna sed nibh. Donec nec dolor sed ex bibendum euismod. Mauris ultricies, diam at rhoncus viverra, orci sapien aliquet tortor, at maximus quam ipsum non ipsum. Sed pellentesque euismod lectus, in vehicula ex interdum sed. Duis dignissim, tortor pretium pharetra gravida, sapien tellus sollicitudin nulla, vitae porta nisl ipsum et elit. Duis a dui suscipit, finibus massa vitae, porta urna. Morbi quis sollicitudin felis, non rhoncus ligula.</p>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Commission
