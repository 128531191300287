import React from 'react'
import t from 'tcomb-form'
// import md5 from 'md5'

import isEmpty from 'lodash/isEmpty'

// import { NotificationManager } from 'react-notifications'
import { TCombForm } from '../../../ui'

import { Images } from '../../../ui/assets/images'
import { change_alias } from '../../../helpers'

const _ = {
  isEmpty
}

class Register extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: {
      },
      captchaRef: null,
      errorsMsg: ''
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCallback = this.onCallback.bind(this)
  }

  onChange(value) {
    if (value.supportRequired) {
      const tmp = value.supportRequired.split('|')
      const curInfo = this.state.supportRequired || []
      if (tmp[1] === 'true') {
        curInfo.push(tmp[0])
        this.setState((state) => {
          return { ...state, supportRequired: curInfo }
        })
      } else {
        const index = curInfo.indexOf(tmp[0])
        if (index > -1) {
          curInfo.splice(index, 1)
          this.setState((state) => {
            return { ...state, supportRequired: curInfo }
          })
        }
      }
    } else if (value.captcha && value.captcha.ref) {
      const { ref } = value.captcha
      value = { ...value, captcha: value.captcha.value }
      this.setState({ value, errorsMsg: '', captchaRef: ref })
    } else {
      this.setState({ value, errorsMsg: '' })
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const value = this.profileForm.getValue()
    if (value) {
      this.setState({ errorsMsg: '' })
      const params = {
        // username: value.username,
        // password: md5(value.password),
        displayName: value.fullname,
        phone: `${e.target.elements.prefixPhone.value}-${value.phone}`,
        email: change_alias(value.email),
        howToGetMember: value.howToGetMember,
        supportRequired: this.state.supportRequired || [],
        othersSupport: value.othersSupports || '',
        refCode: value.refCode || '',
        captcha: value.captcha || 'test'
      }
      this.props.onSubmit(params, this.onCallback)
    }
  }

  onCallback(status, data) {
    if (status) {
      this.props.redirectSuccess()
    } else {
      this.refreshCaptcha()
      this.setState({ errorsMsg: data.message })
    }
  }

  renderErrors() {
    if (!_.isEmpty(this.state.errorsMsg)) {
      return (
        <div className="alert alert-danger">{this.state.errorsMsg}</div>
      )
    }
    if (!_.isEmpty(this.props.errorsRegister)) {
      return (
        <div className="alert alert-danger">{this.props.errorsRegister}</div>
      )
    }
    return null
  }

  refreshCaptcha() {
    if (this.state.captchaRef) {
      this.state.captchaRef.props.grecaptcha.reset()
    }
  }

  componentDidMount() {
    if (this.props.refcode) {
      this.setState({ value: { refCode: this.props.refcode } })
    }
  }

  render() {
    const { translate } = this.props
    // const formatUser = /^[A-Za-z0-9]{5,11}$/
    const formatEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // const user = t.refinement(t.String, (s) => {
    //   return formatUser.test(s) || formatEmail.test(s.trim())
    // })
    const Email = t.refinement(t.String, (s) => {
      return formatEmail.test(s.trim())
    })

    // const formatPass = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d/!/@/#/$/%/^/&/*/(/)/=/_/+/?]{8,16}$/
    // const pass = t.refinement(t.String, (s) => {
    //   return formatPass.test(s)
    // })

    const full = t.refinement(t.String, n => n.length >= 6)

    const formatPhone = t.refinement(t.Number, (s) => {
      return s >= 99999999 && s <= 9999999999
    })

    const policy = t.refinement(t.Boolean, (s) => {
      return s
    })

    const howToGetMember = t.refinement(t.String, n => n.length >= 0)

    // const formatRefCOde = t.refinement(t.String, (n) => {
    //   return !n || n
    // })

    const Person = t.struct({
      // username: user,
      // password: pass,
      fullname: full,
      phone: formatPhone,
      email: Email,
      howToGetMember,
      refCode: t.maybe(t.String),
      supportRequired: t.maybe(t.String),
      othersSupports: t.maybe(t.String),
      // refCode: t.maybe(t.String),
      license: policy,
      captcha: t.String
    })

    const options = {
      auto: 'placeholders',
      fields: {
        // username: {
        //   label: translate('Username'),
        //   error: translate('validUsername'),
        //   attrs: {
        //     placeholder: translate('placeUsername')
        //   },
        //   template: TCombForm.TextRegister
        // },
        // password: {
        //   label: translate('password'),
        //   error: translate('validPassword'),
        //   attrs: {
        //     type: 'password',
        //     placeholder: translate('placePassword')
        //   },
        //   template: TCombForm.TextRegister
        // },
        fullname: {
          label: translate('Fullname'),
          error: translate('validFullname'),
          attrs: {
            placeholder: translate('placeFullname')
          },
          template: TCombForm.TextRegister
        },
        phone: {
          label: translate('Phone'),
          error: translate('validPhone'),
          attrs: {
            placeholder: translate('placePhone')
          },
          template: TCombForm.TextPhone
        },
        email: {
          label: translate('yourEmail'),
          error: translate('validEmail'),
          attrs: {
            placeholder: translate('yourEmail')
          },
          template: TCombForm.TextRegister
        },
        refCode: {
          label: translate('refCode'),
          attrs: {
            placeholder: translate('refCodePlaceHolder')
          },
          template: TCombForm.TextRegister
        },
        howToGetMember: {
          label: translate('howToGetMember'),
          error: translate('validHowToGetMember'),
          attrs: {
            placeholder: translate('howToGetMember')
          },
          template: TCombForm.TextRegister
        },
        supportRequired: {
          attrs: {
            title: translate('supportRequired'),
            placeholder: '...',
            items: [
              'Tạo Banner, hình quảng cáo',
              'Bài viết chuẩn SEO giới thiệu website, sản phẩm',
              'Xem Livestream Đá gà',
              'Xem Livestream Bóng đá',
              'Hỗ trợ tỷ lệ kèo'
            ]
          },
          template: TCombForm.ListCheckBox
        },
        othersSupports: {
          label: translate('Ý khác'),
          attrs: {
            placeholder: '...'
          },
          template: TCombForm.TextRegister
        },
        // refCode: {
        //   label: translate('RefCode'),
        //   error: translate('validRefCode'),
        //   attrs: {
        //     placeholder: translate('RefCodePlaceHolder'),
        //     disabled: this.props.refcode || false
        //   },
        //   template: TCombForm.TextRefCode
        // },
        license: {
          label: translate('License'),
          error: translate('validLicense'),
          template: TCombForm.checkboxRegister
        },
        captcha: {
          label: translate('Captcha'),
          error: translate('validCaptcha'),
          template: TCombForm.TextCaptcha
        }
      }
    }
    return (
      <div className="account-page register-page clearfix">
        <div className="wrapper-inner">
          <div className="box-info">
            <div className="row">
              <div className="info-left col-12 col-md-7 nopadding">
                <div className="info-title">
                  <ul>
                    <li>{translate('Register')}</li>
                  </ul>
                </div>
                <div className="info-content">
                  <form className="frm-register" onSubmit={this.onSubmit}>
                    {this.renderErrors()}
                    <t.form.Form
                      ref={(element) => { this.profileForm = element }}
                      type={Person}
                      value={this.state.value}
                      options={options}
                      onChange={this.onChange}
                    />

                    <div className="form-group text-center">
                      <button type="submit" className="btn btn-register text-uppercase">{translate('ok')}</button>
                    </div>

                  </form>
                </div>
                <div className="row" style={{ marginBottom: 10 }} />
                <div className="row"><h4>BỘ PHẬN CHĂM SÓC ĐẠI LÝ 24/7</h4></div>
                <div className="row">Mọi vấn đề cần tư vấn, vui lòng liên hệ với bộ phận chăm sóc đại lý của chúng tôi:</div>
                <div className="row">
                  <ul>
                    <li>Zalo: +84794593530</li>
                    <li>Viber/ Telegram: (+63) 94 6887 9999 (<a href="https://t.me/CSKHALO88" rel="noopener noreferrer" target="_blank">@CSKHALO88</a>)</li>
                    <li>Email: <a href="mailto:cskh.alo88@gmail.com" rel="noopener noreferrer" target="_blank">cskh.alo88@gmail.com</a></li>
                  </ul>
                </div>
                <div className="row">Cảm ơn quý khách.</div>
              </div>
              <div className="info-right col-12 col-md-5 nopadding">
                <div className="orange-box">
                  <div className="box-title text-uppercase">Đăng ký đại lý Alo88 !</div>
                  <div className="box-highlight">Cùng biến đam mê thành lợi nhuận</div>
                  <ul>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;100% đăng ký miễn phí</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;50% chiết khấu hoa hồng</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;Cam kết an toàn &amp; bảo mật</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;Luôn thanh toán đúng hạn</li>
                  </ul>
                  <div className="box-img"><img src={Images.imgReg} alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Register
