
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as AccountsAction from '../../redux/accounts/accounts.actions'

import CommissionEstimate from './CommissionEstimate'

class CommissionEstimateContainer extends React.Component {
  constructor(prop) {
    super(prop)
    this.state = {}
  }

  componentWillMount() {
    const { accountAct, userInfo } = this.props
    accountAct.commissionConfig({ accountId: userInfo.accountId })
  }

  render() {
    return (
      <CommissionEstimate
        commissionConfig={this.props.commissionConfig}
      />
    )
  }
}
function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch)
    // generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    commissionConfig: rootState.accountsReducer.commissionConfig
  }
}, mapDispatchToProps)(CommissionEstimateContainer)
