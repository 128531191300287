import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import md5 from 'md5'
import { HashLink as Link } from 'react-router-hash-link'
import Modal from 'react-modal'
import t from 'tcomb-form'
// import { NotificationManager } from 'react-notifications'
// import md5 from 'md5'


import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import { Images } from '../../ui/assets/images'
import { TCombForm } from '../../ui'

import * as GeneralAction from '../../redux/general/general.actions'
import * as AccountsAction from '../../redux/accounts/accounts.actions'


const _ = {
  isEmpty, get
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: {},
      modalLogin: this.props.path === '/login',
      errorsMsg: ''
    }

    this.onCloseModal = this.onCloseModal.bind(this)
    this.onOpenModal = this.onOpenModal.bind(this)


    this.onChange = this.onChange.bind(this)
    this.onSubmitLogin = this.onSubmitLogin.bind(this)
    this.onCallback = this.onCallback.bind(this)

    this.onLogout = this.onLogout.bind(this)
  }

  componentWillMount() {
  }

  onCloseModal() {
    this.setState({
      value: {},
      modalLogin: false,
      errorsMsg: ''
    })
  }

  onOpenModal() {
    this.setState({ modalLogin: true })
  }

  onChange(value) {
    this.setState({ value, errorsMsg: '' })
  }

  onSubmitLogin(e) {
    e.preventDefault()
    const { accountAct } = this.props
    const value = this.profileLogin.getValue()
    if (value) {
      this.setState({ errorsMsg: '' })
      const params = {
        username: value.username,
        password: md5(value.password)
      }
      accountAct.postAccountLogin(params, this.onCallback)
    }
  }

  onCallback(status, data) {
    if (status) {
      this.onCloseModal()
      setTimeout(() => {
        window.location.reload()
      }, 500)
    } else {
      this.setState({ errorsMsg: data.message })
      this.refreshCaptcha()
    }
  }

  onLogout() {
    const { accountAct } = this.props
    // const param = {
    //   clientId: clientInfo.clientId
    // }
    accountAct.postAccountLogout()
  }

  renderErrors() {
    if (!_.isEmpty(this.state.errorsMsg)) {
      return (
        <div className="alert alert-danger">{this.state.errorsMsg}</div>
      )
    }
    return null
  }

  renderHeaderAccount() {
    const { translate, userInfo } = this.props
    if (userInfo && userInfo.accountId) {
      return (
        <div className="header-accounts logined">
          <div className="user-info">
            <div className="user-info-inner row">
              <div className="user-info-item user-info-left"><i className="fa fa-user-o" aria-hidden="true" /></div>
              <div className="user-info-item user-info-right">{_.get(userInfo, 'email', '')}</div>
            </div>
          </div>

          <div className="user-btn">
            <Link className="btn btn-manage" to="/dashboard"><span className="text-uppercase">{translate('ManagePage')}</span></Link>
            &nbsp;&nbsp;&nbsp;
            <Link className="btn btn-logout" to={{}} onClick={() => this.onLogout()}><img src={Images.iconLogout} alt="" /></Link>
          </div>
        </div>
      )
    }
    return (
      <div className="header-accounts no-login">
        {/* <Link className="btn btn-login" to={{}} onClick={() => this.onOpenModal()}><span className="text-uppercase">{translate('Login')}</span></Link> */}
        <Link className="btn btn-register" to="/register"><i className="fa fa-plus" aria-hidden="true" />&nbsp;<span className="text-uppercase">&nbsp;{translate('RegisterPartner')}</span></Link>
      </div>
    )
  }

  goToAnchor(anchor) {
    $('.homepage').click()
    window.location.href = anchor
  }

  goToPage(page) {
    this.props.history.push(page)
    $('body').click()
    // window.location.href = page
  }

  renderMobile() {
    const { translate, userInfo } = this.props
    // if (userInfo && userInfo.userId) {
    if (userInfo && userInfo.accountId) {
      return (
        <div>
          <div className="title-sections">{translate('Hello')}</div>

          <div className="user-sections row">
            <div className="user-sections-inner">
              <i className="fa fa-user-o" aria-hidden="true" />
              &nbsp;&nbsp;&nbsp;
              <span>({_.get(userInfo, 'email', '')})</span>
            </div>
          </div>

          <div className="btn-sections row">
            <div className="btn-green col-7 col-sm-6 nopadding"><Link to="/dashboard">{translate('ManagePage')}</Link></div>
            <div className="btn-blue col-5 col-sm-6 nopadding"><Link to={{}} onClick={() => this.onLogout()}>{translate('Logout')}</Link></div>
          </div>

          <div className="menu-sections">
            <div className="items">
              <div className="item item-1"><Link className="text-uppercase" to="/commission">{translate('CommissionPolicy')}</Link></div>
              {/* <div className="item item-2"><Link className="text-uppercase" to="/pages/khuyen-mai">{translate('Promotion')}</Link></div> */}
              {/* <div className="item item-2"><Link className="text-uppercase" to="/news/event.html">{translate('Promostion')}</Link></div> */}
              <div className="item item-3"><Link className="text-uppercase" to="/pages/dieu-kien-va-dieu-khoan">{translate('Terms')}</Link></div>
              <div className="item item-3"><Link className="text-uppercase" to={{}} onClick={() => this.goToAnchor('/#idFAQ')}>{translate('faq')}</Link></div>
              <div className="item item-4"><Link className="text-uppercase" to={{}} onClick={() => this.goToAnchor('/#idContactus')}>{translate('ContactUs')}</Link></div>
            </div>
          </div>

        </div>
      )
    }
    // const input = t.struct({
    //   username: t.String,
    //   password: t.String
    // })

    // const options = {
    //   auto: 'placeholders',
    //   fields: {
    //     username: {
    //       label: 'disabled',
    //       error: translate('validUsername'),
    //       attrs: {
    //         placeholder: translate('username/Email')
    //       },
    //       config: { img: Images.iconRegisterEmail },
    //       template: TCombForm.TextCustom
    //     },
    //     password: {
    //       label: 'disabled',
    //       error: translate('validPassword'),
    //       attrs: {
    //         type: 'password',
    //         placeholder: translate('password')
    //       },
    //       config: { img: Images.iconRegisterPass },
    //       template: TCombForm.TextCustom
    //     }
    //   }
    // }
    return (
      <div>
        {/* <div className="title-sections row">
          <span className="text-uppercase">{translate('Login')}</span>
        </div>

        <div className="form-sections">
          <form className="frm-login" onSubmit={this.onSubmitLogin}>
            {this.renderErrors()}
            <t.form.Form
              ref={(element) => { this.profileLogin = element }}
              type={input}
              value={this.state.value}
              options={options}
              onChange={this.onChange}
            />

            <div className="form-group">
              <input type="submit" className="btn" value={translate('Login')} />
            </div>
          </form>
        </div>

        <div className="upsell-sections">
          <div className="row">
            <div className="col-6 nopadding text-left">
              <Link to={{}}>{translate('ForgotPassword')}</Link>
            </div>
            <div className="col-6 nopadding text-right">
              <Link to="/register">{translate('RegisterNew')}</Link>
            </div>
          </div>
        </div> */}

        <div className="menu-sections">
          <div className="items">
            <div className="item item-1"><Link className="text-uppercase" to="/commission">{translate('CommissionPolicy')}</Link></div>
            {/* <div className="item item-2"><Link className="text-uppercase" to="/news/event.html">{translate('Promostion')}</Link></div> */}
            {/* <div className="item item-2"><Link className="text-uppercase" to={{}} onClick={() => this.goToPage('/pages/khuyen-mai')}>{translate('Promotion')}</Link></div> */}
            <div className="item item-2"><Link className="text-uppercase" to={{}} onClick={() => this.goToPage('/pages/dieu-kien-va-dieu-khoan')}>{translate('Terms')}</Link></div>
            <div className="item item-3"><Link className="text-uppercase" to={{}} onClick={() => this.goToAnchor('/#idFAQ')}>{translate('faq')}</Link></div>
            <div className="item item-4"><Link className="text-uppercase" to={{}} onClick={() => this.goToAnchor('/#idContactus')}>{translate('ContactUs')}</Link></div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { translate, menuActive } = this.props
    return (
      <header className="header-wrapper clearfix">
        <div className="header">

          <div className="navbar navbar-default navbar-expand-lg">

            <div className="navbar-mobile wrap push">
              <div className="header-mobile">
                <div className="wrapper-inner row">
                  <button className="navbar-toggler menu-link a" type="button">
                    <i className="icon-navbar fa fa-bars" aria-hidden="true" />
                  </button>
                  <Link to="/" className="main-logo"><img src={Images.logo} alt="dailyAlo88" /></Link>
                  <Link className="btn btn-register" to="/register">
                    <i className="fa fa-plus" aria-hidden="true" />&nbsp;
                    <span className="text-uppercase">{translate('RegisterPartner')}</span>
                  </Link>
                  {/* {
                    userInfo && userInfo.accountId ? null : (
                      <Link className="btn btn-register" to="/register">
                        <i className="fa fa-plus" aria-hidden="true" />&nbsp;
                        <span className="text-uppercase">{translate('RegisterPartner')}</span>
                      </Link>
                    )
                  } */}
                </div>
              </div>

            </div>

            <div className="navbar-web" id="mainNavbar">
              <div className="header-main">
                <div className="wrapper-inner row">
                  <div className="header-left col-12 col-lg-9 col-xl-10 nopadding">
                    <div className="row">
                      <div className="main-logo col-12 col-md-12 col-lg-12 col-xl-2 nopadding"><Link to="/"><img src={Images.logo} alt="dailyAlo88" /></Link></div>
                      <div className="main-menu col-12 col-md-12 col-lg-12 col-xl-10 nopadding">
                        <ul>
                          <li className={`item item-1 ${menuActive === 'i-1' ? 'active' : null}`}><Link className="text-uppercase" to="/commission">{translate('CommissionPolicy')}</Link></li>
                          {/* <li className={`item item-4 ${menuActive === 'i-5' ? 'active' : null}`}><Link className="text-uppercase" to="/pages/khuyen-mai">{translate('Promotion')}</Link></li> */}
                          <li className={`item item-4 ${menuActive === 'i-4' ? 'active' : null}`}><Link className="text-uppercase" to="/banner">BANNER</Link></li>
                          {/* <li className={`item item-2 ${menuActive === 'i-2' ? 'active' : null}`}><Link className="text-uppercase" to="/news/event.html">{translate('Promostion')}</Link></li> */}
                          <li className={`item item-3 ${menuActive === 'i-3' ? 'active' : null}`}><Link className="text-uppercase" to="/pages/dieu-kien-va-dieu-khoan">{translate('Terms')}</Link></li>
                          <li className={`item item-3 ${menuActive === 'i-3' ? 'active' : null}`}><Link className="text-uppercase" to="/#idFAQ">{translate('faq')}</Link></li>
                          <li className={`item item-4 ${menuActive === 'i-4' ? 'active' : null}`}><Link className="text-uppercase" to="/#idContactus">{translate('ContactUs')}</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="header-right col-12 col-lg-3 col-xl-2 nopadding">
                    <div className="account-box">
                      {this.renderHeaderAccount()}
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div id="tab-mobile" className="panel tab-mobile" role="navigation">
          {this.renderMobile()}
        </div>

        {this.renderModalLogin()}
      </header>
    )
  }

  renderModalLogin() {
    const { userInfo = null, translate } = this.props
    if (userInfo && userInfo.accountId) {
      return null
    }
    // const formatUser = /^[A-Za-z0-9]{5,11}$/
    // const user = t.refinement(t.String, (s) => {
    //   return formatUser.test(s)
    // })

    const input = t.struct({
      username: t.String,
      password: t.String
    })

    const options = {
      auto: 'placeholders',
      fields: {
        username: {
          label: 'disabled',
          error: translate('validUsername2'),
          attrs: {
            placeholder: translate('username/Email')
          },
          config: { icon: 'fa fa-user' },
          template: TCombForm.TextCustom
        },
        password: {
          label: 'disabled',
          error: translate('validPassword'),
          attrs: {
            type: 'password',
            placeholder: translate('password')
          },
          config: { icon: 'fa fa-lock' },
          template: TCombForm.TextCustom
        }
      }
    }
    return (
      <Modal
        className="modal-account modal-login"
        isOpen={this.state.modalLogin}
        onRequestClose={this.onCloseModal}
      >
        <div className="modal-header-custom">
          <div className="title">{translate('Login')}</div>
          <div className="btn-close" onClick={() => this.onCloseModal()} role="presentation"><i className="fa fa-times" /></div>
        </div>
        <div className="modal-content">
          <form className="frm-modal-register" onSubmit={this.onSubmitLogin}>
            {this.renderErrors()}
            <t.form.Form
              ref={(element) => { this.profileLogin = element }}
              type={input}
              value={this.state.value}
              options={options}
              onChange={this.onChange}
            />

            <div className="form-group">
              <input type="submit" className="btn btn-login text-uppercase" value={translate('Login')} />
            </div>
          </form>

          <div className="form-group text-redirect-login">
            <div className="row">
              <div className="col-6 nopadding">
                <Link className="btn btn-left text-uppercase" to="/forgot-pass">{translate('ForgotPassword')}</Link>
              </div>
              <div className="col-6 nopadding text-right">
                <Link className="btn btn-right text-uppercase" to="/register">{translate('RegisterNew')}</Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  componentDidMount() {
    Modal.setAppElement('body')
    this.bigSlide = $('.menu-link').bigSlide({
      menu: '#tab-mobile',
      side: 'right',
      easyClose: true,
      menuWidth: '80%'
    })
    window.bigslide = this.bigSlide
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch),
    generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    refcodeAgency: rootState.accountsReducer.refcodeAgency,
    captcha: rootState.generalReducer.captcha,
    i18nState: rootState.i18nState
  }
}, mapDispatchToProps)(Header)
