import { put, takeLatest } from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import { types } from '../../redux/accounts'
import { types as apiTypes } from '../../redux/api'


function* doAction(action) {
  try {
    const code = responseCode.POST_ACCOUNT_LOGOUT_SUCCESS
    const data = {}
    switch (code) {
      case responseCode.POST_ACCOUNT_LOGOUT_SUCCESS: {
        yield put({ type: types.POST_ACCOUNT_LOGOUT_SUCCESS, payload: data })
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield put({ type: types.POST_ACCOUNT_LOGOUT_FAIL })
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield put({ type: apiTypes.HIDE_LOADING })
    yield put({ type: types.POST_ACCOUNT_LOGOUT_FAIL })
  }
}

export default function* watchAction() {
  yield takeLatest(types.POST_ACCOUNT_LOGOUT, doAction)
}
