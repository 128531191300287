import React from 'react'
import PropTypes from 'prop-types'
// import MetaTags from 'react-meta-tags'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setLanguage } from 'redux-i18n'
// import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { NotificationContainer } from 'react-notifications'
import * as GeneralActions from '../../redux/general/general.actions'
import * as AccountsAction from '../../redux/accounts/accounts.actions'
import Header from './Header'
import Footer from './Footer'
import Loading from '../loading/Loading'
import SidebarLeft from './sidebar/SidebarLeft.container'


// import * as constants from '../../configs/constants'
// import ChatSupport from '../../components/chatSupport/ChatSupport'

class AfterLogin extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.changeShowMenu = this.changeShowMenu.bind(this)
    this.onChangeLanguage = this.onChangeLanguage.bind(this)
  }

  componentWillMount() {
    const { userInfo, accountAct } = this.props
    if (!userInfo || !userInfo.accountId || !userInfo.accessToken) {
      this.props.history.push('/')
    }
    this.props.dispatch(setLanguage(this.props.lang))
    accountAct.getBalance()
  }

  onChangeLanguage = (val) => {
    const { generalAction } = this.props
    this.props.dispatch(setLanguage(val))
    generalAction.changeLang(val)
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  changeShowMenu() {
    const { generalAction } = this.props
    generalAction.changeClassSidebar()
  }

  render() {
    // const { balanceBtc, userInfo, productList } = this.props
    const Component = this.props.component
    const route = this.props.route  //eslint-disable-line

    const { themeSettings } = this.props
    let clss = 'container-fluid default-layout freepadding'
    if (themeSettings === 'dark') {
      clss = 'container-fluid default-layout dark-color freepadding'
    } else {
      clss = 'container-fluid default-layout default-color freepadding'
    }

    const logined = 0
    return (
      <div className={`afterlogin-page ${clss}`}>
        {/* <MetaTags>
          <title>{constants.HOME_PAGE_TITLE}</title>
        </MetaTags> */}
        <Loading />
        <Header
          history={this.props.history}
          translate={this.context.t}
          onChangeLanguage={this.onChangeLanguage}
          lang={this.props.lang}
          changeShowMenu={this.changeShowMenu}
          logined={logined}
          location={this.props.location}
        />
        <div className="contents-wrapper wrap push">
          <div className="cls-container-main clearfix">
            <div className="wrapper-inner">
              <div className="row">
                <div className="info-left col-12 col-md-4 col-lg-3 nopadding">
                  <SidebarLeft
                    active={this.props.computedMatch.path}
                    classSidebar={this.props.classSidebar}
                    userInfo={this.props.userInfo}
                  />
                </div>
                <div className="container-afterlogin row">
                  <Component route={route} {...this.props} />
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <Footer
          translate={this.context.t}
        />
        <NotificationContainer />
        {/* <ChatSupport /> */}
      </div>
    )
  }

  // componentDidMount() {
  //   const { location, lang, userInfo } = this.props
  //   const langVal = location ? location.search ? location.search.substring(6) : '' : ''

  //   if (langVal !== '' && langVal !== lang) {
  //     if (langVal === 'vi' || langVal === 'en') {
  //       this.onChangeLanguage(langVal)
  //     }
  //   }

  //   if (this.props.private === true && userInfo && !userInfo.userId) {
  //     this.props.history.push({
  //       pathname: '/',
  //       state: { popupLogin: true }
  //     })
  //   }

  //   if (location.state && location.state.popupLogin && userInfo && !userInfo.userId) {
  //     NotificationManager.warning(this.context.t('validLoginFirst'), '', 6000)
  //   }
  // }
}

function mapDispatchToProps(dispatch) {
  return {
    generalAction: bindActionCreators(GeneralActions, dispatch),
    accountAct: bindActionCreators(AccountsAction, dispatch),
    dispatch: bindActionCreators(dispatch, dispatch)
  }
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.accountsReducer.userInfo,
      themeSettings: payload.apiResponse.themeSettings,
      // lang: payload.i18nState.lang,
      lang: payload.generalReducer.lang,
      classSidebar: payload.generalReducer.classSidebar
    }
  }, mapDispatchToProps)(AfterLogin)
)
