import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import reverse from 'lodash/reverse'

import * as GeneralAction from '../../../redux/general/general.actions'
import * as AccountsAction from '../../../redux/accounts/accounts.actions'

import { Images } from '../../../ui/assets/images'

import ForgotPass from './ForgotPass'


class ForgotPassContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(prop) {
    super(prop)
    this.state = {
      captchaImg: Images.loading,
      captchaToken: ''
    }

    this.refreshCaptcha = this.refreshCaptcha.bind(this)
    this.onCallbackCaptcha = this.onCallbackCaptcha.bind(this)

    this.onSubmit = this.onSubmit.bind(this)

    this.redirectSuccess = this.redirectSuccess.bind(this)
  }

  refreshCaptcha() {
    // const { generalAct } = this.props
    // generalAct.postCaptcha(param, this.onCallbackCaptcha)
  }

  onCallbackCaptcha(status, data) {
    if (status) {
      this.setState({
        captchaImg: data.imgCaptcha,
        captchaToken: data.token
      })
    }
  }

  onSubmit(params, thisCallback) {
    const { AccountsAct } = this.props

    AccountsAct.postAccountForgotPassword(params, thisCallback)
  }

  redirectSuccess() {
    this.props.history.push('/forgot-pass/success')
  }

  render() {
    const { captcha } = this.props

    return (
      <ForgotPass
        translate={this.context.t}

        captchaImg={this.state.captchaImg}
        captchaToken={captcha.token || this.state.captchaToken}

        onSubmit={this.onSubmit}
        refreshCaptcha={this.refreshCaptcha}
        redirectSuccess={this.redirectSuccess}
      />
    )
  }

  componentDidMount() {
    const { userInfo } = this.props
    if (userInfo && userInfo.accountId) {
      this.props.history.push('/')
    } else {
      this.refreshCaptcha()
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralAction, dispatch),
    AccountsAct: bindActionCreators(AccountsAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    captcha: rootState.generalReducer.captcha,
    lang: rootState.i18nState.lang
  }
}, mapsDispatchToProp)(ForgotPassContainer)
