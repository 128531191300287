
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as GeneralAction from '../../redux/general/general.actions'

import Details from './Details'

class PagesContainer extends React.Component {
  constructor(prop) {
    super(prop)
    const { params } = this.props.computedMatch
    const { alias_unique = '' } = params
    this.state = { alias_unique }
    this.updatePage = this.updatePage.bind(this)
  }

  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  componentWillMount() {
    console.log(this.props)
    const { generalAct } = this.props
    generalAct.getPage({ alias_unique: this.state.alias_unique })
  }

  updatePage() {
    const { params } = this.props.computedMatch
    const { alias_unique = '' } = params
    if (alias_unique !== this.state.alias_unique) {
      this.setState({
        alias_unique
      })
      const { generalAct } = this.props
      generalAct.getPage({ alias_unique })
    }
  }

  componentDidUpdate() {
    this.updatePage()
  }

  render() {
    return (
      <Details
        translate={this.context.t}
        page={this.props.page}
        alias_unique={this.state.alias_unique}
      />
    )
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    page: rootState.generalReducer.page
  }
}, mapsDispatchToProp)(PagesContainer)
