import React from 'react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import ComponentExtend from '../../ComponentExtend'


class DateRange extends ComponentExtend {
  constructor(props) {
    super(props)

    this.state = {
      fromDate: this.props.fromDate,
      toDate: this.props.toDate
    }
    this.applyCalendar = this.applyCalendar.bind(this)
  }

  applyCalendar(key, value) {
    if (key === 'fromDate') {
      this.setState({
        fromDate: moment(value).format('YYYY-MM-DD')
      })
    } else {
      this.setState({
        toDate: moment(value).format('YYYY-MM-DD')
      })
    }
  }

  render() {
    return (
      <div className="block-daterage" style={{ display: 'inline-block' }}>
        <DateRangePicker
          singleDatePicker
          maxDate={this.props.maxDate}
          onApply={(event, picker) => {
            this.applyCalendar('fromDate', moment(picker.startDate).toISOString())
          }}
        >
          <div className="datePicker">
            <i className="fa fa-calendar" aria-hidden="true" />
            <input type="text" name="fromDate" value={this.state.fromDate} readOnly />
          </div>
        </DateRangePicker>
        <span><i className="fa fa-arrow-right" aria-hidden="true" /></span>
        <DateRangePicker
          singleDatePicker
          // maxDate={this.props.maxDate}
          onApply={(event, picker) => {
            this.applyCalendar('toDate', moment(picker.startDate).toISOString())
          }}
        >
          <div className="datePicker">
            <i className="fa fa-calendar" aria-hidden="true" />
            <input type="text" name="toDate" value={this.state.toDate} readOnly />
          </div>
        </DateRangePicker>
      </div>
    )
  }
}
export default DateRange
