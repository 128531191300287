import { call, put, takeLatest } from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import { types } from '../../redux/accounts'
import { types as apiTypes } from '../../redux/api'
import { accountsAPI } from '../../api'

function* doAction(action) {
  try {
    // yield put({ type: apiTypes.SHOW_LOADING })
    // const { code, data } = yield call(accountsAPI.postAccountLogin, action.payload)
    // yield put({ type: apiTypes.HIDE_LOADING })

    // Temporary no login
    const code = responseCode.REQUEST_FAIL
    const data = null
    //

    switch (code) {
      case responseCode.POST_ACCOUNT_LOGIN_SUCCESS: {
        yield put({ type: types.UPDATE_ACCESS_TOKEN, payload: data.accessToken })
        const rsProfile = yield call(accountsAPI.getAccountGetProfile, { accessToken: data.accessToken })
        if (rsProfile && rsProfile.code && rsProfile.code === responseCode.REQUEST_SUCCESS) {
          let rsData = { ...data, ...rsProfile.data }
          const rsGetBalance = yield call(accountsAPI.accountGetBalance, { accessToken: data.accessToken })
          if (rsGetBalance && rsGetBalance.code && rsGetBalance.code === responseCode.REQUEST_SUCCESS) {
            rsData = { ...data, ...rsProfile.data, ...rsGetBalance.data }
          }
          yield put({ type: types.POST_ACCOUNT_LOGIN_SUCCESS, payload: rsData })
          if (action.callback) {
            action.callback(true, rsData)
          }
        } else {
          yield put({ type: types.POST_ACCOUNT_LOGIN_FAIL })
          if (action.callback) {
            action.callback(false, rsProfile.data)
          }
        }

        break
      }

      case responseCode.POST_ACCOUNT_LOGIN_NEXT_STEP: {
        if (action.callback) {
          action.callback('POST_ACCOUNT_LOGIN_NEXT_STEP', data)
        }
        break
      }

      default: {
        yield put({ type: types.POST_ACCOUNT_LOGIN_FAIL })
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield put({ type: apiTypes.HIDE_LOADING })
    yield put({ type: types.POST_ACCOUNT_LOGIN_FAIL })
  }
}

export default function* watchAction() {
  yield takeLatest(types.POST_ACCOUNT_LOGIN, doAction)
}
