import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as GeneralAction from '../../../redux/general/general.actions'

class ElmCaptcha extends React.Component {
  constructor(prop) {
    super(prop)
    this.getCaptcha = this.getCaptcha.bind(this)
  }

  getCaptcha() {
    const { generalAct } = this.props
    generalAct.postCaptcha()
  }

  render() {
    const { locals, captcha, modal = true } = this.props
    // console.log('-------captcha', captcha)
    const { imgCaptcha = null } = captcha
    const hasError = locals.hasError === true ? 'has-error' : ''
    const msg = locals.error ? <span className="help-block error-block">{locals.error}</span> : ''
    const { config: { icon } } = locals
    const label = locals.label ? locals.label : locals.attrs.name
    if (modal) {
      return (
        <div className={`eform-input eform-input-${locals.attrs.name} ${hasError}`}>
          <div className="eform-desc">
            <div className="row">
              <input
                className="eform-input-item form-control placeholder-no-fix"
                autoComplete="off"
                value={locals.value}
                onChange={event => locals.onChange(event.target.value)}
                {...locals.attrs}
              />
              {
                locals.config ? icon
                  ? <span className="input-group-addon"><i className={icon} /></span>
                  : null : null
              }
              {
                locals.config ? imgCaptcha
                  ? (<span className="input-captcha-img"><img src={imgCaptcha} alt="" /><a href="#" className="refresh-captcha" onClick={this.getCaptcha}><i className="fa fa-refresh" aria-hidden="true" /></a></span>)
                  : null : null
              }
            </div>
            {msg}
          </div>
          <div className="clearfix" />
        </div>
      )
    }
    return (
      <div className={`eform-input eform-input-${locals.attrs.name} ${hasError} row`}>
        <div className="eform-label col-12 col-lg-4 nopadding">{label}<span>:</span></div>
        <div className="eform-desc col-12 col-lg-8 nopadding">
          <div className="row">
            <input
              className="eform-input-item form-control placeholder-no-fix"
              autoComplete="off"
              value={locals.value}
              onChange={event => locals.onChange(event.target.value)}
              {...locals.attrs}
            />
            {
              locals.config ? imgCaptcha
                ? <span className="input-captcha-img"><img src={imgCaptcha} alt="" /><a href="#" className="refresh-captcha" onClick={this.getCaptcha}><i className="fa fa-refresh" aria-hidden="true" /></a></span>
                : null : null
            }
          </div>
          {msg}
        </div>
        <div className="clearfix" />
      </div>
    )
  }
}
function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralAction, dispatch)

  }
}

export default connect((rootState) => {
  return {
    captcha: rootState.generalReducer.captcha
  }
}, mapsDispatchToProp)(ElmCaptcha)
