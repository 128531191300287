import { put, takeLatest } from 'redux-saga/effects'
// import * as responseCode from '../../configs/responseCode.config'
import { types } from '../../redux/accounts'
import { types as apiTypes } from '../../redux/api'
// import { accountsAPI } from '../../api'

function* doAction() {
  try {
    // yield put({ type: apiTypes.SHOW_LOADING })
    // const { code, data } = yield call(accountsAPI.postAccountLoginStep2, action.payload)
    // yield put({ type: apiTypes.HIDE_LOADING })
    // switch (code) {
    //   case responseCode.POST_ACCOUNT_LOGIN_STEP2_SUCCESS: {
    //     const rsProfile = yield call(accountsAPI.getAccountGetProfile, { accessToken: data.accessToken })
    //     if (rsProfile && rsProfile.code && rsProfile.code === responseCode.REQUEST_SUCCESS) {
    //       let rsData = { ...rsProfile.data, ...data }
    //       const rsGetBalance = yield call(accountsAPI.accountGetBalance, { accessToken: data.accessToken })
    //       if (rsGetBalance && rsGetBalance.code && rsGetBalance.code === responseCode.REQUEST_SUCCESS) {
    //         rsData = { ...rsProfile.data, ...data, ...rsGetBalance.data }
    //       }
    //       yield put({ type: types.POST_ACCOUNT_LOGIN_STEP2_SUCCESS, payload: rsData })
    //       if (action.callback) {
    //         action.callback(true, rsData)
    //       }
    //     } else {
    //       yield put({ type: types.POST_ACCOUNT_LOGIN_STEP2_FAIL })
    //       if (action.callback) {
    //         action.callback(false, rsProfile.data)
    //       }
    //     }
    //     break
    //   }

    //   default: {
    //     yield put({ type: types.POST_ACCOUNT_LOGIN_STEP2_FAIL })
    //     if (action.callback) {
    //       action.callback(false, data)
    //     }
    //   }
    // }
  } catch (error) {
    yield put({ type: apiTypes.HIDE_LOADING })
    yield put({ type: types.POST_ACCOUNT_LOGIN_STEP2_FAIL })
  }
}

export default function* watchAction() {
  yield takeLatest(types.POST_ACCOUNT_LOGIN_STEP2, doAction)
}
