import React from 'react'
// import numeral from 'numeral'
import Modal from 'react-modal'
import _ from 'lodash'
import md5 from 'md5'
import t from 'tcomb-form'
import Breadcrumb from '../blocks/breadcrumb/Breadcrumb'
import ComponentExtend from '../ComponentExtend'
import { TCombForm } from '../../ui'


class Profile extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      showChangePass: false,
      valueForm: {},
      resendEmailValue: {},
      showResendEmail: false
    }
    this.onSubmitChangePass = this.onSubmitChangePass.bind(this)
    this.closeModalChangePass = this.closeModalChangePass.bind(this)
    this.showModalChangePass = this.showModalChangePass.bind(this)
    this.submitResendMail = this.submitResendMail.bind(this)
    this.callBackChangePass = this.callBackChangePass.bind(this)
    this.showModalResendMail = this.showModalResendMail.bind(this)
    this.closeModalResendEmail = this.closeModalResendEmail.bind(this)
    this.callBackSendLinkActive = this.callBackSendLinkActive.bind(this)
  }

  renderCommissionConfig() {
    // const { commissionConfig } = this.props
    // if (commissionConfig && commissionConfig.config && commissionConfig.config.length > 0) {
    //   return commissionConfig.config.map((item, k) => {
    //     const key = k + 1
    //     let profit = ''
    //     if (item.maxProfit === null) {
    //       profit = `>= ${numeral(item.minProfit).format('0,0')}`
    //     } else {
    //       profit = `${numeral(item.minProfit).format('0,0')} - ${numeral(item.maxProfit).format('0,0')}`
    //     }
    //     return (
    //       <tr key={key}>
    //         <th scope="row">{key}</th>
    //         <td>{profit}</td>
    //         <td>{item.numMember}</td>
    //         <td>{item.commission * 100}%</td>
    //       </tr>
    //     )
    //   })
    // }
    // return null
    return (
      <table style={{ width: '100%' }}>
        <tr>
          <th>Chính sách</th>
          <th>Cụ thể</th>
          <th>Đại lý đạt tiêu chuẩn</th>
          <th>Đại lý chưa đạt tiêu chuẩn</th>
        </tr>
        <tr>
          <td rowSpan="7">
            Hoa hồng
            <span style={{ display: 'inline-block', fontWeight: 'bold' }}> Hoa hồng= % x ((Tổng tiền thắng-thua cược)-các
            khoản phí)
            </span>
                  Các khoản phí bao gồm: phí bản quyền, phí gửi-rút tiền và tổng khuyến mãi khách đã nhận...
          </td>
          <td>Từ 10 triệu đến 25 triệu</td>
          <td rowSpan="3">30.0%</td>
          <td>5.0%</td>
        </tr>
        <tr>
          <td>Từ 25 triệu đến 75 triệu</td>
          <td>7.5%</td>
        </tr>
        <tr>
          <td>Từ 75 triệu đến 100 triệu VND</td>
          <td>10.0%</td>
        </tr>
        <tr>
          <td>Từ 100 đến 500 triệu VND</td>
          <td>35.0%</td>
          <td>15.0%</td>
        </tr>
        <tr>
          <td>Từ 500 triệu đến 1 tỷ</td>
          <td>37.5%</td>
          <td>30.0%</td>
        </tr>
        <tr>
          <td>Trên 1 tỷ</td>
          <td>40.0%</td>
          <td>40.0%</td>
        </tr>
        <tr>
          <td>VIP</td>
          <td>&gt;40%</td>
          <td />
        </tr>
        <tr>
          <td>Tiêu chuẩn cơ bản của đại lý</td>
          <td />
          <td colSpan="2">
            <span style={{ display: 'inline-block' }}>- Có tối thiểu 5 thành viên hoạt động trong tháng</span>
            <span style={{ display: 'inline-block' }}>- Có số lượng hoa hồng đạt được từ 10 triệu trở lên</span>
          </td>
        </tr>
      </table>
    )
  }

  closeModalChangePass() {
    this.setState({
      showChangePass: false
    })
  }

  showModalChangePass() {
    this.setState({
      showChangePass: true,
      errorChangePass: ''
    })
  }

  callBackChangePass(status, data) {
    if (status) {
      alert(this.l('Thành công'))
      this.closeModalChangePass(this)
    } else {
      this.setState({
        errorChangePass: data.message
      })
    }
  }

  showModalResendMail() {
    this.setState({ showResendEmail: true })
  }

  closeModalResendEmail() {
    this.setState({ showResendEmail: false })
  }

  submitResendMail(e) {
    e.preventDefault()
    const value = this.profileForm.getValue()
    if (value) {
      this.setState({ errorsMsg: '' })
      const params = {
        captcha: value.captcha
      }
      this.props.sendLinkActiveEmail(params, this.callBackSendLinkActive)
    }
  }

  callBackSendLinkActive(status, data) {
    if (status) {
      setTimeout(() => {
        this.props.cleanSendLinkActiveEmail()
      }, 60000)
      this.setState({ showResendEmail: false })
    } else {
      // this.props.getCaptcha()
    }
    alert(data.message)
  }

  onSubmitChangePass(event) {
    event.preventDefault()
    const formData = {}
    for (let i = 0; i < event.target.elements.length; i++) {
      if (event.target[i].name) {
        formData[event.target[i].name] = event.target[event.target[i].name].value
      }
    }
    if (_.isEmpty(formData.oldPass) || _.isEmpty(formData.newPass) || _.isEmpty(formData.preNewPass)) {
      this.setState({
        errorChangePass: this.l('Vui lòng kiểm tra dữ liệu nhập')
      })
    } else if (formData.newPass !== formData.preNewPass) {
      this.setState({
        errorChangePass: this.l('Nhập lại mật khẩu mới không trùng khớp')
      })
    } else {
      this.props.submitChangePass({ oldPassword: md5(formData.oldPass), newPassword: md5(formData.newPass) }, this.callBackChangePass)
    }
  }

  renderModalChangePass() {
    return (
      <Modal
        className="modal-afterLogin block-content-afterlogin"
        isOpen={this.state.showChangePass}
      >
        <div className="modal-header-custom head-afterlogin">
          <div className="">{this.l('Thay đổi mật khẩu')}</div>
          <div className="btn-close" onClick={() => this.closeModalChangePass()} role="presentation"><i className="fa fa-times" /></div>
        </div>
        <hr />
        <div className="contain-block-afterlogin">
          <form className="form-horizontal" onSubmit={this.onSubmitChangePass}>
            <div className="form-group">
              <label htmlFor="inputEmail3" className="col-md-4 control-label">{this.l('MK cũ')}</label>
              <div className="col-sm-8">
                <input type="password" className="form-control" id="inputEmail3" placeholder={this.l('Nhập mật khẩu cũ')} name="oldPass" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputPassword3" className="col-sm-4 control-label">{this.l('MK mới')}</label>
              <div className="col-sm-8">
                <input type="password" className="form-control" id="inputPassword3" placeholder={this.l('Nhập mật khẩu mới')} name="newPass" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputPassword3" className="col-sm-4 control-label" style={{ verticalAlign: 'top' }}>{this.l('Nhập lại MK mới')}</label>
              <div className="col-sm-8">
                <input type="password" className="form-control" id="inputPassword3" placeholder={this.l('Nhập lại mật khẩu mới')} name="preNewPass" />
              </div>
            </div>
            <div className="form-group" style={{ display: (this.state.errorChangePass === '') ? 'none' : 'block' }}>
              <label htmlFor="inputPassword3" className="col-sm-4 control-label">&nbsp;</label>
              <div className="col-sm-8 error-block">{this.state.errorChangePass}</div>
            </div>
            <div className="form-group">
              <center className="col-sm-12">
                <button type="submit" className="btn btn btn-primary ">{this.l('Đồng ý')}</button>
              </center>
            </div>
          </form>
        </div>
      </Modal>
    )
  }


  onResendEmailFormValueChange = (value) => {
    this.setState({ resendEmailValue: value, errorsMsg: '' })
  }

  renderModalResendEamil() {
    const { translate } = this.props
    const Captcha = t.struct({
      captcha: t.String
    })

    const options = {
      auto: 'placeholders',
      fields: {
        captcha: {
          label: translate('Captcha'),
          error: translate('validCaptcha'),
          template: TCombForm.TextCaptcha
        }
      }
    }

    return (
      <Modal
        className="modal-afterLogin block-content-afterlogin"
        isOpen={this.state.showResendEmail}
      >
        <div className="modal-header-custom head-afterlogin">
          <div className="">{this.l('Gửi lại link kích hoạt email')}</div>
          <div className="btn-close" onClick={() => this.closeModalResendEmail()} role="presentation"><i className="fa fa-times" /></div>
        </div>
        <hr />
        <div className="contain-block-afterlogin">
          <form className="form-horizontal" onSubmit={this.submitResendMail}>
            <div className="form-group">
              <t.form.Form
                ref={(element) => { this.profileForm = element }}
                type={Captcha}
                value={this.state.resendEmailValue}
                options={options}
                onChange={this.onResendEmailFormValueChange}
              />
            </div>
            <div className="form-group">
              <center className="col-sm-12">
                <button type="submit" className="btn btn btn-primary ">{this.l('Đồng ý')}</button>
              </center>
            </div>
          </form>
        </div>
      </Modal>
    )
  }

  render() {
    const { userInfo } = this.props
    return (
      <div className="cls-aflg-profile row">
        <Breadcrumb arrBreadcrumb={[{ title: this.l('Thông tin tài khoản') }]} />
        <div className="row">
          <div className="col-md-5 nopadding">
            <div className="cls-block-info-user block-content-afterlogin">
              <div className="head-afterlogin">{this.l('Thông tin cá nhân')}</div>
              <hr />
              <div className="contain-block-afterlogin cls-block-info-user">
                <div><span>{this.l('Tên')}</span>:<span>{userInfo.displayName}</span> </div>
                <div>
                  <span>{this.l('Email')}</span>:
                  <span>
                    {userInfo.email}
                    {/* {(!userInfo.isVerify && this.props.isSendLinkActiveEmail === false) ? (<button className="btn btn-primary" type="button" onClick={this.showModalResendMail}>{this.l('Kích hoạt')}</button>) : null} */}
                  </span>
                </div>
                <div><span>{this.l('Điện thoại')}</span>:<span>{userInfo.phone || ''}</span> </div>
                <div><span>{this.l('Ngôn ngữ')}</span>:<span>{userInfo.lang || 'Tiếng việt'}</span> </div>
              </div>
            </div>
            <div className="cls-block-info-pass block-content-afterlogin">
              <div className="head-afterlogin">{this.l('Thông tin đăng nhập')}</div>
              <hr />
              <div className="contain-block-afterlogin cls-block-info-pass">
                <div><span>{this.l('Tên đăng nhập')}</span>:<span>{userInfo.username || ''}</span> </div>
                <div>
                  <span>{this.l('Mật khẩu')}</span>:
                  <span>
                    {this.l('******')}
                    <button className="btn btn-primary-cs button-change-pass" type="button" onClick={this.showModalChangePass}>{this.l('Thay đổi')}</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 info-config-commission">
            <div className="cls-block-info-commisstion block-content-afterlogin">
              <div className="head-afterlogin">{this.l('Thông tin tỉ lệ hoa hồng')}</div>
              <div className="contain-block-afterlogin">
                <div className="table-reponsive">
                  {/* <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{this.l('Mức')}</th>
                        <th scope="col">{this.l('Tổng lợi nhuận rồng hàng tháng')}</th>
                        <th scope="col">{this.l('Số thành viên hoạt động mỗi tháng')}</th>
                        <th scope="col">{this.l('Tỉ lệ hoa hồng')}</th>
                      </tr>
                    </thead>
                    <tbody> */}
                  {this.renderCommissionConfig()}
                  {/* </tbody>
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderModalChangePass()}
        {this.renderModalResendEamil()}
      </div>
    )
  }
}

export default Profile
