import React from 'react'
import PropTypes from 'prop-types'
// import MetaTags from 'react-meta-tags'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { NotificationManager } from 'react-notifications'

import Loading from '../loading/Loading'


// import * as constants from '../../configs/constants'
// import ChatSupport from '../../components/chatSupport/ChatSupport'

class Home extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {}

    this.onChangeLanguage = this.onChangeLanguage.bind(this)
  }

  onChangeLanguage = (val) => {
    this.props.dispatch({
      type: 'REDUX_I18N_SET_LANGUAGE',
      lang: val
    })
    // window.location.reload()
  }

  render() {
    // const { balanceBtc, userInfo, productList } = this.props
    const Component = this.props.component
    const route = this.props.route  //eslint-disable-line
    return (
      <div>
        <Loading />
        <div>
          <Component route={route} {...this.props} />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { location, lang, userInfo } = this.props
    const langVal = location ? location.search ? location.search.substring(6) : '' : ''

    if (langVal !== '' && langVal !== lang) {
      if (langVal === 'vi' || langVal === 'en') {
        this.onChangeLanguage(langVal)
      }
    }

    if (this.props.private === true && userInfo && !userInfo.userId) {
      this.props.history.push({
        pathname: '/',
        state: { popupLogin: true }
      })
    }

    if (location.state && location.state.popupLogin && userInfo && !userInfo.userId) {
      NotificationManager.warning(this.context.t('validLoginFirst'), '', 6000)
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // accountAction: bindActionCreators(AccountsActions, dispatch),
    dispatch: bindActionCreators(dispatch, dispatch)
  }
}

export default withRouter(
  connect((payload) => {
    return {
      userInfo: payload.accountsReducer.userInfo,
      themeSettings: payload.apiResponse.themeSettings,
      lang: payload.i18nState.lang
    }
  }, mapDispatchToProps)(Home)
)
