
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import * as AccountsAction from '../../redux/accounts/accounts.actions'

import Agency from './Agency'

class AgencyContainer extends React.Component {
  constructor(prop) {
    super(prop)
    this.state = {}
    this.getListAgency = this.getListAgency.bind(this)
    this.lockOrUnlockAgency = this.lockOrUnlockAgency.bind(this)
    this.getCommissionConfigAgency = this.getCommissionConfigAgency.bind(this)
    this.updateConfigCommisstionAgency = this.updateConfigCommisstionAgency.bind(this)
  }

  getListAgency(month, page, limit, searchText) {
    const data = {
      month,
      page,
      limit
    }
    if (searchText !== '') {
      data.searchText = searchText
    }
    const { accountAct } = this.props
    accountAct.getAgencyList(data)
  }

  lockOrUnlockAgency(accountId, isBaned, callBack) {
    const { accountAct } = this.props
    accountAct.confirmBanedAgency({ accountId, isBaned }, callBack)
  }

  getCommissionConfigAgency(accountId, callback) {
    const { accountAct } = this.props
    accountAct.getCommissionConfigAgency({ accountId }, callback)
  }

  updateConfigCommisstionAgency(accountId, data, callback) {
    const { accountAct } = this.props
    accountAct.updateConfigCommissionAgency({ accountId, data: JSON.stringify(data) }, callback)
  }

  render() {
    const params = queryString.parse(this.props.location.search)
    const { page = 1 } = params
    return (
      <Agency
        userInfo={this.props.userInfo}
        agencyList={this.props.agencyList}
        commissionConfigAgency={this.props.commissionConfigAgency}
        getListAgency={this.getListAgency}
        lockOrUnlockAgency={this.lockOrUnlockAgency}
        getCommissionConfigAgency={this.getCommissionConfigAgency}
        updateConfigCommisstionAgency={this.updateConfigCommisstionAgency}
        page={page}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch)
    // generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    agencyList: rootState.accountsReducer.agencyList,
    commissionConfigAgency: rootState.accountsReducer.commissionConfigAgency
  }
}, mapDispatchToProps)(AgencyContainer)
