import React from 'react'
import Select from 'react-select'

import size from 'lodash/size'
import map from 'lodash/map'

const _ = { size, map }

const Search = (locals) => {
  const hasError = locals.hasError === true ? 'has-error' : ''
  const msg = locals.error ? <span className="help-block error-block">{locals.error}</span> : ''

  const arr = []
  if (_.size(locals.config.options) > 0) {
    _.map(locals.config.options, (item) => {
      arr.push({ value: `${item[locals.config.valueKey]}`, label: item[locals.config.labelKey] })
    })
  }

  return (
    <span className={`eform-item eform-select eform-select-${locals.attrs.name} ${hasError}`}>
      <Select
        className="eform-select-item e-radius"
        value={locals.value}
        options={arr}
        onChange={event => locals.onChange(event)}
        searchable={false}
        clearable={false}
        {...locals.attrs}
        // valueKey={locals.config.valueKey ? locals.config.valueKey : 'value'}
        // labelKey={locals.config.labelKey ? locals.config.labelKey : 'label'}
      />
      {msg}
      <span className="clearfix" />
    </span>
  )
}

export default Search
