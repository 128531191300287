export const PHONE_CODE = [
  {
    code: '+84',
    name: 'Việt Nam'
  },
  {
    code: '+63',
    name: 'Philippines'
  },
  {
    code: '+86',
    name: 'China'
  },
  {
    code: '+66',
    name: 'Thailand'
  },
  {
    code: '+65',
    name: 'Singapore'
  },
  {
    code: '+95',
    name: 'Myanmar'
  },
  {
    code: '+60',
    name: 'Malaysia'
  },
  {
    code: '+62',
    name: 'Indonesia'
  }
]
