import React from 'react'
import { Link } from 'react-router-dom'
import ComponentExtend from '../../ComponentExtend'

class Breadcrumb extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { arrBreadcrumb } = this.props
    return (
      <div className="col-md-12 bgWhite breadcrumb">
        <div>
          <Link to="/dashboard"><i className="fa fa-home" />{this.l('Trang chủ')}</Link>
        </div>
        {arrBreadcrumb.map((item, k) => {
          const key = k
          if (item.url) {
            return (
              <div key={key}>
                <strong>{this.l('\\')}</strong>
                <Link to={item.url}>{item.title}</Link>
              </div>
            )
          }
          return (
            <div key={key}>
              <strong>\</strong>
              <span>{item.title}</span>
            </div>
          )
        })}
        {this.props.children}
      </div>
    )
  }
}

export default Breadcrumb
