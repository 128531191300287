import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import numeral from 'numeral'
import ComponentExtend from '../../components/ComponentExtend'
import { Images } from '../../ui/assets/images'
import * as GeneralAction from '../../redux/general/general.actions'
import * as AccountsAction from '../../redux/accounts/accounts.actions'

class Header extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      showDropdownLang: false
    }
    this.onLogout = this.onLogout.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  onLogout() {
    const { accountAct } = this.props
    accountAct.postAccountLogout()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      alert('You clicked outside of me!')
    }
  }

  render() {
    const { userInfo, lang } = this.props
    return (
      <header className="header-wrapper clearfix">
        <div className="header">
          <div className="navbar navbar-default navbar-expand-lg">
            <div id="mainNavbar" style={{ width: '100%' }}>
              <div className="header-main">
                <div className="wrapper-inner row">
                  <div className="leftHeader">
                    <button className="navbar-toggler menu-link" type="button" onClick={() => { this.props.changeShowMenu() }}>
                      <i className="icon-navbar fa fa-bars" aria-hidden="true" />
                    </button>
                    <Link to="/" className="main-logo"><img src={Images.logo} alt="dailyAlo88" /></Link>
                  </div>
                  <div className="rightHead">
                    <div className="h-banlance-infog">
                      <div>{this.l('Số dư:')} {numeral(userInfo.balance || 0).format('0,0')} {this.l('VND')}</div>
                      <span>|</span>
                      {/* <div>{this.l('Doanh thu tháng:')} {numeral(userInfo.monthlyRevenue || 0).format('0,0')} {this.l('VND')}</div> */}
                    </div>
                    <Link to="/profile" className="head-info-user">
                      <i className="fa fa-user-circle" aria-hidden="true" />
                      <span>{(userInfo && userInfo.username) ? userInfo.username : null}</span>
                    </Link>
                    <div>(
                      <Link className="logout" onClick={() => this.onLogout(this)} to="/">{this.l('Thoát')}</Link>
                      )
                    </div>
                    <div className="dropdown dropdown-lang">
                      <button className="btn btn-secondary dropdown-toggle btn-lang" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src={this.l(`src_lang_${lang}`)} alt="lang" />
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" href="#" onClick={() => { this.props.onChangeLanguage('vi') }}>
                          <img src="/assets/img/flag-vi.jpg" alt="lang" style={{ width: '30px' }} />
                        </a>
                        <a className="dropdown-item" href="#" onClick={() => { this.props.onChangeLanguage('en') }}>
                          <img src="/assets/img/flag_us.png" alt="lang" style={{ width: '30px' }} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch),
    generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    i18nState: rootState.i18nState
  }
}, mapDispatchToProps)(Header)
