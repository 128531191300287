const Images = {

  /* HEADER AREA */
  logo: '/assets/img/logo.png?v=5',
  loading: '/assets/img/loading.gif?v=6',
  loading2: '/assets/img/loading.svg?v=5',
  iconLogout: '/assets/img/bnt_logout.png?v=5',

  banner1Img: '/assets/img/banner-2.jpg?v=10',
  banner2Img: '/assets/img/ALO88-dong-hanh-cung-world-cup-copy-800x229.gif?v=1',
  blockImg: '/assets/img/banner-top-2.gif?v=9',
  blockImgM: '/assets/img/banner-top-2.gif?v=9',

  block2Img: '/assets/img/block2_img.png?v=6',

  block3Icon: '/assets/img/block3_icon.png?v=5',
  block3Img: '/assets/img/block3_img.png?v=6',

  block4Icon: '/assets/img/block4_icon1.png?v=5',
  block4Icon2: '/assets/img/block4_icon2.png?v=5',
  block4Icon3: '/assets/img/block4_icon3.png?v=5',

  block5Img: '/assets/img/block5_img.png?v=6',

  block6Img: '/assets/img/block6_img.png?v=5',

  block8Icon: '/assets/img/block7_icon1.png?v=5',
  block8Icon2: '/assets/img/block7_icon2.png?v=5',
  block8Icon3: '/assets/img/block7_icon3.png?v=5',
  block8Icon4: '/assets/img/block7_icon4.png?v=5',
  block8Img: '/assets/img/block7_img.png?v=6',

  imgReg: '/assets/img/reg_img.png?v=5',
  iconRegCheck: '/assets/img/reg_icon.png?v=5'


}

export { Images }
