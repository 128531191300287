import { combineReducers } from 'redux'
import { i18nState } from 'redux-i18n'
import apiReducer from '../redux/api/api.reducers'
import generalReducer from '../redux/general/general.reducers'
import accountsReducer from '../redux/accounts/accounts.reducers'

const rootReducer = combineReducers({
  i18nState,
  apiResponse: apiReducer,
  generalReducer,
  accountsReducer
})

export default rootReducer
