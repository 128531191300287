import React from 'react'
import numeral from 'numeral'
import Modal from 'react-modal'
import moment from 'moment'
import DateRange from '../blocks/dateRange/DateRange'
import Breadcrumb from '../blocks/breadcrumb/Breadcrumb'
import ComponentExtend from '../ComponentExtend'
import Pagination from '../blocks/pagination/pagination'
import { copyText } from '../../helpers/commonHelpers'

class Withdraw extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        fromDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        page: 1
      },
      bank: '',
      province: '',
      showModal: false,
      valueForm: {}
    }
    this.closeModal = this.closeModal.bind(this)
    this.showModal = this.showModal.bind(this)
    // this.submitChangePass = this.submitChangePass.bind(this)
    this.onSubmitSearch = this.onSubmitSearch.bind(this)
    this.submitWithdraw = this.submitWithdraw.bind(this)
    this.callbackWithdraw = this.callbackWithdraw.bind(this)
  }

  componentWillMount() {
    this.props.getHistory(this.state.formData.fromDate, this.state.formData.toDate, 1, this.limit)
    this.props.getBank()
    this.props.getProvince()
    this.props.getRemeber('withdraw', 'bank')
  }

  updatePage() {
    const { formData } = this.state
    this.setState({
      formData: { ...formData, page: this.props.page }
    })
    this.props.getHistory(formData.fromDate, formData.toDate, this.props.page, this.limit)
  }

  componentDidUpdate() {
    if (this.state.formData.page !== this.props.page) {
      this.updatePage()
    }
  }

  onSubmitSearch(event) {
    event.preventDefault()
    const formData = {}
    for (let i = 0; i < event.target.elements.length; i++) {
      if (event.target[i].name) {
        formData[event.target[i].name] = event.target[event.target[i].name].value
      }
    }
    this.setState({
      formData: { ...formData, page: 1 }
    })
    this.props.getHistory(formData.fromDate, formData.toDate, this.props.page, this.limit)
  }

  submitWithdraw(event) {
    event.preventDefault()
    const formData = {}
    for (let i = 0; i < event.target.elements.length; i++) {
      if (event.target[i].name) {
        if (event.target[i].type === 'checkbox') {
          formData[event.target[i].name] = event.target[event.target[i].name].checked
        } else {
          formData[event.target[i].name] = event.target[event.target[i].name].value
        }
      }
    }
    if (!Number(formData.amount) || Number(formData.amount) < 1 || !formData.bankAccount || !formData.bank || !formData.branch || !formData.province || !formData.bankNumber) {
      alert(this.l('Data invalid'))
    } else {
      this.props.submitWithdraw(formData.bank, formData.province, formData.branch, formData.bankAccount, formData.bankNumber, formData.amount, formData.remember, this.callbackWithdraw)
    }
  }

  callbackWithdraw(status, data) {
    if (status) {
      this.setState({
        showModal: true
      })
      const { formData } = this.state
      this.props.updateBalance()
      this.props.getHistory(formData.fromDate, formData.toDate, this.props.page, this.limit)
    } else {
      alert(data.message)
    }
  }

  closeModal() {
    this.setState({
      showModal: false
    })
  }

  showModal() {
    this.setState({
      showModal: true
    })
  }

  renderModalResultWithdraw() {
    const { rsWithdraw } = this.props
    return (
      <Modal
        className="modal-afterLogin block-content-afterlogin modal-result-withdraw"
        isOpen={this.state.showModal}
        ariaHideApp={false}
      >
        <div className="modal-header-custom head-afterlogin">
          <div className="">{this.l('Yêu cầu thanh toán đã được gửi')}</div>
          <div className="btn-close" onClick={() => this.closeModal()} role="presentation"><i className="fa fa-times" /></div>
        </div>
        <hr />
        <div className="contain-block-afterlogin">
          <center>
            <img src="/assets/img/img-result-withdraw.png" alt={this.l('withdraw')} />
            <p>{numeral(rsWithdraw.amount || 0).format('0,0')} {this.l('VND')}</p>
          </center>
          <form>
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label">{this.l('Ngân hàng')}</label>
              <div className="col-sm-1 col-form-label">:</div>
              <div className="col-sm-7">
                <input type="text" className="form-control-plaintext text-right" defaultValue={rsWithdraw.bank} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label">{this.l('Tên tài khoản')}</label>
              <div className="col-sm-1 col-form-label">:</div>
              <div className="col-sm-7">
                <input type="text" className="form-control-plaintext text-right" id="staticEmail" defaultValue={rsWithdraw.bankAccount} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label">{this.l('Số dư thời điểm')}</label>
              <div className="col-sm-1 col-form-label">:</div>
              <div className="col-sm-7">
                <input type="text" className="form-control-plaintext text-right sodu" defaultValue={`${numeral(rsWithdraw.balance || 0).format('0,0')} ${this.l('VND')}`} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="staticEmail" className="col-sm-4 col-form-label">{this.l('Mã giao dịch')}</label>
              <div className="col-sm-1 col-form-label">:</div>
              <div className="col-sm-7">
                <input type="text" className="form-control-plaintext text-right" id="staticEmail" defaultValue={rsWithdraw.transactionId || ''} />
              </div>
            </div>
          </form>
          <div className="node-result-withdraw text-center">{this.l('text-node-result-witdraw')}</div>
        </div>
        <hr />
        <div className="form-group row">
          <center className="col-sm-12">
            <button type="button" className="btn btn-primary" onClick={() => { copyText(rsWithdraw.transactionId || '', this.l('Đã sao chép thành công')) }}>{this.l('Sao chép mã giao dịch')}</button>
            <button type="button" className="btn btn-default" onClick={() => this.closeModal()}>{this.l('Đóng')}</button>
          </center>
        </div>
      </Modal>
    )
  }

  onChangeBank = (e) => {
    this.setState({
      bank: e.target.value
    })
  }


  onChangeProvince = (e) => {
    this.setState({
      province: e.target.value
    })
  }

  formatStatus = (status) => {
    switch (status) {
      case 0:
        return this.l('Đang chờ')
      case -1:
        return this.l('Bị từ chối')
      case 1:
        return this.l('Success')
      default:
        return this.l('Đang chờ')
    }
  }

  render() {
    const {
      userInfo = {},
      listWithdrawHistory = [],
      page = 1,
      listBank = [],
      remember = {},
      listProvince = []
    } = this.props
    const bankDefault = remember.bank || ''
    const provinceDefault = remember.province || ''
    const { totalRecord = 0, data = [] } = listWithdrawHistory
    const maxDate = moment().format('YYYY-MM-DD')
    return (
      <div className="cls-aflg-withdraw  row">
        <Breadcrumb arrBreadcrumb={[{ title: this.l('Rút tiền') }]} />
        <div className="row">
          <div className="col-md-12 col-lg-5 nopadding">
            <div className="block-content-afterlogin cls-withdraw-balance">
              <div className="row">
                <img src="/assets/img/img-profit.png" alt="{this.l('profit')}" />
                <div>
                  <span>{this.l('Số dư khả dụng')}</span>
                  <p>{numeral(userInfo.balance || 0).format('0,0')} {this.l('VND')}</p>
                </div>
              </div>
            </div>
            <div className="block-content-afterlogin">
              <div className="head-afterlogin">{this.l('Yểu cầu rút tiền')}</div>
              <hr />
              <div className="contain-block-afterlogin">
                <form id="idFormWithdraw" onSubmit={this.submitWithdraw}>
                  <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label nopadding">{this.l('Tên tài khoản')}</label>
                    <div className="col-sm-8 nopadding">
                      <input type="text" className="form-control" placeholder={this.l('Tên tài khoản')} name="bankAccount" defaultValue={remember.bankAccount || ''} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label nopadding">{this.l('Số tài khoản')}</label>
                    <div className="col-sm-8 nopadding">
                      <input type="text" className="form-control" placeholder={this.l('Số tài khoản')} name="bankNumber" defaultValue={remember.bankNumber || ''} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label nopadding">{this.l('Ngân hàng nhận')}</label>
                    <div className="col-sm-8 nopadding">
                      <select id="inputState" className="form-control" name="bank" onChange={this.onChangeBank} value={this.state.bank || bankDefault}>
                        <option value="">{this.l('select bank')}</option>
                        {listBank.map((item, k) => {
                          const key = k
                          return (
                            <option value={item.bankCode} key={key}>{item.bankName}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label nopadding">{this.l('Tỉnh/Thành phố')}</label>
                    <div className="col-sm-8 nopadding">
                      <select id="inputState" className="form-control" name="province" onChange={this.onChangeProvince} value={this.state.province || provinceDefault}>
                        <option value="">{this.l('select province')}</option>
                        {listProvince.map((item, k) => {
                          const key = k
                          return (
                            <option value={item.slug} key={key}>{item.name}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label nopadding">{this.l('Chi nhánh')}</label>
                    <div className="col-sm-8 nopadding">
                      <input type="text" className="form-control" placeholder={this.l('Chi nhánh')} name="branch" defaultValue={remember.branch || ''} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-4 col-form-label nopadding">{this.l('Số tiền')}</label>
                    <div className="col-sm-8 nopadding">
                      <input type="text" className="form-control" placeholder={this.l('Số tiền')} name="amount" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="form-check offset-sm-4 col-sm-8">
                      <input className="form-check-input" type="checkbox" id="gridCheck" name="remember" />
                      <label className="form-check-label" htmlFor="gridCheck">
                        {this.l('Ghi nhớ cho lần tiếp theo')}
                      </label>
                    </div>
                  </div>

                  <hr />
                  <div className="form-group row">
                    <center className="col-sm-12">
                      <button className="btn btn-primary" type="submit">{this.l('Gửi yêu cầu')}</button>
                    </center>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-7 contain-history-withdraw">
            <div className="block-content-afterlogin history-withdraw">
              <div className="head-afterlogin">
                <span>{this.l('Sao kê tài khoản')}</span>
                <form className="header-afterlogin-form" onSubmit={this.onSubmitSearch}>
                  <DateRange
                    fromDate={this.state.formData.fromDate}
                    toDate={this.state.formData.toDate}
                    maxDate={maxDate}
                  />
                  <button type="submit" className="btn btn-primary-cs">{this.l('Xem')}</button>
                </form>
              </div>
              <div className="contain-block-afterlogin">
                <div className="table-reponsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{this.l('Số')}</th>
                        <th scope="col">{this.l('Ngày giao dịch')}</th>
                        <th scope="col">{this.l('Mã giao dịch')}</th>
                        <th scope="col">{this.l('Hoạt động')}</th>
                        <th scope="col">{this.l('Số dư trước')}</th>
                        <th scope="col">{this.l('Trạng thái')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(data.length > 0) ? (
                        data.map((item, k) => {
                          const key = k
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{item.date}</td>
                              <td>{item.code}</td>
                              <td>{item.activitive}</td>
                              <td>{numeral(item.beforeBalance).format('0,0')} {this.l('VND')}</td>
                              <td>{this.formatStatus(item.status)}</td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" className="empty-record text-center">{this.l('Empty record')}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <center>
                  <Pagination
                    urlPage="/withdraw"
                    total_row={totalRecord}
                    page={page}
                    limit={this.limit}
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
        {this.renderModalResultWithdraw()}
      </div>
    )
  }
}

export default Withdraw
