import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

export default class ComponentExtend extends React.Component {
  constructor(prop) {
    super(prop)
    this.limit = 15
  }

  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  l(text, text1, text2, text3) {
    return this.context.t(text, text1, text2, text3)
  }

  getFromDate() {
    return moment().subtract(30, 'd').format('YYYY-MM-DD')
  }

  getToDate() {
    return moment().format('YYYY-MM-DD')
  }
}
