export const POST_REGISTER_CLIENT = 'POST_REGISTER_CLIENT'
export const POST_REGISTER_CLIENT_SUCCESS = 'POST_REGISTER_CLIENT_SUCCESS'
export const POST_REGISTER_CLIENT_FAIL = 'POST_REGISTER_CLIENT_FAIL'

export const POST_CAPTCHA = 'POST_CAPTCHA'
export const POST_CAPTCHA_SUCCESS = 'POST_CAPTCHA_SUCCESS'
export const POST_CAPTCHA_FAIL = 'POST_CAPTCHA_FAIL'
export const CHANGE_CLASS_SIDEBAR = 'CHANGE_CLASS_SIDEBAR'

export const CHANGE_LANG = 'CHANGE_LANG'

export const FAQ_LIST = 'FAQ_LIST'
export const FAQ_LIST_SUCCESS = 'FAQ_LIST_SUCCESS'
export const FAQ_LIST_FAIL = 'FAQ_LIST_FAIL'

export const PROMOTION_LIST = 'PROMOTION_LIST'
export const PROMOTION_LIST_SUCCESS = 'PROMOTION_LIST_SUCCESS'
export const PROMOTION_LIST_FAIL = 'PROMOTION_LIST_FAIL'

export const PROMOTION_HOT_LIST = 'PROMOTION_HOT_LIST'
export const PROMOTION_HOT_LIST_SUCCESS = 'PROMOTION_HOT_LIST_SUCCESS'
export const PROMOTION_HOT_LIST_FAIL = 'PROMOTION_HOT_LIST_FAIL'


export const PROMOTION_DETAIL = 'PROMOTION_DETAIL'
export const PROMOTION_DETAIL_SUCCESS = 'PROMOTION_DETAIL_SUCCESS'
export const PROMOTION_DETAIL_FAIL = 'PROMOTION_DETAIL_FAIL'

export const GET_PAGE = 'GET_PAGE'
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS'
export const GET_PAGE_FAIL = 'GET_PAGE_FAIL'

export const BANNER_LIST = 'BANNER_LIST'
export const BANNER_LIST_SUCCESS = 'BANNER_LIST_SUCCESS'
export const BANNER_LIST_FAIL = 'BANNER_LIST_FAIL'

export const BANNER_CATEGORIES = 'BANNER_CATEGORIES'
export const BANNER_CATEGORIES_SUCCESS = 'BANNER_CATEGORIES_SUCCESS'
export const BANNER_CATEGORIES_FAIL = 'BANNER_CATEGORIES_FAIL'

export const BANNERS = 'BANNERS'
export const BANNERS_SUCCESS = 'BANNERS_SUCCESS'
export const BANNERS_FAIL = 'BANNERS_FAIL'
