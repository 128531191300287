import React from 'react'
import numeral from 'numeral'
import ComponentExtend from '../ComponentExtend'
import * as responseCode from '../../configs/responseCode.config'
import { generalAPI } from '../../api'

class CommissionEstimate extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      profit: 0
    }

    this.onChange = this.onChange.bind(this)
    this.onEstimate = this.onEstimate.bind(this)
  }

  onChange(value, path) {
    const valStr = numeral(Number(value.replace(/,/g, ''))).format('0,0')
    if (path === 'money') {
      this.setState({ frmMoney: valStr })
    } else if (path === 'turnOver') {
      this.setState({ frmTurnOver: valStr })
    } else {
      this.setState({ frmUser: valStr })
    }
  }

  onEstimate(e) {
    e.preventDefault()
    // eslint-disable-next-line
    // let { frmUser = 0, frmMoney = 0 } = this.state
    // frmUser = Number(frmUser.replace(/,/g, ''))
    // let netProfit = Number(frmMoney.replace(/,/g, ''))
    // const arrConfigProfit = (this.props.commissionConfig && this.props.commissionConfig.config) ? this.props.commissionConfig.config : []
    // let profit = 0
    // arrConfigProfit.forEach((item) => {
    //   if (frmUser >= item.numMember) {
    //     if (netProfit > 0) {
    //       if (item.maxProfit === null) {
    //         profit += netProfit * item.commission
    //         netProfit = 0
    //       } else {
    //         if (netProfit >= item.maxProfit) {
    //           profit += item.maxProfit * item.commission
    //         } else {
    //           profit += netProfit * item.commission
    //         }
    //         netProfit -= item.maxProfit
    //       }
    //     }
    //   }
    // })
    // const { frmUser = 0, frmMoney = 0 } = this.state
    // let total = Number(frmMoney.toString().replace(/,/g, ''))
    // const numActive = Number(frmUser.toString().replace(/,/g, ''))
    // const config = (this.props.commissionConfig && this.props.commissionConfig.config) ? this.props.commissionConfig.config : []
    // let revenue = 0
    // if (config) {
    //   for (let i = (config.length - 1); i >= 0; i--) {
    //     const itemConfig = config[i]
    //     if (total > itemConfig.minProfit
    //       && numActive >= itemConfig.numMember
    //       && itemConfig.maxProfit === null) {
    //       console.log(total, itemConfig.minProfit, (total - itemConfig.minProfit), (total - itemConfig.minProfit) * itemConfig.commission)
    //       revenue += (total - itemConfig.minProfit) * itemConfig.commission
    //       total = itemConfig.minProfit
    //     } else if (total >= itemConfig.minProfit && numActive >= itemConfig.numMember) {
    //       console.log(total, itemConfig.minProfit, (total - itemConfig.minProfit), (total - itemConfig.minProfit) * itemConfig.commission)
    //       revenue += (total - itemConfig.minProfit) * itemConfig.commission
    //       total = itemConfig.minProfit
    //     }
    //   }
    // }
    const turnOver = this.state.frmTurnOver.replace(/,/g, '')
    const winLoss = this.state.frmMoney.replace(/,/g, '')
    console.log(turnOver, winLoss)
    const args = {
      turnOver: parseFloat(turnOver),
      winLoss: parseFloat(winLoss),
      activeUser: parseFloat(this.state.frmUser)
    }
    console.log(args)
    generalAPI.getCommissionEstimate(args)
      .then((res) => {
        console.log('Estimate', res)
        if (res.code === responseCode.REQUEST_SUCCESS) {
          const revenue = res.data.revenueUser || 0
          this.setState({
            profit: numeral(revenue).format('0,0')
          })
        } else {
          this.setState({
            profit: numeral(0).format('0,0')
          })
        }
      })
  }

  render() {
    return (
      <div className="component-estimate clearfix">
        <form onSubmit={this.onEstimate}>
          <div className="frm-box row">
            <div className="item item-money col-12 col-md-6 col-xl-4 nopadding">
              <div className="item-input">
                <p className="item-title">{this.l('Tổng tiền cược')}</p>
                <p className="item-desc">
                  <input type="text" name="turnOver" value={this.state.frmTurnOver || ''} onChange={e => this.onChange(e.target.value, 'turnOver')} />
                  <span className="currency">VNĐ</span>
                </p>
              </div>
            </div>
            <div className="item item-money col-12 col-md-6 col-xl-4 nopadding">
              <div className="item-input">
                <p className="item-title">{this.l('netProfit')}</p>
                <p className="item-desc">
                  <input type="text" name="money" value={this.state.frmMoney || ''} onChange={e => this.onChange(e.target.value, 'money')} />
                  <span className="currency">VNĐ</span>
                </p>
              </div>
            </div>
            <div className="item item-user col-12 col-md-6 col-xl-3 nopadding">
              <div className="item-input">
                <p className="item-title">{this.l('memberNumber')}</p>
                <p className="item-desc">
                  <input type="text" name="user" value={this.state.frmUser || ''} onChange={e => this.onChange(e.target.value, 'user')} />
                </p>
              </div>
            </div>
            <div className="item item-btn col-6 col-md-6 col-xl-2 nopadding">
              <p className="item-title">&nbsp;</p>
              <p className="item-desc">
                <button type="submit" className="btn btn-submit">{this.l('count')}</button>
              </p>
            </div>
            <div className="item item-total col-6 col-md-6 col-xl-3 nopadding">
              <p className="item-title">{this.l('estimateCom')}</p>
              <p className="item-desc">{this.l(this.state.profit)} VNĐ</p>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default CommissionEstimate
