import React from 'react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'

export default function inputDatePicker(locals) {
  const hasError = locals.hasError === true ? 'has-error' : ''
  const msg = locals.error ? <span className="help-block error-block">{locals.error}</span> : ''
  const { config: { limit } } = locals

  let birthday = moment()
  if (locals.value !== '') birthday = moment(locals.value, 'YYYY-MM-DDTHH:mm:sssZ')
  else if (limit && limit.end) birthday = moment(new Date()).subtract(limit.end, 'day').startOf('day')

  let minTime = moment(new Date()).subtract(100, 'day').startOf('day')
  let maxTime = moment(new Date())
  if (limit && limit.start) { minTime = moment(new Date()).subtract(limit.start, 'day').startOf('day') }
  if (limit && limit.end) { maxTime = moment(new Date()).subtract(limit.end, 'day').startOf('day') }


  return (
    <span className={`eform-input eform-input-${locals.attrs.name} ${hasError}`}>
      <DateRangePicker
        minDate={minTime.format('MM/DD/YYYY')}
        maxDate={maxTime.format('MM/DD/YYYY')}
        singleDatePicker
        // showDropdowns
        // locale={localeOptions}
        onApply={(event, picker) => {
          locals.onChange(moment(picker.startDate).toISOString())
        }}

      >
        {
          locals.value !== '' ? (
            <div className="eform-input-item form-control placeholder-no-fix e-radius">
              {birthday.format('DD/MM/YYYY')}
            </div>
            // <input className="eform-input-item form-control placeholder-no-fix e-shadow e-radius" value={birthday.format('DD/MM/YYYY')} />
          ) : (
            <div className="eform-input-item form-control placeholder-no-fix e-radius text-placeholder">
              {locals.attrs && locals.attrs.placeholder ? locals.attrs.placeholder : 'DD/MM/YYYY'}
            </div>
          )
        }
      </DateRangePicker>
      {msg}
      <span className="clearfix" />
    </span>
  )
}
