import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'

// import reverse from 'lodash/reverse'

import * as GeneralAction from '../../../redux/general/general.actions'
import * as AccountsAction from '../../../redux/accounts/accounts.actions'

// import { Images } from '../../../ui/assets/images'

import Register from './Register'


class RegisterContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(prop) {
    super(prop)
    let accountId = ''
    if (this.props.computedMatch && this.props.computedMatch.params) {
      const { refcode = '' } = this.props.computedMatch.params
      accountId = refcode
    } else {
      const params = queryString.parse(this.props.location.search)
      accountId = params.refcode || ''
    }
    this.state = {
      refcode: accountId
    }

    this.onSubmit = this.onSubmit.bind(this)

    this.redirectSuccess = this.redirectSuccess.bind(this)
  }

  componentWillMount() {
    if (this.state.refcode !== '') {
      const { AccountsAct } = this.props
      AccountsAct.updateRefcodeAgency({ refcode: this.state.refcode })
    }
  }

  onSubmit(params, thisCallback) {
    const { AccountsAct } = this.props
    // if (this.props.refcodeAgency !== '') {
    //   params.refCode = this.props.refcodeAgency
    // }
    AccountsAct.postAccountRegister(params, thisCallback)
  }

  redirectSuccess() {
    this.props.history.push('/register/success')
  }

  render() {
    return (
      <Register
        translate={this.context.t}
        onSubmit={this.onSubmit}
        refcode={this.state.refcode}
        redirectSuccess={this.redirectSuccess}
      />
    )
  }

  componentDidMount() {
    const { userInfo } = this.props
    if (userInfo && userInfo.accountId) {
      this.props.history.push('/')
    }
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralAction, dispatch),
    AccountsAct: bindActionCreators(AccountsAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    captcha: rootState.generalReducer.captcha,
    lang: rootState.i18nState.lang,
    refcodeAgency: rootState.accountsReducer.refcodeAgency
  }
}, mapsDispatchToProp)(RegisterContainer)
