import React from 'react'

const LoginMini = (locals) => {
  const hasError = locals.hasError === true ? 'has-error' : ''
  const msg = locals.error ? <span className="help-block error-block">{locals.error}</span> : ''

  return (
    <span className={`eform-input eform-input-${locals.attrs.name} ${hasError}`}>
      <input
        className="eform-input-item form-control placeholder-no-fix e-radius"
        autoComplete="off"
        value={locals.value}
        onChange={event => locals.onChange(event.target.value)}
        {...locals.attrs}
      />
      {msg}
      <span className="clearfix" />
    </span>
  )
}

export default LoginMini
