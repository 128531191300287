import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import reverse from 'lodash/reverse'

import * as GeneralAction from '../../../redux/general/general.actions'
import * as AccountsAction from '../../../redux/accounts/accounts.actions'


import NewPass from './NewPass'


class NewPassContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(prop) {
    super(prop)

    this.onSubmit = this.onSubmit.bind(this)

    this.redirectSuccess = this.redirectSuccess.bind(this)
  }


  onSubmit(params, thisCallback) {
    const { AccountsAct } = this.props

    AccountsAct.postAccountNewPassword(params, thisCallback)
  }

  redirectSuccess() {
    this.props.history.push('/new-pass/success')
  }

  render() {
    const { code } = this.props.computedMatch.params
    return (
      <NewPass
        translate={this.context.t}
        onSubmit={this.onSubmit}
        code={code}
        redirectSuccess={this.redirectSuccess}
      />
    )
  }

  componentDidMount() {
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralAction, dispatch),
    AccountsAct: bindActionCreators(AccountsAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    captcha: rootState.generalReducer.captcha,
    lang: rootState.i18nState.lang
  }
}, mapsDispatchToProp)(NewPassContainer)
