import React from 'react'
import numeral from 'numeral'
import { Link } from 'react-router-dom'

import ComponentExtend from '../../../components/ComponentExtend'

class SidebarLeft extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      positionScroll: 0
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    if (window.pageYOffset > 67 && this.state.positionScroll < 67) {
      this.setState({
        positionScroll: 70
      })
    }
    if (window.pageYOffset < 67 && this.state.positionScroll > 67) {
      this.setState({
        positionScroll: 0
      })
    }
  }

  render() {
    if (this.props.userInfo.typeAgency === 1) {
      return (
        <div className={`sidebar-left sidebar-afterLogin clearfix position-fixed ${this.props.classSidebar} ${(this.state.positionScroll > 67) ? 'activeScroll' : ''}`}>
          <div className="cls-info-user-sb">
            <center className="cls-avatar">
              <img src="/assets/img/avatart.png" alt="Avatar agency" />
            </center>
            <div className="text-center">{this.l('Xin chao')}, {this.props.userInfo.email}</div>
            <div className="text-center">Mã đại lý: {this.props.userInfo.accountId}</div>
            <div className="user-balance-sb">
              <div>{this.l('Số dư:')} {numeral(this.props.userInfo.balance || 0).format('0,0')} {this.l('VND')}</div>
              <div>{this.l('Doanh thu tháng:')} {numeral(this.props.userInfo.monthlyRevenue || 0).format('0,0')} {this.l('VND')}</div>
            </div>
          </div>
          <div className="cls-info-menu-sb">
            <ul>
              <li>
                <Link to="/dashboard" className={(this.props.active === '/dashboard') ? 'active' : ''}>
                  <i className="icon-control" />
                  <span>{this.l('Bảng điều khiển')}</span>
                  <i className="fa fa-chevron-right icon-right" aria-hidden="true" />
                </Link>
              </li>
              <li>
                <Link to="/members" className={(this.props.active === '/members') ? 'active' : ''}>
                  <i className="icon-group" />
                  <span>{this.l('Danh sách thành viên')}</span>
                  <i className="fa fa-chevron-right icon-right" aria-hidden="true" />
                </Link>
              </li>
              <li>
                <Link to="/agency" className={(this.props.active === '/agency') ? 'active' : ''}>
                  <i className="icon-agency-2" />
                  <span>{this.l('Danh sách đại lý cấp 2')}</span>
                  <i className="fa fa-chevron-right icon-right" aria-hidden="true" />
                </Link>
              </li>
              <li>
                <Link to="/statistic" className={(this.props.active === '/statistic') ? 'active' : ''}>
                  <i className="icon-statistics" />
                  <span>{this.l('Thống kê')}</span>
                  <i className="fa fa-chevron-right icon-right" aria-hidden="true" />
                </Link>
              </li>
              <li>
                <Link to="/withdraw" className={(this.props.active === '/withdraw') ? 'active' : ''}>
                  <i className="icon-withdraw" />
                  <span>{this.l('Rút tiền')}</span>
                  <i className="fa fa-chevron-right icon-right" aria-hidden="true" />
                </Link>
              </li>
              <li>
                <Link to="/banners" className={(this.props.active === '/banners') ? 'active' : ''}>
                  <i className="icon-withdraw" />
                  <span>{this.l('Banner')}</span>
                  <i className="fa fa-chevron-right icon-right" aria-hidden="true" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )
    }
    return (
      <div className={`sidebar-left sidebar-afterLogin clearfix position-fixed ${this.props.classSidebar} ${(this.state.positionScroll > 67) ? 'activeScroll' : ''}`}>
        <div className="cls-info-user-sb">
          <center className="cls-avatar">
            <img src="/assets/img/avatart.png" alt="Avatar agency" />
          </center>
          <div className="text-center">{this.l('Xin chao')}, {this.props.userInfo.email}</div>
          <div className="text-center">Mã đại lý: {this.props.userInfo.accountId}</div>
          <div className="user-balance-sb">
            <div>{this.l('Số dư:')} {numeral(this.props.userInfo.balance || 0).format('0,0')} {this.l('VND')}</div>
            <div>{this.l('Doanh thu tháng:')} {numeral(this.props.userInfo.monthlyRevenue || 0).format('0,0')} {this.l('VND')}</div>
          </div>
        </div>
        <div className="cls-info-menu-sb">
          <ul>
            <li>
              <Link to="/dashboard">
                <i className="icon-control" />
                <span>{this.l('Bảng điều khiển')}</span>
                <i className="fa fa-chevron-right icon-right" aria-hidden="true" />
              </Link>
            </li>
            <li>
              <Link to="/statistic">
                <i className="icon-statistics" />
                <span>{this.l('Thống kê')}</span>
                <i className="fa fa-chevron-right icon-right" aria-hidden="true" />
              </Link>
            </li>
            <li>
              <Link to="/withdraw">
                <i className="icon-withdraw" />
                <span>{this.l('Rút tiền')}</span>
                <i className="fa fa-chevron-right icon-right" aria-hidden="true" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default SidebarLeft
