
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'
import * as GeneralAction from '../../redux/general/general.actions'

import Details from './Details'

class DetailsContainer extends React.Component {
  constructor(prop) {
    super(prop)
    this.state = {
      id: ''
    }
  }

  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  componentWillMount() {
    const params = queryString.parse(this.props.location.search)
    const { id = '' } = params
    const { generalAct } = this.props
    generalAct.promotionDetail({ id })
    generalAct.promotionList({
      limit: 3,
      page: 1
    }, false)
    this.updatePage = this.updatePage.bind(this)
  }

  updatePage() {
    const params = queryString.parse(this.props.location.search)
    const { id = '' } = params
    if (id !== this.state.id) {
      this.setState({
        id
      })
      const { generalAct } = this.props
      generalAct.promotionDetail({ id })
      generalAct.promotionList({
        limit: 3,
        page: 1
      }, false)
    }
  }

  componentDidUpdate() {
    this.updatePage()
  }

  render() {
    return (
      <Details
        translate={this.context.t}
        promotionDetail={this.props.promotionDetail}
        listPromotion={this.props.listPromotion}
      />
    )
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    promotionDetail: rootState.generalReducer.promotionDetail,
    listPromotion: rootState.generalReducer.listPromotion
  }
}, mapsDispatchToProp)(DetailsContainer)
