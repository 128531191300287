import React from 'react'

class NotFoundContainer extends React.Component {
  render() {
    return (
      <div style={{ color: '#fff', fontSize: '2em', marginTop: '50px' }} className="text-center">404 Page - File Not Found</div>
    )
  }
}

export default NotFoundContainer
