
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as AccountsAction from '../../redux/accounts/accounts.actions'
import * as GeneralAction from '../../redux/general/general.actions'

import Commission from './Commission'

class CommissionContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(prop) {
    super(prop)
    this.state = {}
  }

  componentWillMount() {
    const { generalAct } = this.props
    generalAct.getPage({ alias_unique: 'chinh-sach-hoa-hong' })
  }

  render() {
    return (
      <Commission
        commissionConfig={this.props.commissionConfig}
        page={this.props.page}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch),
    generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    commissionConfig: rootState.accountsReducer.commissionConfig,
    page: rootState.generalReducer.page
  }
}, mapDispatchToProps)(CommissionContainer)
