// import { Link } from 'react-router-dom'
import React from 'react'

class SidebarLeft extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    /* eslint-disable no-underscore-dangle */
    // const { bannerList = [] } = this.props;
    return (
      <div className="sidebar-left clearfix">
        {
          // bannerList.map((item, k) => {
          //   const key = k
          //   return (
          //     <div className="block-1" key={key}>
          //       <Link to={item.url} className="block-banner">
          //         <img style={{ maxWidth: '100%' }} src={item.bannerSmall} alt={item.title} />
          //       </Link>
          //     </div>
          //   )
          // })
        }
        {/* <div className="block-3 block-hot">
          <div className="block-title text-uppercase">Khuyễn mãi hot</div>
          <div className="block-desc">
            {listHotPromotion.list.map((item, k) => {
              const key = k
              return (
                <div className="items row" key={key}>
                  <div className="item-icon col-1 nopadding"><i className="fa fa-square" aria-hidden="true" /></div>
                  <Link style={{ color: '#03042a' }} className="item-info col-11 nopadding" to={`/news/details?id=${item._id}`}>{item.title}</Link>
                </div>
              )
            })}
          </div>
        </div> */}
      </div>
    )
  }
}

export default SidebarLeft
