import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Pagination from '../blocks/pagination/pagination'
import SidebarLeft from '../sidebar/SidebarLeft.container'
import ComponentExtend from '../ComponentExtend'

class List extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    /* eslint-disable no-underscore-dangle */
    const { listPromotion, page = 1 } = this.props
    return (
      <div className="news-page clearfix">
        <div className="wrapper-inner">
          <div className="row">
            <div className="info-left col-12 order-2 col-md-4 order-md-1 col-lg-3 nopadding">
              <SidebarLeft />
            </div>

            <div className="info-content col-12 order-1 col-md-8 order-md-2 col-lg-9 nopadding">
              <div className="white-box">
                <div className="box-title">Tin Tức</div>
                <div className="box-content">
                  {listPromotion.list.map((item, k) => {
                    const key = k
                    return (
                      <div className="items row" key={key}>
                        <div className="item-img col-12 col-sm-4 col-md-3 nopadding"><Link to={`/news/details?id=${item._id}`}><img src={item.images} alt="" /></Link></div>
                        <div className="item-info col-12 col-sm-8 col-md-9 nopadding">
                          <div className="item-title"><Link to={`/news/details?id=${item._id}`}>{item.title}</Link></div>
                          <div className="item-date">{moment(item.createdAt).format('DD/MM/YYYY')}</div>
                          <div className="item-desc">{item.summary}</div>
                          <div className="item-link">
                            <Link className="btn" to={`/news/details?id=${item._id}`}><span className="text-uppercase">{this.l('Details')}</span></Link>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div className="box-pagination justify-content-center d-flex">
                  <Pagination
                    urlPage="/news/event.html"
                    total_row={listPromotion.total}
                    page={page}
                    limit={this.limit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default List
