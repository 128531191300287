import { call, put, takeLatest } from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import { types } from '../../redux/accounts'
import { types as apiTypes } from '../../redux/api'
import { accountsAPI } from '../../api'

function* doAction(action) {
  try {
    yield put({ type: apiTypes.SHOW_LOADING })
    const { code, data } = yield call(accountsAPI.getRemember, action.payload)
    yield put({ type: apiTypes.HIDE_LOADING })
    switch (code) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.GET_REMEMBER_SUCCESS, payload: data })
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield put({ type: types.GET_REMEMBER_FAIL })
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield put({ type: apiTypes.HIDE_LOADING })
    yield put({ type: types.GET_REMEMBER_FAIL })
  }
}

export default function* watchAction() {
  yield takeLatest(types.GET_REMEMBER, doAction)
}
