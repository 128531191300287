const copyText = (str, textSuccess) => {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  const alertCopy = document.createElement('div')
  alertCopy.className = 'alert alert-success alert-copy'
  alertCopy.innerText = textSuccess
  document.body.appendChild(alertCopy)
  setTimeout(() => {
    document.body.removeChild(alertCopy)
  }, 2000)
}

const copyInputClass = (classObject) => {
  const address = document.getElementsByClassName(classObject)[0]
  address.focus()
  address.select()
  document.execCommand('copy')
  alert('Đã sao chép')
}
export {
  copyText,
  copyInputClass
}
