import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
import Breadcrumb from '../blocks/breadcrumb/Breadcrumb'
import Pagination from '../blocks/pagination/pagination'
import ComponentExtend from '../ComponentExtend'
// import DateRange from '../blocks/dateRange/DateRange'
import MonthFilter from '../blocks/MonthFilter'

class Profile extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        month: moment().format('MM/YYYY'),
        searchUser: '',
        page: 1
      }
    }
    this.onSubmitSearchMember = this.onSubmitSearchMember.bind(this)
    this.updatePage = this.updatePage.bind(this)
  }

  componentWillMount() {
    this.props.getListUser(this.state.formData.month, 1, this.limit)
  }

  onSubmitSearchMember(event) {
    event.preventDefault()
    const formData = {}
    for (let i = 0; i < event.target.elements.length; i++) {
      if (event.target[i].name) {
        formData[event.target[i].name] = event.target[event.target[i].name].value
      }
    }
    this.setState({
      formData: { ...formData, page: 1 }
    })
    console.log(formData)
    this.props.getListUser(formData.month, 1, this.limit, formData.searchUser)
  }

  updatePage() {
    const { formData } = this.state
    this.setState({
      formData: { ...formData, page: this.props.page }
    })
    this.props.getListUser(formData.month, this.props.page, this.limit, this.state.searchUser)
  }

  componentDidUpdate() {
    if (this.state.formData.page !== this.props.page) {
      this.updatePage()
    }
  }

  onChangeMonth = (value) => {
    const formData = { ...this.state.formData }
    this.setState({
      formData: {
        ...formData,
        month: value
      }
    })
  }


  render() {
    const { userListByAgency = {}, page = 1 } = this.props
    const { data = [], totalRecord = 0, totalInfo = {} } = userListByAgency
    // const maxDate = moment().format('YYYY-MM-DD')
    return (
      <div className="cls-aflg-profile row">
        <Breadcrumb arrBreadcrumb={[{ title: this.l('Danh sách thành viên') }]} />
        <div className="row">
          <div className="col-md-12 nopadding">
            <div className="cls-block-info-commisstion block-content-afterlogin cls-aflg-members">
              <div className="head-afterlogin">
                <span>{this.l('Danh sách thành viên')}</span>
                <form className="header-afterlogin-form" onSubmit={this.onSubmitSearchMember}>
                  <input type="text" name="searchUser" placeholder={this.l('User ID, Email')} />
                  <MonthFilter onChange={this.onChangeMonth} />
                  <button type="submit" className="btn btn-primary-cs">{this.l('Xem')}</button>
                </form>
              </div>

              <div className="contain-block-afterlogin row" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <div className="cls-report-income col-md-4">
                  <div className="surplus-before-month">
                    <span>{this.l('Thành viên hoạt động')}</span>
                    <p>{numeral(totalInfo.numMemberActive || 0).format('0,0')}</p>
                  </div>
                </div>
                <div className="cls-report-income col-md-4">
                  <div className="surplus-form-members">
                    <span>{this.l('Tỉ lệ hoa hồng')}</span>
                    <p>{numeral((totalInfo.commission || 0) * 100).format('0,0')} <span>%</span></p>
                  </div>
                </div>
                <div className="cls-report-income col-md-4">
                  <div className="surplus-form-agency">
                    <span>{this.l('Tổng doanh thu')}</span>
                    <p>{numeral(totalInfo.revenue || 0).format('0,0')} <span>{this.l('VND')}</span></p>
                  </div>
                </div>
              </div>
              <div className="contain-block-afterlogin">
                <div className="table-reponsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{this.l('Họ và tên')}</th>
                        <th scope="col">{this.l('Số điện thoại')}</th>
                        <th scope="col">{this.l('Email')}</th>
                        <th scope="col">{this.l('Tổng tiền cược')}</th>
                        <th scope="col">{this.l('netProfit')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(data.length > 0) ? (
                        data.map((item, k) => {
                          const key = k
                          return (
                            <tr key={key}>
                              <td><span>{item.name}</span></td>
                              <td><span>{item.phone}</span></td>
                              <td><span>{item.email}</span></td>
                              <td>{numeral(item.turnOver).format('0,0')} {this.l('VND')}</td>
                              <td>{numeral(item.winLoss).format('0,0')} {this.l('VND')}</td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan="7" className="empty-record">{this.l('Empty record')}</td>
                        </tr>
                      )}
                      <tr className="last_row_table">
                        <td colSpan="2">{this.l('Tổng cộng')}</td>
                        <td>{numeral(totalInfo.numMemberActive || 0).format('0,0')} {this.l('Thành viên hoạt động')}</td>
                        <td>{numeral(totalInfo.totalTurnOver || 0).format('0,0')} {this.l('VND')}</td>
                        <td>{numeral(totalInfo.totalWinLoss || 0).format('0,0')} {this.l('VND')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <center>
                  <Pagination
                    urlPage="/members"
                    total_row={totalRecord}
                    page={Number(page)}
                    limit={this.limit}
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Profile
