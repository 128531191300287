
import React from 'react'
import PropTypes from 'prop-types'

import SidebarLeft from './SidebarLeft'

class SidebarLeftContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(prop) {
    super(prop)
    this.state = {}
  }

  render() {
    return (
      <SidebarLeft
        active={this.props.active}
        classSidebar={this.props.classSidebar}
        userInfo={this.props.userInfo}
      />
    )
  }
}

export default SidebarLeftContainer
