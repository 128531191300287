import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
// import DateRange from '../blocks/dateRange/DateRange'
import MonthFilter from '../blocks/MonthFilter'
import ComponentExtend from '../ComponentExtend'
import Pagination from '../blocks/pagination/pagination'
import { copyText } from '../../helpers/commonHelpers'


class Dashboard2 extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        month: moment().format('MM/YYYY'),
        searchUser: '',
        page: 1
      }
    }
    this.onSubmitSearchMember = this.onSubmitSearchMember.bind(this)
    this.updatePage = this.updatePage.bind(this)
  }

  componentWillMount() {
    this.props.getListUser(this.state.formData.month, 1, this.limit)
  }

  onSubmitSearchMember(event) {
    event.preventDefault()
    const formData = {}
    for (let i = 0; i < event.target.elements.length; i++) {
      if (event.target[i].name) {
        formData[event.target[i].name] = event.target[event.target[i].name].value
      }
    }
    this.setState({
      formData: { ...formData, page: 1 }
    })
    this.props.getListUser(formData.month, 1, this.limit, formData.searchUser)
  }

  updatePage() {
    const { formData } = this.state
    this.setState({
      formData: { ...formData, page: this.props.page }
    })
    this.props.getListUser(formData.month, this.props.page, this.limit, this.state.searchUser)
  }

  componentDidUpdate() {
    if (this.state.formData.page !== this.props.page) {
      this.updatePage()
    }
  }

  onChangeMonth = (value) => {
    const formData = { ...this.state.formData }
    this.setState({
      formData: {
        ...formData,
        month: value
      }
    })
  }

  render() {
    const {
      userListByAgency = [],
      page = 1,
      overview = {},
      userInfo = {}
    } = this.props
    const { data = [], totalRecord = 0, totalInfo = {} } = userListByAgency
    // const maxDate = moment().format('YYYY-MM-DD')
    return (
      <div className="cls-aflg-profile cls-aflg-dashboard cls-aflg-dashboard-agency2 row">
        <div className="row">
          <div className="col-md-12 col-lg-5 block-firstrow-dashboard">
            <div className="bgWhite count-member">
              <h5>{this.l('Số lượng thành viền của tôi')} </h5>
              <div>
                <img src="/assets/img/img-count-memeber.png" alt={this.l('Số lượng thành viền của tôi')} />
                <span>{numeral(overview.numMember || 0).format('0,0')}</span>
              </div>
            </div>
            <div className="block-info-invite-reg">
              <div>
                <i className="icon-invite-member" />{this.l('Link mời thành viên')}: <span>{`${window.location.origin.toString()}/i/${userInfo.linkId}`}</span>
                <button className="btn btn-primary-cs" type="button" onClick={() => { copyText(`${window.location.origin.toString()}/i/${userInfo.linkId}`, this.l('Đã sao chép thành công')) }}>{this.l('Sao chép')}</button>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-7 block-revenue-agency2 nopadding row">
            <div className="col-md-6 block-revenue-month-ag2 nopadding">
              <div>
                <img src="/assets/img/icon-revenue-month.png" alt={this.l('Doanh thu tháng này')} />
                <h5>{this.l('Doanh thu tháng này')}</h5>
                <h4>
                  <span>{numeral(overview.revenueThisMonth || 0).format('0,0')}</span>
                </h4>
              </div>
            </div>
            <div className="col-md-6 block-member-month-ag2 nopadding">
              <div>
                <img src="/assets/img/icon-member-month.png" alt={this.l('Số thành viên hoạt động tháng này')} />
                <h5>{this.l('Số thành viên hoạt động tháng này')}</h5>
                <h4>
                  <span>{numeral(overview.numMemberActivieThisMonth || 0).format('0,0')}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 nopadding block-agency2-revenue nopadding">
            <div className="cls-block-info-commisstion block-content-afterlogin">
              <div className="head-afterlogin">
                <span>{this.l('Tổng kết doanh thu đại lý')}</span>
                <form className="header-afterlogin-form" onSubmit={this.onSubmitSearchMember}>
                  <input type="text" name="searchUser" placeholder={this.l('User ID, Email')} />
                  <MonthFilter onChange={this.onChangeMonth} />
                  <button type="submit" className="btn btn-primary-cs">{this.l('Xem')}</button>
                </form>
              </div>
              <div className="contain-block-afterlogin">
                <div className="table-reponsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{this.l('Họ và tên')}</th>
                        <th scope="col">{this.l('Số điện thoại')}</th>
                        <th scope="col">{this.l('Email')}</th>
                        <th scope="col">{this.l('Tổng tiền cược')}</th>
                        <th scope="col">{this.l('netProfit')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(data.length > 0) ? (
                        data.map((item, k) => {
                          const key = k
                          return (
                            <tr key={key}>
                              <td><span>{item.name}</span></td>
                              <td><span>{item.phone}</span></td>
                              <td><span>{item.email}</span></td>
                              <td>{numeral(item.turnOver).format('0,0')} {this.l('VND')}</td>
                              <td>{numeral(item.winLoss).format('0,0')} {this.l('VND')}</td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan="6" className="empty-record">{this.l('Empty record')}</td>
                        </tr>
                      )}
                      <tr className="last_row_table">
                        <td colSpan="2">{this.l('Tổng cộng')}</td>
                        <td>{numeral(totalInfo.numMemberActive || 0).format('0,0')} {this.l('Thành viên hoạt động')}</td>
                        <td>{numeral(totalInfo.totalTurnOver || 0).format('0,0')} {this.l('VND')}</td>
                        <td>{numeral(totalInfo.totalWinLoss || 0).format('0,0')} {this.l('VND')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <center>
                  <Pagination
                    urlPage="/dashboard"
                    total_row={totalRecord}
                    page={Number(page)}
                    limit={this.limit}
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard2
