import React from 'react'
import moment from 'moment'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import numeral from 'numeral'
// import DateRange from '../blocks/dateRange/DateRange'
import Breadcrumb from '../blocks/breadcrumb/Breadcrumb'
import ComponentExtend from '../ComponentExtend'
// import Pagination from '../blocks/pagination/pagination'
import MonthFilter from '../blocks/MonthFilter'

class Statistic extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        page: 1
      },
      month: moment().format('MM/YYYY'),
      showDataStatistic: false,
      showChangePass: false,
      valueForm: {},
      numRecordShowData: 15
    }
    this.onChangeMonth = this.onChangeMonth.bind(this)
    // this.onSubmitSearch = this.onSubmitSearch.bind(this)
    this.onChangeShowLimitData = this.onChangeShowLimitData.bind(this)
  }

  componentWillMount() {
    this.props.getReportGeneral(this.state.month)
    this.props.reportGetChartMember(this.state.month)
    this.props.reportGetChartRevenue(this.state.month)
    this.props.reportRevenue(this.state.month, 1, this.state.numRecordShowData)
  }

  updatePage() {
    const { formData } = this.state
    this.setState({
      formData: { ...formData, page: this.props.page }
    })
    this.props.reportRevenue(this.state.month, this.props.page, this.state.numRecordShowData)
  }

  componentDidUpdate() {
    if (this.state.formData.page !== this.props.page) {
      this.updatePage()
    }
  }

  onChangeShowLimitData(event) {
    this.setState({
      numRecordShowData: event.target.value
    })
  }

  renderStatistic() {
    // sho bang so lieu
    if (this.state.showDataStatistic) {
      // const { page = 1, reportRevenueList = {} } = this.props
      // const { totalRecord = 0, data = [] } = reportRevenueList
      const { reportRevenueList = {} } = this.props
      const { data = [] } = reportRevenueList
      return (
        <div className="table-data-statistic">
          <div className="col-md-12">
            <div className="table-reponsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{this.l('Ngày')}</th>
                    <th scope="col">{this.l('Thành viên đăng ký mới')}</th>
                    <th scope="col">{this.l('Đại lý đăng ký mới')}</th>
                    <th scope="col">{this.l('Số thành viên hoạt động')}</th>
                    <th scope="col">{this.l('Lãi ròng từ thành viên')}</th>
                    <th scope="col">{this.l('Lãi ròng từ đại lý')}</th>
                  </tr>
                  {(data.length > 0) ? (
                    data.map((item, k) => {
                      const key = k
                      return (
                        <tr key={key}>
                          <td>{item.date}</td>
                          <td>{numeral(item.numMemberRegisterNew || 0).format('0,0')}</td>
                          <td>{numeral(item.numAgencyNewRegister || 0).format('0,0')}</td>
                          <td>{numeral(item.numMemberActive || 0).format('0,0')}</td>
                          <td>{numeral(item.taxMember || 0).format('0,0')} {this.l('VND')}</td>
                          <td>{numeral(item.taxAgency2 || 0).format('0,0')} {this.l('VND')}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan="11" className="empty-record">{this.l('Empty record')}</td>
                    </tr>
                  )}
                </thead>
                <tbody />
              </table>
            </div>
            {/* <div className="pagination_statistic">
              <div>
                <span>{this.l('Hiển thị')}:</span>
                <select value={this.state.numRecordShowData} onChange={this.onChangeShowLimitData}>
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="35">35</option>
                  <option value="45">45</option>
                  <option value="60">60</option>
                </select>
              </div>

              <Pagination
                urlPage="/statistic"
                total_row={totalRecord}
                page={page}
                limit={this.state.onChangeShowLimitData}
              />
            </div> */}
          </div>
        </div>
      )
    }
    // sho bieu do
    const optionsMembersMonth = {
      chart: {
        type: 'line'
      },
      title: {
        text: this.l('Số thành viên hoạt động trong tháng')
      },
      xAxis: {
        categories: this.props.chartMember.categories || []
      },
      yAxis: {
        title: {
          text: this.l('Số thành viên')
        }
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: false
        }
      },
      series: [{
        color: '#3ab54b',
        name: this.l('Số thành viên'),
        data: this.props.chartMember.data || []
      }]
    }
    const optionsRevenueMonth = {
      chart: {
        type: 'line'
      },
      title: {
        text: this.l('Doanh thu trong tháng')
      },
      xAxis: {
        categories: this.props.chartRevenue.categories || []
      },
      yAxis: {
        title: {
          text: this.l('Doanh thu')
        }
      },
      plotOptions: {
        line: {

          dataLabels: {
            enabled: true
          },
          enableMouseTracking: false
        }
      },
      series: [{
        name: this.l('Doanh thu'),
        data: this.props.chartRevenue.data || []
      }]
    }
    return (
      <div className="table-data-statistic">
        <div className="col-md-12">
          <div className="col-md-12" style={{ border: '1px solid #e2e2e2', marginBottom: '30px' }}>
            <div className="row">
              <div className="col-md-6">
                <HighchartsReact highcharts={Highcharts} options={optionsMembersMonth} />
              </div>
              <div className="col-md-6">
                <HighchartsReact highcharts={Highcharts} options={optionsRevenueMonth} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  changeTabStatistic(value) {
    if (value === 'map') {
      this.setState({
        showDataStatistic: false
      })
    } else {
      this.setState({
        showDataStatistic: true
      })
    }
  }

  onChangeMonth(value) {
    this.setState({
      month: value
    })
    this.props.getReportGeneral(value)
    this.props.reportGetChartMember(value)
    this.props.reportGetChartRevenue(value)
    this.props.reportRevenue(value, 1, this.state.numRecordShowData)
  }

  render() {
    const { reportGeneral = {}, reportRevenueList = {} } = this.props
    // const maxDate = moment().format('YYYY-MM-DD')
    const { totalInfo = {} } = reportRevenueList
    return (
      <div className="cls-aflg-statistics row">
        <Breadcrumb arrBreadcrumb={[{ title: this.l('Thống kê') }]}>
          <div className="time-update-data" style={{ position: 'relative', float: 'right' }}>
            {this.l('Số liệu cập nhật lúc')}: {reportGeneral.datetimeUpdateDate}
          </div>
        </Breadcrumb>
        <div className="row">
          <div className="col-md-12 nopadding">
            <div className=" block-content-afterlogin">
              <div className="head-afterlogin">
                <span>{this.l('Báo cáo thu nhập')}</span>
                <form className="header-afterlogin-form">
                  <span>{this.l('Thu thập trong tháng')}</span>
                  <MonthFilter onChange={this.onChangeMonth} />
                </form>
              </div>
              <hr />
              <div className="contain-block-afterlogin">
                {/* <div className="cls-report-income">
                  <div className="surplus-before-month">
                    <span>{this.l('Số dư tháng trước')}</span>
                    <p>{numeral(reportGeneral.lastMonthBalance).format('0,0')} <span>{this.l('VND')}</span></p>
                  </div>
                </div> */}
                <div className="cls-report-income">
                  <div className="surplus-form-members">
                    <span>{this.l('Doanh thu từ thành viên')}</span>
                    {/* <p>{numeral(reportGeneral.netMoneyFromMember).format('0,0')} <span>{this.l('VND')}</span></p> */}
                    <p>{numeral(totalInfo.revenueFromMember).format('0,0')} <span>{this.l('VND')}</span></p>
                  </div>
                </div>
                <div className="cls-report-income">
                  <div className="surplus-form-agency">
                    <span>{this.l('Doanh thu từ đại lý')}</span>
                    {/* <p>{numeral(reportGeneral.netMoneyFromAgency).format('0,0')} <span>{this.l('VND')}</span></p> */}
                    <p>{numeral(totalInfo.revenueFromAgency).format('0,0')} <span>{this.l('VND')}</span></p>
                  </div>
                </div>
                <div className="cls-report-income">
                  <div className="money-withdraw">
                    <span>{this.l('Tiền đã rút')}</span>
                    <p>{numeral(reportGeneral.moneywithdraw).format('0,0')} <span>{this.l('VND')}</span></p>
                  </div>
                </div>
                {/* <div className="cls-report-income">
                  <div className="surplus-this-month">
                    <span>{this.l('Số dư tháng này')}</span>
                    <p>{numeral(reportGeneral.balanceThisMonth).format('0,0')} <span>{this.l('VND')}</span></p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 nopadding">
            <div className="cls-block-info-commisstion block-content-afterlogin">
              <div className="head-afterlogin col-md-12">
                <span>{this.l('Thống kê')}</span>
              </div>
              <hr />
              <div className="contain-block-afterlogin">
                <div className="tab-header-statistic col-md-12">
                  <div className="tab-statistic">
                    <a href="#" onClick={() => { this.changeTabStatistic('map') }} className={`${(this.state.showDataStatistic) ? '' : 'active'}`}>{this.l('Biểu đồ')}</a>
                    <a href="#" onClick={() => { this.changeTabStatistic('data') }} className={`${(this.state.showDataStatistic) ? 'active' : ''}`}>{this.l('Bảng số liệu')}</a>
                  </div>
                  {/* <div className="time-update-data">
                    {this.l('Số liệu cập nhật lúc')}: {reportGeneral.datetimeUpdateDate}
                  </div> */}
                </div>
                <div>
                  {this.renderStatistic()}
                </div>
                <div className="row info-static-statistic">
                  <div className="col-md-3 col-lg-3 revenue-members">
                    <div>
                      <span>{this.l('Tổng doanh thu')}</span>
                      <p>{numeral(totalInfo.revenue).format('0,0')} <span>{this.l('VND')}</span></p>
                      {/* <img src="/assets/img/icon-revenue-month-105.png" alt={this.l('Doanh thu thành viên')} /> */}
                    </div>
                  </div>
                  <div className="col-md-9 col-lg-9 nopadding">
                    <div className="row members-statistic">
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div>
                          <span>{this.l('Thành viên đăng ký mới')}</span>
                          <p>{numeral(totalInfo.numMemberRegisterNew).format('0,0')}</p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div>
                          <span>{this.l('Đại lý đăng ký mới')}</span>
                          <p>{numeral(totalInfo.agencyRegisterNew).format('0,0')}</p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div>
                          <span>{this.l('Số thành viên hoạt động')}</span>
                          <p>{numeral(totalInfo.numMemberactive).format('0,0')}</p>
                        </div>
                      </div>
                      {/* <div className="col-sm-6 col-md-6 col-lg-4">
                        <div>
                          <span>{this.l('Tổng thành viên nạp')}</span>
                          <p>{numeral(totalInfo.totalMoneyMemberDeposit).format('0,0')}
                            <span>{this.l('VND')}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div>
                          <span>{this.l('Tổng thành viên rút')}</span>
                          <p>{numeral(totalInfo.totalMoneyMemberWithdraw).format('0,0')}
                            <span>{this.l('VND')}</span>
                          </p>
                        </div>
                      </div> */}
                      {/* <div className="col-sm-6 col-md-6 col-lg-4">
                        <div>
                          <span>{this.l('Doanh thu từ thành viên')}</span>
                          <p>{numeral(totalInfo.revenueFromMember).format('0,0')}
                            <span>{this.l('VND')}</span>
                          </p>
                        </div>
                      </div> */}
                      {/* <div className="col-sm-6 col-md-6 col-lg-4">
                        <div>
                          <span>{this.l('Tổng thành viên đại lý nạp')}</span>
                          <p>{numeral(totalInfo.totalMoneyMemberAgencyDeposit).format('0,0')}
                            <span>{this.l('VND')}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div>
                          <span>{this.l('Tổng thành viên đại lý rút')}</span>
                          <p>{numeral(totalInfo.totalMoneyMemberAgencyWithdraw).format('0,0')}
                            <span>{this.l('VND')}</span>
                          </p>
                        </div>
                      </div> */}
                      {/* <div className="col-sm-6 col-md-6 col-lg-4">
                        <div>
                          <span>{this.l('Doanh thu từ đại lý')}</span>
                          <p>{numeral(totalInfo.revenueFromAgency).format('0,0')}
                            <span>{this.l('VND')}</span>
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Statistic
