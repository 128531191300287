import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import I18n from 'redux-i18n'

import { Provider } from 'react-redux'
import { persistStore, createTransform } from 'redux-persist'
import CryptoJS from 'crypto-js'
import * as serviceWorker from './serviceWorker'

import history from './history'

import translations from './translations'
import store from './configs/store.config'
import AppRoutes from './routers/routers'

class App extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  componentDidMount() {
    const encryptTransform = createTransform(
      (inboundState) => {
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(inboundState), 'OBAASH7HOQCFOYYD')
        return encrypted.toString()
      },
      (outboundState) => {
        const decrypted = CryptoJS.AES.decrypt(outboundState, 'OBAASH7HOQCFOYYD')
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
      },
      { whitelist: ['i18nState', 'apiResponse', 'generalReducer', 'accountsReducer'] }
    )

    persistStore(store, {
      transforms: [encryptTransform],
      whitelist: ['i18nState', 'apiResponse', 'generalReducer', 'accountsReducer']
    }, () => {
      this.setState({ loading: false })
      // store.dispatch({ type: 'GET_INFO' })
    })
  }

  render() {
    if (this.state.loading === true) {
      return <div style={{ textAlign: 'center' }}>Khởi tạo ứng dụng...</div>
    }
    // const history = createBrowserHistory({ basename: '/' })
    // const language = store.getState()

    return (
      <Provider store={store}>
        {/* <I18n translations={translations} initialLang={language.i18nState.lang}> */}
        <I18n translations={translations} initialLang="vi">
          <Router history={history}>
            <AppRoutes />
          </Router>
        </I18n>
      </Provider>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
