import { all, fork } from 'redux-saga/effects'

import * as General from './general'
import * as Accounts from './accounts'
// import * as Lottery from './lottery'
// import * as Payment from './payment'
// import * as News from './news'

function* rootSagas() {
  yield all([
    /* General API */
    fork(General.postRegisterClient),
    fork(General.postCaptcha),

    /* Accounts API */
    fork(Accounts.postAccountForgotPassword),
    fork(Accounts.postAccountNewPassword),
    fork(Accounts.postAccountLogin),
    fork(Accounts.postAccountLoginStep2),
    fork(Accounts.postAccountLogout),
    fork(Accounts.postAccountRegister),
    fork(Accounts.postAccountChangePass),
    fork(Accounts.postAccountGetProfile),
    fork(Accounts.commissionConfig),
    fork(Accounts.getAgencyList),
    fork(General.faqList),
    fork(General.promotionList),
    fork(General.promotionHotList),
    fork(General.promotionDetail),
    fork(General.page),
    fork(General.bannerCategories),
    fork(General.banners),
    fork(General.bannerList),
    fork(Accounts.getOverview),
    fork(Accounts.getRegisterAgencyList),
    fork(Accounts.confirmApproveAgency),
    fork(Accounts.getUserListByAgency),
    fork(Accounts.getCommissionConfigAgency),
    fork(Accounts.updateConfigCommisstionAgency),
    fork(Accounts.confirmBanAgency),
    fork(Accounts.reportGeneral),
    fork(Accounts.reportGetChartMember),
    fork(Accounts.reportGetChartRevenue),
    fork(Accounts.withdrawHistory),
    fork(Accounts.withdraw),
    fork(Accounts.reportRevenue),
    fork(Accounts.getBalance),
    fork(Accounts.getBank),
    fork(Accounts.getProvince),
    fork(Accounts.getRemember),
    fork(Accounts.sendLinkActiveEmail),
    fork(Accounts.getUrlInviteUserOfLinkId),
    fork(Accounts.getAgentBannerList)
  ])
}

export default rootSagas
