import React from 'react'
import t from 'tcomb-form'

import isEmpty from 'lodash/isEmpty'

// import { NotificationManager } from 'react-notifications'
import { TCombForm } from '../../../ui'
import { change_alias } from '../../../helpers'
import { Images } from '../../../ui/assets/images'
// import { change_alias } from '../../../helpers'

const _ = {
  isEmpty
}

class ForgotPass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: {},
      captchaRef: null,
      errorsMsg: ''
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCallback = this.onCallback.bind(this)
  }

  onChange(value) {
    if (value.captcha && value.captcha.ref) {
      const { ref } = value.captcha
      value = { ...value, captcha: value.captcha.value }
      this.setState({ value, errorsMsg: '', captchaRef: ref })
    } else {
      this.setState({ value, errorsMsg: '' })
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const value = this.profileForm.getValue()
    if (value) {
      this.setState({ errorsMsg: '' })
      const params = {
        email: change_alias(value.email),
        captcha: value.captcha
      }
      this.props.onSubmit(params, this.onCallback)
    }
  }

  onCallback(status, data) {
    if (status) {
      this.props.redirectSuccess()
    } else {
      this.refreshCaptcha()
      this.setState({ errorsMsg: data.message })
    }
  }

  refreshCaptcha() {
    if (this.state.captchaRef) {
      this.state.captchaRef.props.grecaptcha.reset()
    }
  }

  renderErrors() {
    if (!_.isEmpty(this.state.errorsMsg)) {
      return (
        <div className="alert alert-danger">{this.state.errorsMsg}</div>
      )
    }
    return null
  }

  render() {
    const { translate } = this.props

    const formatEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const Email = t.refinement(t.String, (s) => {
      return formatEmail.test(s.trim())
    })

    const Person = t.struct({
      email: Email,
      captcha: t.String
    })

    const options = {
      auto: 'placeholders',
      fields: {
        email: {
          label: translate('yourEmail'),
          error: translate('validEmail'),
          attrs: {
            placeholder: translate('yourEmail')
          },
          template: TCombForm.TextRegister
        },
        captcha: {
          label: translate('Captcha'),
          error: translate('validCaptcha'),
          attrs: {
            placeholder: translate('Captcha')
          },
          config: { img: this.props.captchaImg },
          template: TCombForm.TextCaptcha
        }
      }
    }
    return (
      <div className="account-page register-page clearfix">
        <div className="wrapper-inner">
          <div className="box-info">
            <div className="row">
              <div className="info-left col-12 col-md-7 nopadding">
                <div className="info-title">
                  <ul>
                    <li>{translate('ForgotPassword')}</li>
                  </ul>
                </div>
                <div className="info-content">
                  <form className="frm-register" onSubmit={this.onSubmit}>
                    {this.renderErrors()}
                    <t.form.Form
                      ref={(element) => { this.profileForm = element }}
                      type={Person}
                      value={this.state.value}
                      options={options}
                      onChange={this.onChange}
                    />

                    <div className="form-group text-center">
                      <button type="submit" className="btn btn-register text-uppercase">{translate('ok')}</button>
                    </div>

                  </form>
                </div>
              </div>
              <div className="info-right col-12 col-md-5 nopadding">
                <div className="orange-box">
                  <div className="box-title text-uppercase">Đăng ký đại lý Alo88 !</div>
                  <div className="box-highlight">Cùng biến đam mê thành lợi nhuận</div>
                  <ul>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;100% đăng ký miễn phí</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;50% chiết khấu hoa hồng</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;Cam kết an toàn &amp; bảo mật</li>
                    <li><img src={Images.iconRegCheck} alt="" />&nbsp;&nbsp;&nbsp;Luôn thanh toán đúng hạn</li>
                  </ul>
                  <div className="box-img"><img src={Images.imgReg} alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPass
