import React from 'react'
import PropTypes from 'prop-types'
import RegisterSuccess from './RegisterSuccess'

class RegisterSuccessContainer extends React.Component {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(prop) {
    super(prop)
    this.state = {}
  }

  render() {
    return (
      <RegisterSuccess
        translate={this.context.t}
      />
    )
  }
}


export default RegisterSuccessContainer
