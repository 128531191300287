import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import SidebarLeft from '../sidebar/SidebarLeft.container'
import ComponentExtend from '../ComponentExtend'

class Details extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    /* eslint-disable no-underscore-dangle */
    const { promotionDetail = null, listPromotion } = this.props
    if (!promotionDetail) {
      return <div>{this.l('Không tìm thấy')}</div>
    }
    return (
      <div className="news-page details-page clearfix">
        <div className="wrapper-inner">
          <div className="row">
            <div className="info-left col-12 order-2 col-md-4 order-md-1 col-lg-3 nopadding">
              <SidebarLeft />
            </div>

            <div className="info-content col-12 order-1 col-md-8 order-md-2 col-lg-9 nopadding">
              <div className="white-box">
                <div className="box-title">{promotionDetail.title}</div>

                <div className="box-content">
                  <div dangerouslySetInnerHTML={{ __html: promotionDetail.summary }} />
                </div>

                <div className="box-more">
                  <div className="box-more-title">{this.l('Các tin tức khác')}</div>
                  <div className="box-more-content">
                    {listPromotion.list.map((item, k) => {
                      const key = k
                      return (
                        <div className="items row" key={key}>
                          <div className="item-img col-12 col-sm-4 col-md-3 nopadding">
                            <Link to={`/news/details?id=${item._id}`}>
                              <img src={item.images} alt={item.title} />
                            </Link>
                          </div>
                          <div className="item-info col-12 col-sm-8 col-md-9 nopadding">
                            <div className="item-title"><Link to={`/news/details?id=${item._id}`}>{item.title}</Link></div>
                            <div className="item-date">{moment(item.createdAt).format('DD/MM/YYYY')}</div>
                          </div>
                        </div>
                      )
                    })}

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Details
