import HomeContainer from '../components/home/Home.container'

import RegisterContainer from '../components/account/register/Register.container'
import RegisterSuccessContainer from '../components/account/registerSuccess/RegisterSuccess.container'
import ForgotPassContainer from '../components/account/forgotPass/ForgotPass.container'
import ForgotSuccessContainer from '../components/account/forgotSuccess/ForgotSuccess.container'

import CommissionContainer from '../components/commission/Commission.container'

import NewsListContainer from '../components/news/List.container'
import NewsDetailsContainer from '../components/news/Details.container'
import PagesContainer from '../components/pages/Pages.container'

import NotFound from '../components/notfound/NotFound.container'

import * as Layout from '../layouts'

import DashboardContainer from '../components/dashboard/Dashboard.container'
import ProfiledContainer from '../components/profile/Profile.container'
import MembersContainer from '../components/members/Members.container'
import AgencyContainer from '../components/agency/Agency.container'
import WithdrawContainer from '../components/withdraw/Withdraw.container'
import StatisticContainer from '../components/statistic/Statistic.container'
import InviteUserContainer from '../components/inviteUser/InviteUser.container'
import ActiveEmailContainer from '../components/redirect/ActiveEmail.container'
import NewPassContainer from '../components/account/newPass/NewPass.container'
import NewPassSuccessContainer from '../components/account/newPassSuccess/NewPassSuccess.container'
import BannerContainer from '../components/banner/Banner.container'
import PromotionContainer from '../components/promotion-content/Promotion.container'

const routes = [
  {
    layout: Layout.Home,
    path: '/',
    exact: true,
    component: HomeContainer,
    private: false
  },

  {
    layout: Layout.Home,
    path: '/login',
    exact: true,
    component: HomeContainer,
    private: false
  },

  {
    layout: Layout.Home,
    path: '/register',
    exact: true,
    component: RegisterContainer,
    private: false
  },
  {
    layout: Layout.Home,
    path: '/r/:refcode',
    exact: true,
    component: RegisterContainer,
    private: false
  },
  {
    layout: Layout.Home,
    path: '/register/success',
    exact: true,
    component: RegisterSuccessContainer,
    private: false
  },

  {
    layout: Layout.Home,
    path: '/forgot-pass',
    exact: true,
    component: ForgotPassContainer,
    private: false
  },

  {
    layout: Layout.Home,
    path: '/forgot-pass/success',
    exact: true,
    component: ForgotSuccessContainer,
    private: false
  },

  {
    layout: Layout.Home,
    path: '/newPassword/:code',
    exact: true,
    component: NewPassContainer,
    private: false
  },

  {
    layout: Layout.Home,
    path: '/new-pass/success',
    exact: true,
    component: NewPassSuccessContainer,
    private: false
  },

  {
    layout: Layout.Home,
    path: '/commission',
    exact: true,
    component: CommissionContainer,
    private: false
  },

  {
    layout: Layout.Home,
    path: '/dai-ly-chinh-hieu',
    exact: true,
    component: PromotionContainer,
    private: false
  },

  {
    layout: Layout.Home,
    path: '/banner',
    exact: true,
    component: BannerContainer,
    private: false
  },
  {
    layout: Layout.Home,
    path: '/news',
    exact: true,
    component: NewsListContainer,
    private: false
  },
  {
    layout: Layout.Home,
    path: '/news/event.html',
    exact: true,
    component: NewsListContainer,
    private: false
  },
  {
    layout: Layout.Home,
    path: '/news/details',
    exact: true,
    component: NewsDetailsContainer,
    private: false
  },

  {
    layout: Layout.Home,
    path: '/pages/:alias_unique',
    exact: true,
    component: PagesContainer,
    private: false
  },

  // sau khi login
  {
    layout: Layout.AfterLogin,
    path: '/dashboard',
    exact: true,
    component: DashboardContainer,
    private: false
  },
  {
    layout: Layout.AfterLogin,
    path: '/profile',
    exact: true,
    component: ProfiledContainer,
    private: false
  },
  {
    layout: Layout.AfterLogin,
    path: '/members',
    exact: true,
    component: MembersContainer,
    private: false
  },
  {
    layout: Layout.AfterLogin,
    path: '/agency',
    exact: true,
    component: AgencyContainer,
    private: false
  },
  {
    layout: Layout.AfterLogin,
    path: '/withdraw',
    exact: true,
    component: WithdrawContainer,
    private: false
  },
  {
    layout: Layout.AfterLogin,
    path: '/statistic',
    exact: true,
    component: StatisticContainer,
    private: false
  },
  {
    layout: Layout.Empty,
    path: '/i/:linkId',
    exact: true,
    component: InviteUserContainer,
    private: false
  },
  {
    layout: Layout.Home,
    path: '/activeEmail/:code',
    exact: true,
    component: ActiveEmailContainer,
    private: false
  },
  {
    layout: Layout.Home,
    component: NotFound,
    private: false
  }
]

export default routes
