import React from 'react'
// import numeral from 'numeral'
// import SidebarLeft from '../sidebar/SidebarLeft.container'
import ComponentExtend from '../ComponentExtend'
import { generalAPI } from '../../api'

class Banner extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      banners: [],
      currentCategoryId: '',
      affId: ''
    }
  }

  onShowBanners() {
    generalAPI.getBanners({ categoryId: this.state.currentCategoryId })
      .then((res) => {
        this.setState({ banners: res.data.list })
      })
  }

  handleChange(event, key) {
    this.setState({ [key]: event.target.value })
  }

  copyToClipboard = (text) => {
    const textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  renderBanner(banner) {
    const embed = `<a href="https://alo88.bet/dang-ky?affid=${this.state.affId}"><img src="${banner.image}" border='0' style="max-width: 100%" /></a>`
    const key = `copyText_${banner.id}`
    this.state[key] = 'Copy'
    return (
      <div key={banner.id} className="row banner-item">
        <div className="col-md-6" style={{ marginBottom: '10px' }}>
          <img className="thumbnail" src={banner.image} alt={banner.name} />
        </div>
        <div className="col-md-6">
          <div>
            <div className="pull-left" style={{ float: 'left' }}>
              <div>Kích thước: <b>{banner.width} x {banner.height}</b></div>
              <div>Mã nhúng:</div>
            </div>
            <div className="pull-right" style={{ float: 'right' }}>
              <input type="button" className="btn btn-warning" onClick={() => this.copyToClipboard(embed)} value={this.state[key]} />
            </div>
          </div>
          <textarea value={embed} className="form-control" onChange={() => { }} style={{ height: '100px' }} />
        </div>
      </div>
    )
  }

  render() {
    const { categories } = this.props
    if (categories && categories.list && categories.list.length && !this.state.currentCategoryId) {
      this.state.currentCategoryId = categories.list[0].categoryId
    }
    const whiteText = { color: '#ffffff', width: '100%' }
    return (
      <div className="news-page banner-page clearfix">
        <div className="wrapper-inner">
          <div className="row">
            <div className="info-content col-12 col-md-12 col-lg-12 nopadding">
              <div className="white-box">
                <div className="box-title">Banners</div>
                <div className="box-content">
                  <div className="row">
                    <div className="col-md-3 config-box">
                      <div className="config-label">Loại Banner</div>
                      <select className="form-control" value={this.state.currentCategoryId} onChange={e => this.handleChange(e, 'currentCategoryId')}>
                        {
                          categories ? categories.list.map(category => <option key={category.categoryId} value={category.categoryId}>{category.name}</option>) : ''
                        }
                      </select>
                    </div>
                    <div className="col-md-3 config-box">
                      <div className="config-label">Mã Đại Lý</div>
                      <input className="form-control" type="text" value={this.state.affId} onChange={e => this.handleChange(e, 'affId')} />
                    </div>
                    <div className="col-md-3 config-box">
                      <div className="config-label" style={whiteText}>.</div>
                      <input type="button" className="btn btn-primary" value="Áp Dụng" onClick={() => this.onShowBanners()} />
                    </div>
                  </div>
                  <div className={this.state.banners.length ? 'banner-list' : ''}>
                    {
                      this.state.banners.map(banner => this.renderBanner(banner))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Banner
