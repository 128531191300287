import React from 'react'
// import SidebarLeft from '../sidebar/SidebarLeft.container'
import ComponentExtend from '../ComponentExtend'
import NotFoundContainer from '../notfound/NotFound.container'

class Details extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    /* eslint-disable no-underscore-dangle */
    const { page = null, alias_unique = '' } = this.props
    console.log('page', page)
    window.scrollTo(0, 0)
    if (!page || !page.list[alias_unique]) {
      return <NotFoundContainer />
    }
    return (
      <div className="news-page details-page clearfix">
        <div className="wrapper-inner">
          <div className="row">
            <div className="info-content col-md-12 nopadding">
              <div className="white-box">
                <div className="box-title" dangerouslySetInnerHTML={{ __html: page.list[alias_unique].title || '' }} />
                <div className="box-content content-html">
                  <div dangerouslySetInnerHTML={{ __html: page.list[alias_unique].content || '' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Details
