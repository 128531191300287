import * as types from './general.types'

const initialState = {
  clientInfo: {},
  classSidebar: '',
  lang: 'vi',
  faq: { total: 0, list: [] },
  listPromotion: { total: 0, list: [] },
  listHotPromotion: { total: 0, list: [] },
  promotionDetail: {},
  page: {},
  bannerList: [],
  captcha: {}
}
export default function accounts(state = initialState, action) {
  switch (action.type) {
    case types.POST_REGISTER_CLIENT_SUCCESS:
      return {
        ...state,
        clientInfo: { ...state.clientInfo, ...action.payload }
      }

    case types.POST_REGISTER_CLIENT_FAIL:
      return {
        ...state,
        clientInfo: {}
      }
    case types.CHANGE_CLASS_SIDEBAR:
      if (state.classSidebar === '') {
        return {
          ...state,
          classSidebar: 'active'
        }
      }
      return {
        ...state,
        classSidebar: ''
      }
    case types.CHANGE_LANG:
      return {
        ...state,
        lang: action.lang
      }

    case types.FAQ_LIST_SUCCESS:
      return {
        ...state,
        faq: { total: action.payload.totalRecode, list: action.payload.list }
      }
    case types.FAQ_LIST_FAIL:
      return {
        ...state,
        faq: { total: 0, list: [] }
      }
    case types.PROMOTION_LIST_SUCCESS:
      return {
        ...state,
        listPromotion: { total: action.payload.totalRecode, list: action.payload.list }
      }
    case types.PROMOTION_HOT_LIST_SUCCESS:
      return {
        ...state,
        listHotPromotion: { total: action.payload.totalRecode, list: action.payload.list }
      }
    case types.PROMOTION_HOT_LIST:
    case types.PROMOTION_HOT_LIST_FAIL:
      return {
        ...state,
        listHotPromotion: { total: 0, list: [] }
      }

    case types.PROMOTION_LIST_FAIL:
      return {
        ...state,
        listPromotion: { total: 0, list: [] }
      }
    case types.PROMOTION_DETAIL_SUCCESS:
      return {
        ...state,
        promotionDetail: action.payload
      }
    case types.PROMOTION_DETAIL_FAIL:
      return {
        ...state,
        promotionDetail: {}
      }
    case types.GET_PAGE_SUCCESS:
      return {
        ...state,
        page: action.payload
      }
    case types.GET_PAGE_FAIL:
      return {
        ...state,
        page: {}
      }
    case types.BANNER_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload
      }
    case types.BANNER_CATEGORIES_FAIL:
      return {
        ...state,
        categories: { message: 'Thất bại', list: [] }
      }
    case types.BANNERS_SUCCESS:
      return {
        ...state,
        banners: { total: 0, list: [] }
      }
    case types.BANNERS_FAIL:
      return {
        ...state,
        banners: {}
      }
    case types.BANNER_LIST_SUCCESS:
      return {
        ...state,
        bannerList: action.payload.list
      }
    case types.BANNER_LIST_FAIL:
      return {
        ...state,
        bannerList: []
      }
    case types.POST_CAPTCHA_SUCCESS:
      return {
        ...state,
        captcha: action.payload
      }

    default:
      return state
  }
}
