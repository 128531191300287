import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReCAPTCHA from 'react-google-recaptcha'
import * as GeneralAction from '../../../redux/general/general.actions'

class GGCaptcha extends React.Component {
  constructor(prop) {
    super(prop)
    this.getCaptcha = this.getCaptcha.bind(this)
  }

  getCaptcha() {
    const { generalAct } = this.props
    generalAct.postCaptcha()
  }

  refreshCaptcha() {
    this.captchaRef.reset()
  }

  render() {
    const { locals, modal = true } = this.props
    // console.log('-------captcha', captcha)
    const hasError = locals.hasError === true ? 'has-error' : ''
    const msg = locals.error ? <span className="help-block error-block">{locals.error}</span> : ''
    const label = locals.label ? locals.label : locals.attrs.name
    if (modal) {
      return (
        <div className={`eform-input eform-input-${locals.attrs.name} ${hasError}`}>
          <div className="eform-desc">
            <div className="row">
              <ReCAPTCHA
                ref={(element) => { this.captchaRef = element }}
                theme="light"
                sitekey="6Lf-NFEjAAAAAN_crYjprEVmvJltOVpS84Jpt_wc"
                onChange={value => locals.onChange(value)}
              />
            </div>
            {msg}
          </div>
          <div className="clearfix" />
        </div>
      )
    }
    return (
      <div className={`eform-input eform-input-${locals.attrs.name} ${hasError} row`}>
        <div className="eform-label col-12 col-lg-4 nopadding">{label}<span>:</span></div>
        <div className="eform-desc col-12 col-lg-8 nopadding">
          <div className="row">
            <ReCAPTCHA
              ref={(element) => { this.captchaRef = element }}
              theme="light"
              sitekey="6Lf-NFEjAAAAAN_crYjprEVmvJltOVpS84Jpt_wc"
              onChange={value => locals.onChange({ value, ref: this.captchaRef })}
            />
          </div>
          {msg}
        </div>
        <div className="clearfix" />
      </div>
    )
  }
}
function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralAction, dispatch)

  }
}

export default connect((rootState) => {
  return {
    captcha: rootState.generalReducer.captcha
  }
}, mapsDispatchToProp)(GGCaptcha)
