import * as types from './general.types'

export function postRegisterClient(payload, callback) {
  return {
    type: types.POST_REGISTER_CLIENT,
    payload,
    callback
  }
}

export function postCaptcha(payload, callback) {
  return {
    type: types.POST_CAPTCHA,
    payload,
    callback
  }
}

export function changeClassSidebar(payload, callback) {
  return {
    type: types.CHANGE_CLASS_SIDEBAR,
    payload,
    callback
  }
}

export function changeLang(lang) {
  return {
    type: types.CHANGE_LANG,
    lang
  }
}

// alias_unique, limit, page
export function FAQList(payload) {
  return {
    type: types.FAQ_LIST,
    payload
  }
}

// "alias_unique", "isHot", "limit", "page"
export function promotionList(payload, isLeftHot = false) {
  if (isLeftHot) {
    return {
      type: types.PROMOTION_HOT_LIST,
      payload,
      isLeftHot
    }
  }
  return {
    type: types.PROMOTION_LIST,
    payload,
    isLeftHot
  }
}

// "id"
export function promotionDetail(payload) {
  return {
    type: types.PROMOTION_DETAIL,
    payload
  }
}

// "id", "alias_unique"
export function getPage(payload) {
  return {
    type: types.GET_PAGE,
    payload
  }
}

// "alias_unique", 'isHot', 'limit'
export function bannerList(payload) {
  return {
    type: types.BANNER_LIST,
    payload
  }
}

export function getBannerCategories(payload) {
  return {
    type: types.BANNER_CATEGORIES,
    payload
  }
}

export function getBanners(payload) {
  return {
    type: types.BANNERS,
    payload
  }
}
