import React from 'react'
import ComponentExtend from '../ComponentExtend'

const defaultHTML = `<style type="text/css"> ul {
 list-style-type: revert !important;
}</style>
<div class="text-center">
  <image width="80%" src="/assets/img/banner-1.jpg" />
</div>
</br>
<ol>
<li><strong> THỜI GIAN KHUYẾN M&Atilde;I</strong><span style="font-weight: 400;"><br /></span></li>
</ol>
<p><span style="font-weight: 400;">&nbsp; &nbsp; &nbsp; Từ 00:00 ng&agrave;y 01/06/2021 đến 23:59 ng&agrave;y 31/7/2021&nbsp;</span></p>
<p class="pt-1"></p>
<ol start="2">
<li><strong> ĐỐI TƯỢNG V&Agrave; C&Aacute;CH THỨC THAM GIA</strong><span style="font-weight: 400;"><br /></span></li>
</ol>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Khuyến m&atilde;i n&agrave;y chỉ d&agrave;nh cho những đại l&yacute; mới đăng k&yacute; trong th&aacute;ng 6/2021 v&agrave; những đại l&yacute; chưa đạt ti&ecirc;u chuẩn trong những th&aacute;ng trước</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Để nhận được phần qu&agrave; n&agrave;y những đại l&yacute; tham gia phải đ&aacute;p ứng c&aacute;c ti&ecirc;u ch&iacute; sau:</span></li>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Số th&agrave;nh vi&ecirc;n hoạt động trong th&aacute;ng 6/2021 v&agrave; th&aacute;ng 7/2021 tối thiểu mỗi th&aacute;ng l&agrave; 5 th&agrave;nh vi&ecirc;n (Theo điều kiện </span><strong>Đại l&yacute; đạt ti&ecirc;u chuẩn </strong><span style="font-weight: 400;">tại daily.alo88.bet</span><strong>)</strong></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Doanh thu hợp lệ trong th&aacute;ng 6/2021 v&agrave; th&aacute;ng 7/2021 tối thiểu mỗi th&aacute;ng l&agrave; </span><strong>100 triệu đồng</strong></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">C&oacute; &iacute;t nhất 5 th&agrave;nh vi&ecirc;n c&oacute; doanh thu hợp lệ tr&ecirc;n từng th&agrave;nh vi&ecirc;n trong th&aacute;ng 6/2021 v&agrave; th&aacute;ng 7/2021 lớn hơn hoặc bằng </span><strong>10 triệu đồng</strong></li>
</ul>
</ul>
<ol start="3">
<li><strong> QU&Agrave; TẶNG</strong><span style="font-weight: 400;"><br /></span></li>
</ol>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Qu&agrave; tặng cho mỗi đại l&yacute; đ&aacute;p ứng c&aacute;c ti&ecirc;u ch&iacute; tr&ecirc;n l&agrave; một điện thoại iPhone 12 128GB trị gi&aacute; 22 triệu đồng</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Số lượng đại l&yacute; được nhận qu&agrave; tặng l&agrave; kh&ocirc;ng giới hạn&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Qu&agrave; tặng lu&ocirc;n đổi ra th&agrave;nh tiền</span></li>
</ul>
<ol start="4">
<li><strong> C&Aacute;C ĐIỀU KHOẢN KH&Aacute;C</strong><span style="font-weight: 400;"><br /></span></li>
</ol>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Alo88 c&oacute; quyền kh&ocirc;ng trao thưởng cho đại l&yacute;, c&oacute; quyền sửa đổi hoặc hủy chương tr&igrave;nh n&agrave;y nếu nhận thấy rằng chương tr&igrave;nh bị đại l&yacute; lạm dụng hoặc cần thay thế chương tr&igrave;nh n&agrave;y bằng một chương tr&igrave;nh kh&aacute;c tốt hơn</span></li>
</ul>`

class Promotion extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    // const { translate } = this.props
    const { page } = this.props
    return (
      <div className="news-page commission-page clearfix">
        <div className="wrapper-inner">
          <div className="row">
            <div className="info-content col-12 col-md-12 col-lg-12 nopadding">
              <div className="white-box">
                <div className="box-title">Đại Lý Chính Hiệu - Nhận Quà Tiền Triệu</div>
                <div className="box-content">

                  {
                    page && page.list
                    && <div className="item-desc" dangerouslySetInnerHTML={{ __html: page.list['dai-ly-chinh-hieu'] ? page.list['dai-ly-chinh-hieu'].content : '' }} />
                  }

                  {
                    (page && !page.list['dai-ly-chinh-hieu'])
                    && <div className="item-desc" dangerouslySetInnerHTML={{ __html: defaultHTML }} />
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Promotion
