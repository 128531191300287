
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import * as AccountsAction from '../../redux/accounts/accounts.actions'

import Statistic from './Statistic'
import StatisticAgency from './StatisticAgency'

class StatisticContainer extends React.Component {
  constructor(prop) {
    super(prop)
    this.state = {}
    this.getReportGeneral = this.getReportGeneral.bind(this)
    this.reportGetChartMember = this.reportGetChartMember.bind(this)
    this.reportGetChartRevenue = this.reportGetChartRevenue.bind(this)
    this.reportRevenue = this.reportRevenue.bind(this)
  }

  getReportGeneral(month) {
    const { accountAct } = this.props
    accountAct.reportGeneral({ month })
  }

  reportGetChartMember(month) {
    const { accountAct } = this.props
    accountAct.reportGetChartMember({ month })
  }

  reportGetChartRevenue(month) {
    const { accountAct } = this.props
    accountAct.reportGetChartRevenue({ month })
  }

  reportRevenue(month, page, limit) {
    const { accountAct } = this.props
    const params = {
      month,
      page,
      limit
    }
    accountAct.reportRevenue(params)
  }

  render() {
    const { userInfo = {} } = this.props
    const params = queryString.parse(this.props.location.search)
    const { page = 1 } = params
    if (userInfo.typeAgency === 1) {
      return (
        <Statistic
          page={Number(page)}
          reportGetChartMember={this.reportGetChartMember}
          reportGetChartRevenue={this.reportGetChartRevenue}
          reportRevenue={this.reportRevenue}
          getReportGeneral={this.getReportGeneral}
          chartMember={this.props.chartMember}
          reportGeneral={this.props.reportGeneral}
          chartRevenue={this.props.chartRevenue}
          reportRevenueList={this.props.reportRevenueList}

        />
      )
    }
    return (
      <StatisticAgency
        page={Number(page)}
        reportGetChartMember={this.reportGetChartMember}
        reportGetChartRevenue={this.reportGetChartRevenue}
        reportRevenue={this.reportRevenue}
        getReportGeneral={this.getReportGeneral}
        chartMember={this.props.chartMember}
        reportGeneral={this.props.reportGeneral}
        chartRevenue={this.props.chartRevenue}
        reportRevenueList={this.props.reportRevenueList}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch)
    // generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    reportGeneral: rootState.accountsReducer.reportGeneral,
    chartMember: rootState.accountsReducer.chartMember,
    chartRevenue: rootState.accountsReducer.chartRevenue,
    reportRevenueList: rootState.accountsReducer.reportRevenue
  }
}, mapDispatchToProps)(StatisticContainer)
