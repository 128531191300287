export const POST_ACCOUNT_FORGOT_PASSWORD = 'POST_ACCOUNT_FORGOT_PASSWORD'
export const POST_ACCOUNT_FORGOT_PASSWORD_SUCCESS = 'POST_ACCOUNT_FORGOT_PASSWORD_SUCCESS'
export const POST_ACCOUNT_FORGOT_PASSWORD_FAIL = 'POST_ACCOUNT_FORGOT_PASSWORD_FAIL'

export const POST_ACCOUNT_LOGIN = 'POST_ACCOUNT_LOGIN'
export const POST_ACCOUNT_LOGIN_SUCCESS = 'POST_ACCOUNT_LOGIN_SUCCESS'
export const POST_ACCOUNT_LOGIN_FAIL = 'POST_ACCOUNT_LOGIN_FAIL'

export const POST_ACCOUNT_LOGIN_STEP2 = 'POST_ACCOUNT_LOGIN_STEP2'
export const POST_ACCOUNT_LOGIN_STEP2_SUCCESS = 'POST_ACCOUNT_LOGIN_STEP2_SUCCESS'
export const POST_ACCOUNT_LOGIN_STEP2_FAIL = 'POST_ACCOUNT_LOGIN_STEP2_FAIL'

export const POST_ACCOUNT_LOGOUT = 'POST_ACCOUNT_LOGOUT'
export const POST_ACCOUNT_LOGOUT_SUCCESS = 'POST_ACCOUNT_LOGOUT_SUCCESS'
export const POST_ACCOUNT_LOGOUT_FAIL = 'POST_ACCOUNT_LOGOUT_FAIL'

export const POST_ACCOUNT_REGISTER = 'POST_ACCOUNT_REGISTER'
export const POST_ACCOUNT_REGISTER_SUCCESS = 'POST_ACCOUNT_REGISTER_SUCCESS'
export const POST_ACCOUNT_REGISTER_FAIL = 'POST_ACCOUNT_REGISTER_FAIL'

export const POST_ACCOUNT_GET_PROFILE = 'POST_ACCOUNT_GET_PROFILE'
export const POST_ACCOUNT_GET_PROFILE_SUCCESS = 'POST_ACCOUNT_GET_PROFILE_SUCCESS'
export const POST_ACCOUNT_GET_PROFILE_FAIL = 'POST_ACCOUNT_GET_PROFILE_FAIL'

export const POST_ACCOUNT_CHANGE_PASS = 'POST_ACCOUNT_CHANGE_PASS'
export const POST_ACCOUNT_CHANGE_PASS_SUCCESS = 'POST_ACCOUNT_CHANGE_PASS_SUCCESS'
export const POST_ACCOUNT_CHANGE_PASS_FAIL = 'POST_ACCOUNT_CHANGE_PASS_FAIL'

export const POST_ACCOUNT_NEW_PASS = 'POST_ACCOUNT_NEW_PASS'

export const COMMISSION_CONFIG = 'COMMISSION_CONFIG'
export const COMMISSION_CONFIG_SUCCESS = 'COMMISSION_CONFIG_SUCCESS'
export const COMMISSION_CONFIG_FAIL = 'COMMISSION_CONFIG_FAIL'

export const GET_REGISTER_AGENCY_LIST = 'GET_REGISTER_AGENCY_LIST'
export const GET_REGISTER_AGENCY_LIST_SUCCESS = 'GET_REGISTER_AGENCY_LIST_SUCCESS'
export const GET_REGISTER_AGENCY_LIST_FAIL = 'GET_REGISTER_AGENCY_LIST_FAIL'

export const CONFIRM_APPROVE_AGENCY = 'CONFIRM_APPROVE_AGENCY'
export const CONFIRM_APPROVE_AGENCY_SUCCESS = 'CONFIRM_APPROVE_AGENCY_SUCCESS'
export const CONFIRM_APPROVE_AGENCY_FAIL = 'CONFIRM_APPROVE_AGENCY_FAIL'

export const GET_AGENCY_LIST = 'GET_AGENCY_LIST'
export const GET_AGENCY_LIST_SUCCESS = 'GET_AGENCY_LIST_SUCCESS'
export const GET_AGENCY_LIST_FAIL = 'GET_AGENCY_LIST_FAIL'

export const ERROR_401 = 'ERROR_401'

export const GET_OVERVIEW = 'GET_OVERVIEW'
export const GET_OVERVIEW_SUCCESS = 'GET_OVERVIEW_SUCCESS'
export const GET_OVERVIEW_FAIL = 'GET_OVERVIEW_FAIL'

export const GET_USER_LIST_BY_AGENCY = 'GET_USER_LIST_BY_AGENCY'
export const GET_USER_LIST_BY_AGENCY_SUCCESS = 'GET_USER_LIST_BY_AGENCY_SUCCESS'
export const GET_USER_LIST_BY_AGENCY_FAIL = 'GET_USER_LIST_BY_AGENCY_FAIL'

export const GET_COMMISSION_CONFIG_AGENCY = 'GET_COMMISSION_CONFIG_AGENCY'
export const GET_COMMISSION_CONFIG_AGENCY_SUCCESS = 'GET_COMMISSION_CONFIG_AGENCY_SUCCESS'
export const GET_COMMISSION_CONFIG_AGENCY_FAIL = 'GET_COMMISSION_CONFIG_AGENCY_FAIL'

export const UPDATE_CONFIG_COMMISSION_AGENCY = 'UPDATE_CONFIG_COMMISSION_AGENCY'
export const UPDATE_CONFIG_COMMISSION_AGENCY_SUCCESS = 'UPDATE_CONFIG_COMMISSION_AGENCY_SUCCESS'
export const UPDATE_CONFIG_COMMISSION_AGENCY_FAIL = 'UPDATE_CONFIG_COMMISSION_AGENCY_FAIL'

export const CONFIRM_BAN_AGENCY = 'CONFIRM_BAN_AGENCY'
export const CONFIRM_BAN_AGENCY_SUCCESS = 'CONFIRM_BAN_AGENCY_SUCCESS'
export const CONFIRM_BAN_AGENCY_FAIL = 'CONFIRM_BAN_AGENCY_FAIL'

export const REPORT_GENERAL = 'REPORT_GENERAL'
export const REPORT_GENERAL_SUCCESS = 'REPORT_GENERAL_SUCCESS'
export const REPORT_GENERAL_FAIL = 'REPORT_GENERAL_FAIL'

export const REPORT_GET_CHART_MEMBER = 'REPORT_GET_CHART_MEMBER'
export const REPORT_GET_CHART_MEMBER_SUCCESS = 'REPORT_GET_CHART_MEMBER_SUCCESS'
export const REPORT_GET_CHART_MEMBER_FAIL = 'REPORT_GET_CHART_MEMBER_FAIL'

export const REPORT_GET_CHART_REVENUE = 'REPORT_GET_CHART_REVENUE'
export const REPORT_GET_CHART_REVENUE_SUCCESS = 'REPORT_GET_CHART_REVENUE_SUCCESS'
export const REPORT_GET_CHART_REVENUE_FAIL = 'REPORT_GET_CHART_REVENUE_FAIL'

export const REPORT_REVENUE = 'REPORT_REVENUE'
export const REPORT_REVENUE_SUCCESS = 'REPORT_REVENUE_SUCCESS'
export const REPORT_REVENUE_FAIL = 'REPORT_REVENUE_FAIL'

export const WITHDRAW_HISTORY = 'WITHDRAW_HISTORY'
export const WITHDRAW_HISTORY_SUCCESS = 'WITHDRAW_HISTORY_SUCCESS'
export const WITHDRAW_HISTORY_FAIL = 'WITHDRAW_HISTORY_FAIL'

export const WITHDRAW = 'WITHDRAW'
export const WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS'
export const WITHDRAW_FAIL = 'WITHDRAW_FAIL'

export const ACCOUNT_GET_BALANCE = 'ACCOUNT_GET_BALANCE'
export const ACCOUNT_GET_BALANCE_SUCCESS = 'ACCOUNT_GET_BALANCE_SUCCESS'
export const ACCOUNT_GET_BALANCE_FAIL = 'ACCOUNT_GET_BALANCE_FAIL'

export const GET_BANK = 'GET_BANK'
export const GET_BANK_SUCCESS = 'GET_BANK_SUCCESS'
export const GET_BANK_FAIL = 'GET_BANK_FAIL'

export const GET_REMEMBER = 'GET_REMEMBER'
export const GET_REMEMBER_SUCCESS = 'GET_REMEMBER_SUCCESS'
export const GET_REMEMBER_FAIL = 'GET_REMEMBER_FAIL'

export const GET_PROVINCE = 'GET_PROVINCE'
export const GET_PROVINCE_SUCCESS = 'GET_PROVINCE_SUCCESS'
export const GET_PROVINCE_FAIL = 'GET_PROVINCE_FAIL'

export const SEND_LINK_ACTIVE_EMAIL = 'SEND_LINK_ACTIVE_EMAIL'
export const SEND_LINK_ACTIVE_EMAIL_SUCCESS = 'SEND_LINK_ACTIVE_EMAIL_SUCCESS'
export const SEND_LINK_ACTIVE_EMAIL_FAIL = 'SEND_LINK_ACTIVE_EMAIL_FAIL'

export const UPDATE_REFCODE_AGENCY = 'UPDATE_REFCODE_AGENCY'

export const GET_URL_INVITE_OF_LINK_ID = 'GET_URL_INVITE_OF_LINK_ID'


export const UPDATE_ACCESS_TOKEN = 'UPDATE_ACCESS_TOKEN'
export const SUBMIT_ACTIVE_EMAIL = 'SUBMIT_ACTIVE_EMAIL'
export const SUBMIT_ACTIVE_EMAIL_SUCCESS = 'SUBMIT_ACTIVE_EMAIL_SUCCESS'
export const SUBMIT_ACTIVE_EMAIL_FAIL = 'SUBMIT_ACTIVE_EMAIL_FAIL'

export const GET_AGENT_BANNER_LIST = 'GET_AGENT_BANNER_LIST'
export const GET_AGENT_BANNER_LIST_SUCCESS = 'GET_AGENT_BANNER_LIST_SUCCESS'
export const GET_AGENT_BANNER_LIST_FAIL = 'GET_AGENT_BANNER_LIST_FAIL'
