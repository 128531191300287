import { API_URL } from '../../configs/api.config'
import Request from '../middleware/request'
// import { BannerData } from '../../configs/constants'

const accountsAPI = {

  postAccountForgotPassword(args) {
    return Request.callAPI('post', `${API_URL}/account/forgotPassword`, { ...args })
  },
  postAccountLogin(args) {
    return Request.callAPI('post', `${API_URL}/account/login`, { ...args })
  },
  postAccountLoginStep2(args) {
    return Request.callAPI('post', `${API_URL}/account/login-step2`, { ...args })
  },
  postAccountLogout(args) {
    return Request.callAPI('post', `${API_URL}/account/logout`, { ...args })
  },
  postAccountRegister(args) {
    return Request.callAPI('post', `${API_URL}/V1/affiliate/register`, { ...args })
  },
  getAccountGetProfile(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/account/getProfile`, { ...args }, true)
  },
  postAccountChangePass(args) {
    return Request.callAPIWithAccessToken('post', `${API_URL}/account/changePassword`, { ...args }, true)
  },
  postAccountNewPass(args) {
    return Request.callAPIWithAccessToken('post', `${API_URL}/account/newPassword`, { ...args }, true)
  },
  commissionConfig() {
  },
  accountGetBalance(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/account/getBalance`, { ...args }, false)
  },
  getAgencyList(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/account/getAgencyList`, { ...args }, false)
  },
  getoverview(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/report/overview`, { ...args }, false)
  },
  getRegisterAgencyList(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/account/getRegisterAgencyList`, { ...args }, false)
  },
  getBank(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/withdraw/getBank`, { ...args }, false)
  },
  getProvince(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/withdraw/getProvince`, { ...args }, false)
  },
  getRemember(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/withdraw/getRemember`, { ...args }, false)
  },
  getAgentBannerList() {
    // return new Promise((res, rej) => {
    //   res({ code: 1000, data: BannerData })
    //   // eslint-disable-next-line no-constant-condition
    //   if (false) {
    //     rej()
    //   }
    // })
    return Request.callAPI('get', `${API_URL}/campaign-list`, {})
  },
  confirmApproveAgency(args) {
    return Request.callAPIWithAccessToken('post', `${API_URL}/agency/confirmApproveAgency`, { ...args }, false)
  },
  getUserListByAgency(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/account/getUserListByAgency`, { ...args }, false)
  },
  getCommissionConfigAgency(args) {
    return Request.callAPIWithAccessToken('post', `${API_URL}/getCommissionConfigAgency`, { ...args }, false)
  },
  updateConfigCommisstionAgency(args) {
    return Request.callAPIWithAccessToken('post', `${API_URL}/updateConfigCommisstionAgency`, { ...args }, false)
  },
  confirmBanAgency(args) {
    return Request.callAPIWithAccessToken('post', `${API_URL}/agency/confirmBanAgency`, { ...args }, false)
  },
  reportGeneral(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/report/general`, { ...args }, false)
  },
  reportGetChartMember(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/report/getChartMember`, { ...args }, false)
  },
  reportGetChartRevenue(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/report/getChartRevenue`, { ...args }, false)
  },
  withdrawHistory(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/withdraw/history`, { ...args }, false)
  },
  withdraw(args) {
    return Request.callAPIWithAccessToken('post', `${API_URL}/withdraw`, { ...args }, false)
  },
  reportRevenue(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/report/revenue`, { ...args }, false)
  },
  sendLinkActiveEmail(args) {
    return Request.callAPIWithAccessToken('get', `${API_URL}/account/active`, { ...args }, true)
  },
  getUrlInviteOfLinkId(args) {
    console.log('args', args)
    return Request.callAPIWithAccessToken('get', `${API_URL}/account/getUrlInviteOfLinkId`, { ...args })
  }
}

export default accountsAPI
