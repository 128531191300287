
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'
import * as GeneralAction from '../../redux/general/general.actions'
import ComponentExtend from '../ComponentExtend'

import List from './List'

class ListContainer extends ComponentExtend {
  static contextTypes = {
    t: PropTypes.func.isRequired
  }

  constructor(prop) {
    super(prop)
    const params = queryString.parse(this.props.location.search)
    const { page = 1 } = params
    this.state = {
      page: Number(page)
    }
    this.updateChangePage = this.updateChangePage.bind(this)
  }

  componentWillMount() {
    const { generalAct } = this.props
    generalAct.promotionList({
      limit: this.limit,
      page: this.state.page
    }, false)
  }

  updateChangePage() {
    const params = queryString.parse(this.props.location.search)
    const { page = 1 } = params
    if (this.state.page !== Number(page)) {
      this.setState({
        page: Number(page)
      })
      const { generalAct } = this.props
      generalAct.promotionList({
        limit: this.limit,
        page: Number(page)
      }, false)
    }
  }

  componentDidUpdate() {
    this.updateChangePage()
  }

  render() {
    return (
      <List
        listPromotion={this.props.listPromotion}
        page={this.state.page}
      />
    )
  }
}

function mapsDispatchToProp(dispatch) {
  return {
    generalAct: bindActionCreators(GeneralAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    listPromotion: rootState.generalReducer.listPromotion
  }
}, mapsDispatchToProp)(ListContainer)
