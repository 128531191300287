import React from 'react'

const Register = (locals) => {
  const hasError = locals.hasError === true ? 'has-error' : ''
  const msg = locals.error ? <span className="help-block error-block">{locals.error}</span> : ''
  const cls = locals.value ? 'checkbox checked' : 'checkbox'

  return (
    <div className={`eform-input form-group eform-checkbox-${locals.attrs.name} ${hasError} row`}>
      <div className="eform-label col-12 col-lg-4 nopadding">&nbsp;</div>
      <div className="eform-desc col-12 col-lg-8 nopadding">
        <div className={cls} checked>
          <label htmlFor={locals.attrs.id}>
            <input
              type="checkbox"
              className="eform-checkbox-item"
              onChange={event => locals.onChange(event.target.checked)}
              {...locals.attrs}
            />
            &nbsp;&nbsp;&nbsp;
            <span dangerouslySetInnerHTML={{ __html: locals.label }} />
          </label>
          {msg}
        </div>
      </div>
      <div className="clearfix" />
    </div>
  )
}

export default Register
