import React from 'react'
import numeral from 'numeral'
import Modal from 'react-modal'
import _ from 'lodash'
// import md5 from 'md5'
import moment from 'moment'
// import DateRange from '../blocks/dateRange/DateRange'
import MonthFilter from '../blocks/MonthFilter'
import Breadcrumb from '../blocks/breadcrumb/Breadcrumb'
import ComponentExtend from '../ComponentExtend'
import Pagination from '../blocks/pagination/pagination'

class Profile extends ComponentExtend {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        month: moment().format('MM/YYYY'),
        searchUser: '',
        page: 1
      },
      formDataConfigCommission: [
        {
          minProfit: 0,
          maxProfit: 0,
          numMember: 0,
          commission: 0
        },
        {
          minProfit: 0,
          maxProfit: 0,
          numMember: 0,
          commission: 0
        },
        {
          minProfit: 0,
          maxProfit: 0,
          numMember: 0,
          commission: 0
        }
      ],
      showModalConfigCommission: false,
      itemConfigCommssion: {},
      showModalLock: false,
      itemLock: {},
      showModalUnlock: false,
      itemUnlock: {},
      errorChangeForm: ''
    }
    this.onSubmitSearchAgency = this.onSubmitSearchAgency.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.showModal = this.showModal.bind(this)
    this.callBackLockAndUnlock = this.callBackLockAndUnlock.bind(this)
    this.submitLockOrUnlock = this.submitLockOrUnlock.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmitConfigCommission = this.onSubmitConfigCommission.bind(this)
    this.callBackUpdateConfig = this.callBackUpdateConfig.bind(this)
    this.callBackGetConfigCommission = this.callBackGetConfigCommission.bind(this)
  }

  componentWillMount() {
    this.props.getListAgency(this.state.formData.month, 1, this.limit)
  }

  onSubmitSearchAgency(event) {
    event.preventDefault()
    const formData = {}
    for (let i = 0; i < event.target.elements.length; i++) {
      if (event.target[i].name) {
        formData[event.target[i].name] = event.target[event.target[i].name].value
      }
    }
    this.setState({
      formData: { ...formData, page: 1 }
    })
    this.props.getListAgency(formData.month, 1, this.limit, formData.searchUser)
  }

  updatePage() {
    const { formData } = this.state
    this.setState({
      formData: { ...formData, page: this.props.page }
    })
    this.props.getListAgency(formData.month, this.props.page, this.limit, this.state.searchUser)
  }

  componentDidUpdate() {
    if (this.state.formData.page !== this.props.page) {
      this.updatePage()
    }
  }

  onSubmitConfigCommission(event) {
    event.preventDefault()
    let flag = true
    this.state.formDataConfigCommission.map((item) => {
      if (!_.isNumber(Number(item.minProfit))
        || (item.maxProfit !== null && !_.isNumber(Number(item.maxProfit)))
        || !_.isNumber(Number(item.commission))
        || !_.isNumber(Number(item.numMember))) {
        flag = false
      }
      return true
    })
    if (flag === false) {
      alert(this.l('Data invalid'))
    } else {
      this.props.updateConfigCommisstionAgency(this.state.itemConfigCommssion.accountId, this.state.formDataConfigCommission, this.callBackUpdateConfig)
    }
  }

  callBackUpdateConfig(status, data) {
    if (status) {
      alert(this.l('Success'))
      this.setState({
        showModalConfigCommission: false
      })
    } else {
      alert(data.message)
    }
  }

  closeModal(value) {
    if (value === 'commission') {
      this.setState({
        showModalConfigCommission: false
      })
    }
    if (value === 'lock') {
      this.setState({
        showModalLock: false
      })
    }
    if (value === 'unlock') {
      this.setState({
        showModalUnlock: false
      })
    }
  }

  callBackGetConfigCommission(status, data) {
    if (status) {
      this.setState({
        formDataConfigCommission: data.data
      })
    }
  }

  showModal(value, item) {
    if (value === 'commission') {
      this.props.getCommissionConfigAgency(item.accountId, this.callBackGetConfigCommission)
      this.setState({
        errorChangeForm: '',
        showModalConfigCommission: true,
        itemConfigCommssion: item
      })
    }
    if (value === 'lock') {
      this.setState({
        itemLock: item,
        showModalLock: true
      })
    }
    if (value === 'unlock') {
      this.setState({
        itemUnlock: item,
        showModalUnlock: true
      })
    }
  }

  submitLockOrUnlock(accountId, isBanned) {
    if (isBanned) {
      this.setState({
        showModalLock: false
      })
    } else {
      this.setState({
        showModalUnlock: false
      })
    }
    this.props.lockOrUnlockAgency(accountId, isBanned, this.callBackLockAndUnlock)
  }

  callBackApi(status, value, data) {
    if (status) {
      alert(this.l('Thành công'))
      this.closeModal(value)
    } else {
      this.setState({
        errorChangePass: data.message
      })
    }
  }

  callBackLockAndUnlock(status, data) {
    if (status) {
      const { formData } = this.state
      this.props.getListAgency(formData.fromDate, formData.toDate, this.props.page, this.limit, formData.searchUser)
    } else {
      alert(data.message)
    }
  }

  handleChange(index, name, event) {
    const formData = this.state.formDataConfigCommission
    if (name === 'commission' && event.target.value === '0.') {
      formData[index][name] = event.target.value
      this.setState({
        formDataConfigCommission: [...formData]
      })
    } else {
      let value = 0
      if (Number(event.target.value)) {
        value = Number(event.target.value)
      }
      if (_.isNumber(value) && Number(value) >= 0) {
        if (value > 1 && name === 'commission') {
          console.log('Commission invalid')
        } else {
          formData[index][name] = value
          this.setState({
            formDataConfigCommission: [...formData]
          })
        }
      }
    }
  }

  renderModalCommission() {
    const formData = this.state.formDataConfigCommission
    return (
      <Modal
        className="modal-afterLogin affter modal-config-agency block-content-afterlogin"
        isOpen={this.state.showModalConfigCommission}
        ariaHideApp={false}
      >
        <div className="modal-header-custom head-afterlogin">
          <div className="">{this.l('Cấu hình đại lý')}</div>
          <div className="btn-close" onClick={() => this.closeModal('commission')} role="presentation"><i className="fa fa-times" /></div>
        </div>
        <hr />
        <div className="contain-block-afterlogin">
          <form className="form-horizontal" onSubmit={this.onSubmitConfigCommission}>
            <div className="form-group text-center">
              <div className="col-sm-7" dangerouslySetInnerHTML={{ __html: this.l('Doanh thu đặt<br> được trong tháng') }} />
              <div className="col-sm-3">{this.l('Thành viên hoạt động tối thiểu trong tháng')}</div>
              <div className="col-sm-2">{this.l('Tỉ lệ % hoa hồng')}</div>
            </div>
            <div className="form-group">
              <div className="col-sm-7 ">
                <div className="cls-record-input-config-agency row">
                  <span>{this.l('Doanh số bắt đầu từ')}</span>
                  <span className="text-center">:</span>
                  <div>
                    <input type="hidden" value="0" name="config[][minProfit]" />
                    <input type="text" className="form-control" placeholder={this.l('Nhập số tiền VND')} onChange={(event) => { this.handleChange(0, 'maxProfit', event) }} value={formData[0].maxProfit} />
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <input type="text" className="form-control text-center" placeholder="00" onChange={(event) => { this.handleChange(0, 'numMember', event) }} value={formData[0].numMember} />
              </div>
              <div className="col-sm-2">
                <input type="text" className="form-control text-center" placeholder="00" onChange={(event) => { this.handleChange(0, 'commission', event) }} value={formData[0].commission} />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-7 ">
                <div className="cls-record-input-config-agency row">
                  <div>
                    <input type="text" className="form-control" placeholder={this.l('Nhập số tiền VND')} onChange={(event) => { this.handleChange(1, 'minProfit', event) }} value={formData[1].minProfit} />
                  </div>
                  <span className="text-center"><i className="fa fa-arrow-right" aria-hidden="true" /></span>
                  <div>
                    <input type="text" className="form-control" placeholder={this.l('Nhập số tiền VND')} onChange={(event) => { this.handleChange(1, 'maxProfit', event) }} value={formData[1].maxProfit} />
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <input type="text" className="form-control text-center" placeholder="00" onChange={(event) => { this.handleChange(1, 'numMember', event) }} value={formData[1].numMember} />
              </div>
              <div className="col-sm-2">
                <input type="text" className="form-control text-center" placeholder="00" onChange={(event) => { this.handleChange(1, 'commission', event) }} value={formData[1].commission} />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-7 ">
                <div className="cls-record-input-config-agency row">
                  <span>{this.l('Doanh số vượt qua')}</span>
                  <span className="text-center">:</span>
                  <div>
                    <input type="text" className="form-control" placeholder={this.l('Nhập số tiền VND')} onChange={(event) => { this.handleChange(2, 'minProfit', event) }} value={formData[2].minProfit} />
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <input type="text" className="form-control text-center" placeholder="00" onChange={(event) => { this.handleChange(2, 'numMember', event) }} value={formData[2].numMember} />
              </div>
              <div className="col-sm-2">
                <input type="text" className="form-control text-center" placeholder="00" onChange={(event) => { this.handleChange(2, 'commission', event) }} value={formData[2].commission} />
              </div>
            </div>
            <hr />
            <div className="form-group" style={{ display: (this.state.errorChangeForm === '') ? 'none' : 'block' }}>
              <label htmlFor="inputPassword3" className="col-sm-4 control-label">&nbsp;</label>
              <div className="col-sm-8 error-block">{this.state.errorChangePass}</div>
            </div>
            <div className="form-group">
              <center className="col-sm-12">
                <button type="submit" className="btn btn-primary">{this.l('Lưu cấu hình')}</button>
                <button type="button" className="btn btn-default" onClick={() => this.closeModal('commission')}>{this.l('Hủy')}</button>
              </center>
            </div>
          </form>
        </div>
      </Modal>
    )
  }

  renderModalLockAgency2() {
    const item = this.state.itemLock
    return (
      <Modal
        className="modal-afterLogin affter modal-lock-agency block-content-afterlogin"
        isOpen={this.state.showModalLock}
        ariaHideApp={false}
      >
        <div className="modal-header-custom head-afterlogin text-center">
          <div className="">{this.l('Khóa quyền đại lý')} {item.displayName}</div>
          <div className="btn-close" onClick={() => this.closeModal('lock')} role="presentation"><i className="fa fa-times" /></div>
        </div>
        <hr />
        <div className="contain-block-afterlogin">
          <center>
            <img src="/assets/img/img-key-lock.png" alt="{this.l('lock user')}" />
            <p dangerouslySetInnerHTML={{ __html: this.l('text-desc-lock-popup') }} />
          </center>
          <hr />
          <div className="form-group">
            <center className="col-sm-12">
              <button type="button" className="btn btn-primary" onClick={() => this.submitLockOrUnlock(item.accountId, true)}>{this.l('Khóa')}</button>
              <button type="button" className="btn btn-default" onClick={() => this.closeModal('lock')}>{this.l('Không')}</button>
            </center>
          </div>
        </div>
      </Modal>
    )
  }

  renderModalUnLockAgency2() {
    const item = this.state.itemUnlock
    return (
      <Modal
        className="modal-afterLogin affter modal-lock-agency block-content-afterlogin"
        isOpen={this.state.showModalUnlock}
        ariaHideApp={false}
      >
        <div className="modal-header-custom head-afterlogin">
          <div className="text-center">{this.l('Mở khóa quyền đại lý')} {item.accountI}</div>
          <div className="btn-close" onClick={() => this.closeModal('unlock')} role="presentation"><i className="fa fa-times" /></div>
        </div>
        <hr />
        <div className="contain-block-afterlogin">
          <center>
            <img src="/assets/img/img-key-unlock.png" alt="{this.l('un lock user')}" />
            <p dangerouslySetInnerHTML={{ __html: this.l('text-desc-un-lock-popup') }} />
          </center>
          <hr />
          <div className="form-group">
            <center className="col-sm-12">
              <button type="submit" className="btn btn-primary" onClick={() => { this.submitLockOrUnlock(item.accountId, false) }}>{this.l('Mở khóa')}</button>
              <button type="button" className="btn btn-default" onClick={() => this.closeModal('unlock')}>{this.l('Không')}</button>
            </center>
          </div>
        </div>
      </Modal>
    )
  }

  onChangeMonth = (value) => {
    const formData = { ...this.state.formData }
    this.setState({
      formData: {
        ...formData,
        month: value
      }
    })
  }

  render() {
    const { page = 1 } = this.props
    // const maxDate = moment().format('YYYY-MM-DD')
    const { agencyList } = this.props
    const { data = [], totalRecord = 0, totalInfo = {} } = agencyList
    return (
      <div className="cls-aflg-profile row">
        <Breadcrumb arrBreadcrumb={[{ title: this.l('Danh sách đại lý cấp 2') }]} />
        <div className="row">
          <div className="col-md-12 nopadding">
            <div className="cls-block-info-commisstion block-content-afterlogin">
              <div className="head-afterlogin">
                <span>{this.l('Danh sách đại lý cấp 2')}</span>
                <form className="header-afterlogin-form" onSubmit={this.onSubmitSearchAgency}>
                  <input type="text" name="searchUser" placeholder={this.l('User ID, Email')} />
                  <MonthFilter onChange={this.onChangeMonth} />
                  <button type="submit" className="btn btn-primary-cs">{this.l('Xem')}</button>
                </form>
              </div>
              <div className="contain-block-afterlogin">
                <div className="table-reponsive">
                  <table className="table cls-list-agency2">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{this.l('Đại lý')}</th>
                        <th scope="col">{this.l('Điện thoại')}</th>
                        <th scope="col">{this.l('Email')}</th>
                        <th scope="col" dangerouslySetInnerHTML={{ __html: this.l('Số thành viên <br> (hoạt động / tổng)') }} />
                        <th scope="col" dangerouslySetInnerHTML={{ __html: this.l('Doanh thu') }} />
                        {/* <th scope="col" dangerouslySetInnerHTML={{ __html: this.l('Thao tác') }} /> */}
                      </tr>
                    </thead>
                    <tbody>
                      {(data.length > 0) ? (
                        data.map((item, k) => {
                          const key = k
                          if (item.isBanned) {
                            return (
                              <tr className="haslock" key={key}>
                                <td><span>{item.displayName}</span></td>
                                <td>{item.phone}</td>
                                <td><span>{item.email}</span></td>
                                <td>{numeral(item.numMemberActive).format('0,0')}/{numeral(item.totalMember).format('0,0')}</td>
                                <td>{numeral(item.revenue).format('0,0')}</td>
                                {/* <td style={{ whiteSpace: 'nowrap' }}>
                                  <button className="btn btn-success btn-xs" type="button" disabled><i className="fa fa-cog" aria-hidden="true" /> {this.l('Cấu hình')}</button>
                                  <button className="btn btn-primary btn-xs" type="button" onClick={() => this.showModal('unlock', item)}><i className="fa fa-unlock" aria-hidden="true" /> {this.l('Mở khóa')}</button>
                                </td> */}
                              </tr>
                            )
                          }
                          return (
                            <tr key={key}>
                              <td><span>{item.displayName}</span></td>
                              <td>{item.phone}</td>
                              <td><span>{item.email}</span></td>
                              <td>{numeral(item.numMemberActive).format('0,0')}/{numeral(item.totalMember).format('0,0')}</td>
                              <td>{numeral(item.revenue).format('0,0')}</td>
                              {/* <td style={{ whiteSpace: 'nowrap' }}>
                                <button className="btn btn-success btn-xs" type="button" onClick={() => this.showModal('commission', item)}><i className="fa fa-cog" aria-hidden="true" /> {this.l('Cấu hình')}</button>
                                <button className="btn btn-danger btn-xs" type="button" onClick={() => this.showModal('lock', item)}><i className="fa fa-lock" aria-hidden="true" /> {this.l('Khóa quyền')}</button>
                              </td> */}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan="8" className="empty-record">{this.l('Empty record')}</td>
                        </tr>
                      )}
                      <tr className="last_row_table">
                        <td colSpan="3">{this.l('Tổng cộng')}</td>
                        <td>{numeral(totalInfo.numMemberActive).format('0,0')}/{numeral(totalInfo.totalMember).format('0,0')}</td>
                        <td>{numeral(totalInfo.revenue).format('0,0')} {this.l('VND')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <center>
                  <Pagination
                    urlPage="/members"
                    total_row={totalRecord}
                    page={page}
                    limit={this.limit}
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
        {this.renderModalCommission()}
        {this.renderModalLockAgency2()}
        {this.renderModalUnLockAgency2()}
      </div>
    )
  }
}

export default Profile
