
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import * as AccountsAction from '../../redux/accounts/accounts.actions'
import Dashboard1 from './Dashboard1'
import Dashboard2 from './Dashboard2'
import ComponentExtend from '../ComponentExtend'

class DashboardContainer extends ComponentExtend {
  constructor(prop) {
    super(prop)
    this.state = {}
    this.submitSearchAgency = this.submitSearchAgency.bind(this)
    this.getListUser = this.getListUser.bind(this)
    this.confirmAproveAgency = this.confirmAproveAgency.bind(this)
  }

  componentWillMount() {
    const { accountAct } = this.props
    accountAct.getOverview({})
    accountAct.getBalance()
  }

  submitSearchAgency(fromDate, toDate, page, limit, status) {
    const { accountAct, userInfo } = this.props
    if (userInfo.typeAgency === 1) {
      const params = {
        fromDate,
        toDate,
        page,
        limit
      }
      if (Number(status)) {
        params.status = Number(status)
      }
      accountAct.getRegisterAgencyList(params)
    }
  }

  getListUser(month, page, limit, searchText) {
    const data = {
      month,
      page,
      limit
    }
    if (searchText !== '') {
      data.searchText = searchText
    }
    const { accountAct } = this.props
    accountAct.getUserListByAgency(data)
  }

  confirmAproveAgency(accountId, approveStatus, callBack) {
    const { accountAct, userInfo } = this.props
    if (userInfo.typeAgency === 1) {
      accountAct.confirmAproveAgency({ accountId, approveStatus }, callBack)
    }
  }

  render() {
    const { userInfo: { typeAgency = 2 } } = this.props
    if (typeAgency === 1) {
      return (
        <Dashboard1
          userInfo={this.props.userInfo}
          agencyList={this.props.agencyList}
          overview={this.props.overview}
          registerAgencyList={this.props.registerAgencyList}
          submitSearchAgency={this.submitSearchAgency}
          confirmAproveAgency={this.confirmAproveAgency}
        />
      )
    }
    const params = queryString.parse(this.props.location.search)
    const { page = 1 } = params
    return (
      <Dashboard2
        userInfo={this.props.userInfo}
        overview={this.props.overview}
        page={Number(page)}
        getListUser={this.getListUser}
        userListByAgency={this.props.userListByAgency}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accountAct: bindActionCreators(AccountsAction, dispatch)
  }
}

export default connect((rootState) => {
  return {
    userInfo: rootState.accountsReducer.userInfo,
    commissionConfig: rootState.accountsReducer.commissionConfig,
    registerAgencyList: rootState.accountsReducer.registerAgencyList,
    overview: rootState.accountsReducer.overview,
    userListByAgency: rootState.accountsReducer.userListByAgency
  }
}, mapDispatchToProps)(DashboardContainer)
