import * as types from './accounts.types'

export function postAccountForgotPassword(payload, callback) {
  return {
    type: types.POST_ACCOUNT_FORGOT_PASSWORD,
    payload,
    callback
  }
}

export function postAccountNewPassword(payload, callback) {
  return {
    type: types.POST_ACCOUNT_NEW_PASS,
    payload,
    callback
  }
}

export function sendLinkActiveEmail(payload, callback) {
  return {
    type: types.SEND_LINK_ACTIVE_EMAIL,
    payload,
    callback
  }
}

export function cleanSendLinkActiveEmail(payload, callback) {
  return {
    type: types.SEND_LINK_ACTIVE_EMAIL_FAIL,
    payload,
    callback
  }
}

export function postAccountLogin(payload, callback) {
  return {
    type: types.POST_ACCOUNT_LOGIN,
    payload,
    callback
  }
}

export function postAccountLoginStep2(payload, callback) {
  return {
    type: types.POST_ACCOUNT_LOGIN_STEP2,
    payload,
    callback
  }
}

export function postAccountLogout(payload, callback) {
  return {
    type: types.POST_ACCOUNT_LOGOUT,
    payload,
    callback
  }
}

export function postAccountRegister(payload, callback) {
  return {
    type: types.POST_ACCOUNT_REGISTER,
    payload,
    callback
  }
}

export function commissionConfig(payload, callback) {
  return {
    type: types.COMMISSION_CONFIG,
    payload,
    callback
  }
}

export function getProfile(payload, callback) {
  return {
    type: types.POST_ACCOUNT_GET_PROFILE,
    payload,
    callback
  }
}

export function changePass(payload, callback) {
  return {
    type: types.POST_ACCOUNT_CHANGE_PASS,
    payload,
    callback
  }
}
// lấy danh sách đại lý cấp 2 paload ={fromDate, toDate, page, limit}
export function getAgencyList(payload, callback) {
  return {
    type: types.GET_AGENCY_LIST,
    payload,
    callback
  }
}
// ========== trang điều kiển =================

// lấy danh sách đại lý cấp 2 vừa đăng ký nhưng chưa approve paload ={fromDate, toDate, page, limit}
export function getRegisterAgencyList(payload, callback) {
  return {
    type: types.GET_REGISTER_AGENCY_LIST,
    payload,
    callback
  }
}

// lấy danh sách đại lý cấp 2 vừa đăng ký nhưng chưa approve paload ={accountId, approveStatus}
export function confirmAproveAgency(payload, callback) {
  return {
    type: types.CONFIRM_APPROVE_AGENCY,
    payload,
    callback
  }
}

// lấy danh tổng quan dashboard paload ={}
export function getOverview(payload, callback) {
  return {
    type: types.GET_OVERVIEW,
    payload,
    callback
  }
}

// lấy danh tổng quan dashboard paload ={fromDate, toDate, page, limit}
export function getUserListByAgency(payload, callback) {
  return {
    type: types.GET_USER_LIST_BY_AGENCY,
    payload,
    callback
  }
}

/** ========= trang danh sách đại lý cấp 2 =========== */

// lấy cấu hình hoa hồng của đại lý paload ={accountId}
export function getCommissionConfigAgency(payload, callback) {
  return {
    type: types.GET_COMMISSION_CONFIG_AGENCY,
    payload,
    callback
  }
}

// lấy cấu hình hoa hồng của đại lý paload ={accountId, data: [{minProfit: 0, maxProfit: 2000, commission: 0.3, numMember: 5}, {minProfit: 0, maxProfit: 2000, commission: 0.3, numMember: 5}]}
export function updateConfigCommissionAgency(payload, callback) {
  return {
    type: types.UPDATE_CONFIG_COMMISSION_AGENCY,
    payload,
    callback
  }
}
// lấy cấu hình hoa hồng của đại lý paload ={accountId, isBan}
export function confirmBanedAgency(payload, callback) {
  return {
    type: types.CONFIRM_BAN_AGENCY,
    payload,
    callback
  }
}

/** =============== thống kê =========== */
// lấy cấu hình hoa hồng của đại lý paload ={month}
export function reportGeneral(payload, callback) {
  return {
    type: types.REPORT_GENERAL,
    payload,
    callback
  }
}

// chart member paload ={searchText, fromDate, toDate }
export function reportGetChartMember(payload, callback) {
  return {
    type: types.REPORT_GET_CHART_MEMBER,
    payload,
    callback
  }
}

// chart revenue paload ={searchText, fromDate, toDate }
export function reportGetChartRevenue(payload, callback) {
  return {
    type: types.REPORT_GET_CHART_REVENUE,
    payload,
    callback
  }
}

// bangr thống kee paload ={searchText, fromDate, toDate, limit, page }
export function reportRevenue(payload, callback) {
  return {
    type: types.REPORT_REVENUE,
    payload,
    callback
  }
}

/** ============ WITHDRAW========== */

// lấy lịch sử rút tiền paload ={limit, page, fromDate, toDate }
export function withdrawHistory(payload, callback) {
  return {
    type: types.WITHDRAW_HISTORY,
    payload,
    callback
  }
}

// submit rut tien paload ={bank, province, city, bankAccount, bankNumber, amount, remember }
export function withdraw(payload, callback) {
  return {
    type: types.WITHDRAW,
    payload,
    callback
  }
}

// submit rut tien paload ={bank, province, city, bankAccount, bankNumber, amount, remember }
export function getBalance(payload, callback) {
  return {
    type: types.ACCOUNT_GET_BALANCE,
    payload,
    callback
  }
}

// lấy danh sách ngân hàng
export function getBank(payload, callback) {
  return {
    type: types.GET_BANK,
    payload,
    callback
  }
}

// lấy danh sách Banner
export function getAgentBannerList() {
  return {
    type: types.GET_AGENT_BANNER_LIST
  }
}

// lấy danh sách tỉnh thành phố
export function getProvince(payload, callback) {
  return {
    type: types.GET_PROVINCE,
    payload,
    callback
  }
}

// lấy ghi nhớ
export function getRemember(payload, callback) {
  return {
    type: types.GET_REMEMBER,
    payload,
    callback
  }
}

// update refcode agnecy payload ={refcode}
export function updateRefcodeAgency(payload, callback) {
  return {
    type: types.UPDATE_REFCODE_AGENCY,
    payload,
    callback
  }
}

// update refcode agnecy payload ={linkId}
export function getUrlInviteOfLinkId(payload, callback) {
  return {
    type: types.GET_URL_INVITE_OF_LINK_ID,
    payload,
    callback
  }
}

// update refcode agnecy payload ={linkId}
export function submitActiveEmail(payload, callback) {
  return {
    type: types.SUBMIT_ACTIVE_EMAIL,
    payload,
    callback
  }
}
