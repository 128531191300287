export default {
  /* HEADER AREA */
  CommissionPolicy: 'Commission policy',
  Promostion: 'News',
  Terms: 'Terms & Conditions',
  faq: 'FAQ',
  ContactUs: 'Contact Us',
  Login: 'Login',
  ForgotPassword: 'Forgot password',
  RegisterPartner: 'Register',
  NewPassword: 'New Password',
  RegisterNew: 'Register',
  Register: 'Register',
  Logout: 'Logout',
  Hello: 'Hello,',
  ManagePage: 'Agent ',
  Promotion: 'Promotion',


  /* HOME PAGE */
  WhatIsAlo88Club: 'What is Alo88?',
  block2Text: 'Alo88 is a traditional Vietnamese game\'s portal with many attract games and tournaments.',
  DownloadGame: 'Download',
  HomePage: 'Home',

  block3Title: 'Why is Alo88?',
  block3Text: 'Alo88 has profit base on RAKE system, so your earn profit every month.',
  block3Text2: 'Alo88 has an "easy to use" affiliate control panel.',
  block3Text3: 'Alo88 always have promotions to attract players.',
  block3Text4: 'Alo88 has a proffesional CS system and always help users solve their problems "swiftly and smoothly". You just need to bring your users, we will take good care of them.',
  block3Text5: 'Minh bạch thu nhập hoa hồng mỗi tháng thông qua hỗ trợ theo dõi thu nhập theo thời gian thực 24/7. Đảm bảo an toàn bảo mật danh tính cho đại lý thông qua các hình thức thanh toán bằng tiền điện tử',
  block3Text6: 'Alo88 có đội ngũ chăm sóc khách hàng cực kỳ chuyên nghiệp. Mọi vấn đề hỗ trợ người chơi sẽ được đảm trách bởi đội ngũ nhân viên của Alo88. Bạn chỉ việc kê cao gối ngủ ngon',

  block4Title: 'Benefits when joining with us!',
  block4Text: 'Commission percentage rate up to 80%',
  block4Text2: 'You always earn profit regardless of your user\'s winloss.',
  block4Text3: 'Become millionare without investment',
  block4Text4: 'Sản phẩm đa dạng thu hút được mọi người chơi',
  block4Text5: 'Được thanh toán linh hoạt và bảo mật tối đa',

  block5Title: 'Commission plan',
  block5Text: 'Alo88 is TOP game portal, you can register to become an affiliate easily and start to earn profit without any investment in money.',
  ReadMore: 'Find out more ...',
  CommissionEstimate: 'Commission calculation',
  netProfit: 'Net profit',
  memberNumber: 'Members:',
  estimateCom: 'Total commission:',
  count: 'Count now!',

  block6Title: 'Promotion',
  Details: 'Details',
  More: 'More ...',

  block7Title: 'FAQ',
  block7TabLabel: '1. Why join Alo88?',
  block7TabLabel2: '2. How to become an affiliate?',
  block7TabLabel3: '3. How to earn net profit?',
  block7TabLabel4: '4. Advertise',
  block7TabLabel5: '5. How to earn commission',

  block8Title: 'Contact us',
  block8Text: 'If you have any question please don\'t hesitate to contact us via mobile phone, viber, zalo, telegram:',


  /* REGISTER PAGE */
  Username: 'Username',
  'username/Email': 'Tên truy cập / Email',
  validUsername: 'Input 5-11 characters or number',
  validUsername2: 'Please input username or email',
  password: 'Password',
  validPassword: 'Password must have at least 8-16 characters and numbers.',
  Fullname: 'Full Name',
  validFullname: 'Please input full name',
  Phone: 'Phone number',
  validPhone: 'Phone number must have at least 9-10 numbers, do not input 0 at the beginning.',
  yourEmail: 'Email',
  validEmail: 'Please input your email.',
  Captcha: 'Captcha',
  validCaptcha: 'Please input your captcha.',
  License: 'I\'m over 18 and I agreed with <a target="_blank" href="/pages/dieu-kien-va-dieu-khoan">Alo88 Terms and Condition.</a>',
  validLicense: 'Please check "Agree" before continuing.',
  RefCode: 'Ref Code',
  RefCodePlaceHolder: 'Ref Code (optional)',
  validRefCode: 'Please input Agency Ref Code',

  placeUsername: 'Please input 5-11 characters or numbers.',
  placePassword: 'Please input 8-16 characters or numbers.',
  placeFullname: 'Please use same name in your ID card.',
  placePhone: 'Phone number must have at least 9-10 numbers, do not input 0 at the beginning.',

  compareNewPassErr: 'Incorrect Re-enter Password ',

  refCode: 'RefCode',
  refCodePlaceHolder: 'Reference Code (optional)',

  /* REGISTER SUCCESS PAGE */
  RegisterSuccess: 'Registered.',
  ActiveSuccess: 'Active Success',
  ActiveFail: 'Active Failed',
  Home: 'Home',
  /** =============== */
  'Số lượng thành viền của tôi': 'My members:',
  'Link mời thành viên': 'Member inviting URL',
  src_lang_vi: '/assets/img/flag-vi.jpg',
  src_lang_en: '/assets/img/flag_us.png',
  'Sao chép': 'Copy',
  Xem: 'View',
  'Đồng ý': 'Agree',
  'Từ chối': 'Reject',
  'Đại lý cấp 2': 'Tier 2 Agency',
  'Thành viên đại lý cấp 2': 'Tier 2 agency\'s member',
  'Doanh thu từ thành viên': 'Member\'s revenue',
  'Doanh thu từ đại lý cấp 2': 'Revenue form tier 2 agency',
  'Doanh thu tổng doanh thu': 'Total revenue',
  'Đại lý cấp 2 đăng ký mới': 'New tier 2 agency registered',
  'Mã người dùng': 'User ID',
  'Họ tên': 'Fullname',
  'Số điện thoại': 'Phone number',
  Email: 'Email',
  'Tương tác': 'Active',
  'Số dư:': 'Balance:',
  'Doanh thu tháng:': 'Monthly revenue:',
  Thoát: 'Logout',
  'Bảng điều khiển': 'Dashboard',
  'Danh sách thành viên': 'Member list',
  'Danh sách đại lý cấp 2': 'Tier 2 agancy list',
  'Thống kê': 'Statistic',
  'Rút tiền': 'Withdraw',
  'Xin chào': 'Wellcome',
  'Trang chủ': 'Home',
  'Họ và tên': 'Fullname',
  'Tổng tiền nạp': 'Total deposit',
  'Tổng tiền rút': 'Total withdraw',
  'Số dư hiện tại': 'Current balance',
  'Tổng tiền cược': 'Turn Over',
  'Doanh thu': 'Revenue',
  'Tổng cộng': 'Total',
  'User ID, Email': 'User ID, Email',
  'Đại lý': 'Agency',
  'Điện thoại': 'Phone',
  'Số thành viên <br> (hoạt động / tổng)': 'Total members <br> (active / total)',
  'Tiền thành viên <br> Đã nạp': 'Members total deposit money <br>',
  'Tiền thành viên <br> Đã rút': 'Members total withdraw money <br>',
  'Thao tác': 'Action',
  'Cấu hình': 'Config',
  'Khóa quyền': 'Lock',
  'Mở khóa': 'Unlock',
  VND: 'VND',
  'Số dư trước tháng': 'Last month balance',
  'Thu nhập từ thành viên': 'Member\'s revenue',
  'Tiền thu nhập từ đại lý': 'Agency\'s revenue',
  'Số dư tháng này': 'This month balance',
  'Biểu đồ': 'Chart',
  'Bảng số liệu': 'Data',
  'Số thành viên': 'Members',
  'Số thành viên hoạt động trong tháng': 'Active member of this month',
  'Doanh thu trong tháng': 'Revenue of this month',
  'Số liệu cập nhật lúc': 'Updated time',
  'Doanh số thành viên': 'Member\'s revenue',
  'Thành viên đăng ký mới': 'New register member',
  'Đại lý đăng ký mới': 'New register agency',
  'Số thành viên hoạt động': 'Number members activitive',
  'Tổng thành viên nạp': 'Total members deposit',
  'Tổng thành viên rút': 'Total members withdraw',
  'Doanh số từ thành viên': 'Member\'s revenue',
  'Tổng thành viên đại lý nạp': 'Total members agency deposit',
  'Tổng thành viên đại lý rút': 'Total members agency withdraw',
  'Doanh số từ đại lý': 'Agency\'s revenue',
  'Thu nhập trong tháng': 'Revenue of this month',
  'Số dư khả dụng': 'Available balance',
  'Sao kê tài khoản': 'Account statement',
  'Yểu cầu rút tiền': 'Withdraw request',
  'Ngân hàng nhận': 'Bank',
  Tỉnh: 'Province',
  'Thành phố': 'City',
  'Số tài khoản': 'Account number',
  'Tên tài khoản': 'Account name',
  'Số tiền': 'Amount',
  'Ghi nhớ cho lần tiếp theo': 'Remember for the next time',
  'Gửi yêu cầu': 'Send request',
  'Yêu cầu thanh toán đã được gửi': 'Payment request has been sent',
  'Ngân hàng': 'Bank',
  'Số dư thời điểm': 'Current balance',
  'Mã giao dịch': 'Transaction ID',
  'text-node-result-witdraw': '(*) The transaction resolving time will be depended on the bank, please contact customer support if you have any problem.',
  'Sao chép mã giao dịch': 'Copy transaction ID',
  Đóng: 'Close',
  Số: 'Numbers',
  'Ngày giao dịch': 'Date',
  'Hoạt động': 'Activitives',
  'Số dự trước': 'Balance before',
  'Doanh thu tháng này': 'Revenue of this month',
  'Số thành viên hoạt động tháng này': 'Total members activite this month',
  'Tổng kết doanh thu đại lý': 'Agency revenue summarizing',
  'Tổng tiền đã nạp': 'Total deposit',
  'Tổng tiền đã rút': 'Total withdraw',
  'Báo cáo thu nhập': 'Revenue report',
  'Mở khóa quyền đại lý': 'Unlock agency',
  'text-desc-un-lock-popup': 'Agency can log in to the management page after unlocking. Do you want to unlock this agency?',
  Không: 'No',
  'text-desc-lock-popup': 'Agency can\'t login to the management page after locking. Do you want to lock this agency?',
  'Cấu hình đại lý': 'Agency config',
  'Doanh thu đặt<br> được trong tháng': 'Revenue <br/> of the month',
  'Thành viên hoạt động tối thiểu trong tháng': 'Active member of a month',
  'Tỉ lệ % hoa hồng': '% Commission rate',
  'Doanh số bắt đầu từ': 'Revenue from',
  'Doanh số vượt qua': 'Revenue over',
  'Nhập số tiền VND': 'Enter amount (VND)',
  'Lưu cấu hình': 'Save config',
  Hủy: 'Cancel',
  'Thông tin tài khoản': 'Account infomation',
  'Thông tin cá nhân': 'Personal infomation',
  Tên: 'Name',
  'Ngôn ngữ': 'Language',
  'Thông tin đăng nhập': 'Login info',
  'Tên đăng nhập': 'Username',
  'Mật khẩu': 'Password',
  'Thông tin tỉ lệ hoa hồng': 'Commission rate',
  Mức: 'Level',
  'Tổng lợi nhuận rồng hàng tháng': 'Total monthly net profit',
  'Số thành viên hoạt động mỗi tháng': 'Total monthly active members',
  'Tỉ lệ hoa hồng': 'Commisstion rate',
  'Thay đổi': 'Change',
  'Thay đổi mật khẩu': 'Change password',
  'MK cũ': 'Old password',
  'MK mới': 'New password',
  'Nhập lại MK mới': 'Re-enter new password.',
  'Nhập mật khẩu cũ': 'Enter old password',
  'Nhập mật khẩu mới': 'Enter new password',
  'Nhập lại mật khẩu mới': 'Re-enter new password',
  'Đã sao chép thành công': 'Copied',
  'Tổng doanh thu': 'Total Revenue',
  'Xin chao': 'Wellcome',
  'Link mời đại lý': 'Agency inviting URL',
  'User ID': 'User ID',
  'Khóa quyền đại lý': 'Lock Agency',
  Khóa: 'Lock',
  'Thu thập trong tháng': 'Tax of this month',
  'Số dư tháng trước': 'Balance last month',
  'Tiền đã rút': 'Withdraw money',
  'Hiển thị': 'Show',
  Ngày: 'Date',
  'Tổng tiền thành viên nạp': 'Members total deposit money',
  'Tổng tiền thành viên rút': 'Members total withdraw money',
  'Tổng tiền TV Đại lý nạp': 'Agencies\'s total deposit money',
  'Tổng tiền TV Đại lý rút': 'Agencies\'s total withdraw money',
  'Doanh số từ TV': 'Member\'s revenue',
  'Doanh số': 'Revenue',
  'select bank': 'Select bank',
  'Số dư trước': 'Balance before',
  withdraw: 'withdraw',
  'Data invaid': 'Invalid Ddta',
  username: 'username',
  ok: 'ok',
  'Empty record': 'Empty',
  'Thu nhập ròng': 'Net profit',
  'Doanh thu thành viên': 'Member\'s Revenue',
  'Doanh thu từ đại lý': 'Agency\'s Revenue',
  'Doanh thu từ TV': 'Members\'s Revenue',
  'Live Chat': 'Live Chat',
  'Tất cả': 'All',
  'Đã từ chối': 'Has deny',
  'Đã đồng ý': 'Has accept',
  'Đang chờ': 'Watting',
  'select province': 'Select province/city',
  'Chi nhánh': 'Branch',
  'Data invalid': 'Data invalid',
  'Thành viên hoạt động': 'Member active',
  'Kích hoạt': 'Active',
  'Gửi lại link kích hoạt email': 'Resend Email Active',
  'Nhập mã xác thực': 'Enter captcha',
  'Nhập lại mật khẩu mới không trùng khớp': 'Passwords must match',
  'Vui lòng kiểm tra dữ liệu nhập': 'Plase check data input',
  'before-text-desc-commission': '<p>Hoa hồng là tiền chia doanh thu Lãi có được từ người chơi mà đại lý có được thông qua các link mời người chơi tham gia</p><p>Alo88 cam kết các mức chia doanh thu tối thiểu như chi tiết bên dưới. Nếu các đại lý hoạt động hiệu quả cao hơn mong đợi, các chính sách về tỷ lệ hoa hồng sẽ tăng thêm tuỳ vào hiệu quả kinh doanh của game.</p>',
  Success: 'Success',
  'Trạng thái': 'Status',
  'Bị từ chối': 'Rejected'
}
