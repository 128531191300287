import { API_URL } from '../../configs/api.config'
import Request from '../middleware/request'

const generalAPI = {

  postRegisterClient(args) {
    return Request.callAccountAPI('post', `${API_URL}/register-client`, { ...args })
  },
  postCaptcha(args) {
    return Request.callAPI('post', `${API_URL}/get-captcha`, { ...args })
  },
  faqList(args) {
    return Request.callAPI('post', `${API_URL}/V1/affiliate/faq-list`, { ...args })
  },
  promotionList() {
  },
  promotionDetail(args) {
    return Request.callAPI('post', `${API_URL}/promotion-detail`, { ...args })
  },
  getPage(args) {
    return Request.callAPI('post', `${API_URL}/V1/affiliate/getPage`, { ...args })
  },
  getBannerCategories(args) {
    return Request.callAPI('get', `${API_URL}/V1/affiliate/banner/categories`, { ...args })
  },
  getBanners(args) {
    return Request.callAPI('post', `${API_URL}/V1/affiliate/banners`, { ...args })
  },
  bannerList(args) {
    return Request.callAPI('post', `${API_URL}/banner-list`, { ...args })
  },
  getCommissionEstimate(args) {
    console.log('Call Com Estimate')
    return Request.callAPI('post', `${API_URL}/V1/affiliate/cal-profit`, { ...args })
  }
}

export default generalAPI
