export default {
  /* HEADER AREA */
  CommissionPolicy: 'Chính sách hoa hồng',
  Promostion: 'Tin Tức',
  Terms: 'Điều kiện & điều khoản',
  faq: 'faq',
  ContactUs: 'Liên hệ',
  Login: 'Đăng nhập',
  ForgotPassword: 'Quên mật khẩu',
  NewPassword: 'Đổi mật khẩu',
  RegisterPartner: 'Đăng ký đối tác',
  RegisterNew: 'Đăng ký mới',
  Register: 'Đăng ký',
  Logout: 'Đăng xuất',
  Hello: 'Xin chào',
  ManagePage: 'Trang quản lý',
  Promotion: 'Khuyến mãi',

  /* HOME PAGE */
  WhatIsAlo88Club: 'Alo88 là gì',
  block2Text: `Alo88 là nhà cung cấp tài khoản cược của các nhà cái uy tín hàng đầu thế giới. Đối tác của Alo88 bao gồm nhà cái SV388, SBOBET, Bong88 … Alo88 hoạt động dựa trên nguyên tắc đặt lợi ích của khách hàng lên trên hết, giúp quý khách thỏa mãn đam mê trong khi vẫn có sự bảo đảm an toàn tuyệt đối. Tài khoản do Alo88 cung cấp có thể tham gia đặt cược các sản phẩm như đá gà, thể thao nói chung hay bóng đá nói riêng hoặc sòng bài trực tuyến với các trò chơi thịnh hành được nhiều người yêu thích nhất hiện nay như baccarat, roulette, tài xỉu… Alo88 có giấy phép hoạt động cá cược hợp pháp được cấp bởi tổ chức First Cagayan Leisure & Resort Corporation, tại Philippines. Alo88 mang đến cho các bạn một môi trường giải trí hợp pháp và an toàn thông qua hình thức đăng ký thông tin ngắn gọn, bảo mật, tốc độ nạp rút và nhận tiền nhanh chóng, chính xác.
  `,
  DownloadGame: 'Tải game',
  HomePage: 'Trang chủ',

  block3Title: 'Vì sao nên kinh doanh cùng Alo88',
  block3Text00: '<b>Alo88</b> là nhà bán điểm hỗ trợ các đại lý theo dõi thu nhập theo thời gian thực từ đường link quản lý nhà cái <b>Bong88, SBOBET, SV388</b> riêng biệt.. Khi trở thành đại lý Alo88, đại lý còn có thể xem các thông tin về thành viên mới đăng ký, kết quả vé cược, xem win/loss… Đây là một điểm mạnh hiếm có, không phải nhà cái nào cũng làm được',
  block3Text01: 'Với cơ chế thanh toán linh hoạt cho đại lý VIP. Khi Trở thành đại lý nhà cái <b>Alo88</b> là cơ hội kiếm lợi nhuận tốt mà không cần lo tới vốn hay tính ổn định khách hàng. Đặc biệt những đối tác tiềm năng còn có  quyền lợi yêu cầu ứng tiền hoa hồng trước kỳ thanh toán, đây là lợi ích lớn chưa từng thấy ở các nhà cái khác',
  block3Text: 'Hoàn toàn không cần đóng bất kỳ khoản phí nào. Chỉ riêng điều này giúp bạn không bận tâm về khoản tiền vốn hay băn khoăn việc có bị lừa đảo hay không',
  block3Text2: 'Hoa hồng cực kỳ ưu đãi lên đến 50% chỉ từ 1 thành viên mà không cần bất kỳ cấp bậc và áp dụng cho tất cả sản phẩm, Alo88 khẳng định đây là mức hoa hồng cao nhất Việt Nam vào thời điểm hiện tại',
  block3Text3: 'Alo88 có rất nhiều chương trình khuyến mãi tặng tiền cho người chơi lên đến 100%, không những thế vào các sự kiện lớn trong năm đều đặn đưa ra những ưu đãi đặc biệt nên bạn có nhiều điểm nổi bật cũng như cũng trong việc cạnh tranh và tìm kiếm được nhiều người chơi hơn các nhà cái',
  block3Text4: 'Alo88 đi tiên phong về việc hỗ trợ nội dung hấp dẫn góp phần tiết kiệm thời gian sáng tạo hoặc chi phí cho các đại lý như trực tiếp thể thao - đá gà - phim 18+ sắc nét và sống động, ngoài ra để góp phần phát triển kênh đại lý của các bạn Alo88 sẵn sàng cung cấp đầy đủ thông tin về tỷ lệ kèo giữa các nhà cái hay hình ảnh và bài viết chuẩn SEO mục đích tăng lượng truy cập và trang web của đại lý',
  block3Text5: 'Minh bạch thu nhập hoa hồng mỗi tháng thông qua hỗ trợ theo dõi thu nhập theo thời gian thực 24/7. Đảm bảo an toàn bảo mật danh tính cho đại lý thông qua các hình thức thanh toán bằng tiền điện tử',
  block3Text6: 'Alo88 có đội ngũ chăm sóc khách hàng cực kỳ chuyên nghiệp. Mọi vấn đề hỗ trợ người chơi sẽ được đảm trách bởi đội ngũ nhân viên của Alo88. Bạn chỉ việc kê cao gối ngủ ngon',

  block4Title: 'Lợi ích khi trở thành đại lý của Alo88 là gì?',
  block4Text: 'Chính sách hoa hồng lên đến 50% là mức cao nhất Việt Nam',
  block4Text2: 'Chỉ với 1 thành viên hoạt động vẫn nhận về thu nhập khủng',
  block4Text3: 'Được cung cấp nội dung hấp dẫn phát triển kênh đại lý mà không tốn thời gian và chi phí',
  block4Text4: 'Sản phẩm đa dạng thu hút được mọi người chơi',
  block4Text5: 'Được thanh toán linh hoạt và bảo mật tối đa',
  block4Text6: 'Cơ chế thanh toán linh hoạt cho đại lý VIP',
  block4Text7: 'Hỗ trợ theo dõi Doanh Thu theo thời gian thực 24/7/365',

  block5Title: 'Chính sách hoa hồng',
  block5Text: 'Alo88 cung cấp tài khoản cược của các nhà cái hàng đầu thế giới và châu Á bao gồm SBOBET, BONG88, SV388… Sự tín nhiệm của người chơi vào các thương hiệu hàng đầu và sự đa dạng của các trò chơi là cơ sở để bạn dễ dàng thu hút được số lượng người chơi lớn tham gia CỘNG với chính sách hoa hồng hấp dẫn sẽ giúp bạn được có được một mức thu nhập trong mơ của hàng triệu người.',
  ReadMore: 'Tìm hiểu thêm',
  CommissionEstimate: 'Tính thử hoa hồng',
  netProfit: 'Tiền lãi ròng ',
  memberNumber: 'Số thành viên :',
  estimateCom: 'Hoa hồng dự kiến :',
  count: 'Tính',

  block6Title: 'Điều kiện & điều khoản',
  Details: 'Chi tiết',
  More: 'Xem thêm',

  block7Title: 'Câu hỏi thường gặp',
  block7TabLabel: '1. Vì sao quý khách nên làm đại lý Alo88?',
  block7TabLabel2: '2. Cách thức trở thành đại lý?',
  block7TabLabel3: '3. Làm thế nào để nhận được hoa hồng?',
  block7TabLabel4: '4. Quảng cáo',
  block7TabLabel5: '5. Làm thế nào để nhận đượng hoa hồng',

  block8Title: 'Liên hệ hỗ trợ đại lý',
  block8Text: 'Gặp bất kỳ vấn đề thắc mắc nào vể đại lý hãy liên hệ số dưới bằng điện thoại, viber, zalo, telegram:',


  /* REGISTER PAGE */
  Username: 'Tên truy cập',
  'username/Email': 'Tên truy cập / Email',
  validUsername: 'Vui lòng nhập 5-11 ký tự chữ hoặc số',
  validUsername2: 'Vui lòng nhập tên đăng nhập hoặc email',
  password: 'Mật khẩu',
  newPassword: 'Mật khẩu mới',
  renewPassword: 'Nhập lại mật khẩu mới',
  validPassword: 'Mật khẩu phải là tổ hợp 8-16 ký tự chữ và số',
  Fullname: 'Họ tên',
  validFullname: 'Vui lòng nhập Họ tên',
  Phone: 'Điện thoại',
  validPhone: 'Vui lòng nhập độ dài 9 số, không nhập số 0 đầu',
  yourEmail: 'Email',
  supportRequired: 'Vui lòng chọn hạng mục quý Đại lý cần hỗ trợ',
  howToGetMember: 'Cách tìm kiếm thành viên',
  validHowToGetMember: 'Vui lòng nhập cách thức tìm kiếm thành viên',
  validEmail: 'Vui lòng nhập email của bạn',
  Captcha: 'Mã xác nhận',
  validCaptcha: 'Vui lòng nhập mã xác nhận',
  License: 'Tôi đã đủ 18 tuổi và đồng ý với các <a target="_blank" href="/pages/dieu-kien-va-dieu-khoan">điều kiện & điều khoản của Alo88</a>',
  validLicense: 'Vui lòng chọn đồng ý với điều khoản ở trên',
  RefCode: 'Mã Đại Lý',
  RefCodePlaceHolder: 'Mã Đại Lý (tùy chọn)',
  validRefCode: 'Vui lòng nhập mã đại lý',

  placeUsername: 'Vui lòng nhập 5-11 ký tự chữ hoặc số',
  placePassword: 'Tổ hợp 8-16 ký tự chữ và số',
  placeFullname: 'Điền tên thật như trên CMND',
  placePhone: 'Độ dài 9 đến 10 số, không nhập số 0 đâu',

  compareNewPassErr: 'Mật khẩu nhập lại không trùng khớp',

  refCode: 'Mã giới thiệu',
  refCodePlaceHolder: 'Mã giới thiệu (nếu có)',

  /* REGISTER SUCCESS PAGE */
  RegisterSuccess: 'Đăng ký thành công',
  ActiveSuccess: 'Kích hoạt thành công',
  ActiveFail: 'Kích hoạt thất bại',
  Home: 'Trang chủ',
  /** =============== */
  'Số lượng thành viền của tôi': 'Số lượng thành viên của tôi',
  'Link mời thành viên': 'Link mời thành viên',
  src_lang_vi: '/assets/img/flag-vi.jpg',
  src_lang_en: '/assets/img/flag_us.png',
  'Sao chép': 'Sao chép',
  Xem: 'Xem',
  'Đồng ý': 'Đồng ý',
  'Từ chối': 'Từ chối',
  'Đại lý cấp 2': 'Đại lý cấp 2',
  'Thành viên đại lý cấp 2': 'Số thành viên đại lý cấp 2',
  'Doanh thu từ thành viên': 'Doanh thu từ thành viên',
  'Doanh thu từ đại lý cấp 2': 'Doanh thu từ đại lý cấp 2',
  'Doanh thu tổng doanh thu': 'Tổng doanh thu',
  'Đại lý cấp 2 đăng ký mới': 'Đại lý cấp 2 đăng ký mới',
  'Mã người dùng': 'Mã người dùng',
  'Họ tên': 'Họ tên',
  'Số điện thoại': 'Số điện thoại',
  Email: 'Email',
  'Tương tác': 'Tương tác',
  'Số dư:': 'Số dư:',
  'Doanh thu tháng:': 'Doanh thu tháng:',
  Thoát: 'Thoát',
  'Bảng điều khiển': 'Bảng điều khiển',
  'Danh sách thành viên': 'Danh sách thành viên',
  'Danh sách đại lý cấp 2': 'Danh sách đại lý cấp 2',
  'Thống kê': 'Thống kê',
  'Rút tiền': 'Rút tiền',
  'Xin chào': 'Xin chào',
  'Trang chủ': 'Trang chủ',
  'Họ và tên': 'Họ và tên',
  'Tổng tiền nạp': 'Tổng tiền nạp',
  'Tổng tiền rút': 'Tổng tiền rút',
  'Số dư hiện tại': 'Số dư hiện tại',
  'Doanh thu': 'Doanh thu',
  'Tổng cộng': 'Tổng cộng',
  'User ID, Email': 'User ID, Email',
  'Đại lý': 'Đại lý',
  'Điện thoại': 'Điện thoại',
  'Số thành viên <br> (hoạt động / tổng)': 'Số thành viên <br> (hoạt động / tổng)',
  'Tiền thành viên <br> Đã nạp': 'Tiền thành viên <br> Đã nạp',
  'Tiền thành viên <br> Đã rút': 'Tiền thành viên <br> Đã rút',
  'Thao tác': 'Thao tác',
  'Cấu hình': 'Cấu hình',
  'Khóa quyền': 'Khóa quyền',
  'Mở khóa': 'Mở khóa',
  VND: 'VND',
  'Số dư trước tháng': 'Số dư trước tháng',
  'Thu nhập từ thành viên': 'Doanh thu từ thành viên',
  'Tiền thu nhập từ đại lý': 'Doanh thu từ đại lý',
  'Số dư tháng này': 'Số dư tháng này',
  'Biểu đồ': 'Biểu đồ',
  'Bảng số liệu': 'Bảng số liệu',
  'Số thành viên': 'Số thành viên',
  'Số thành viên hoạt động trong tháng': 'Số thành viên hoạt động trong tháng',
  'Doanh thu trong tháng': 'Doanh thu trong tháng',
  'Số liệu cập nhật lúc': 'Số liệu cập nhật lúc',
  'Doanh số thành viên': 'Doanh thu từ thành viên',
  'Thành viên đăng ký mới': 'Thành viên đăng ký mới',
  'Đại lý đăng ký mới': 'Đại lý đăng ký mới',
  'Số thành viên hoạt động': 'Số thành viên hoạt động',
  'Tổng thành viên nạp': 'Tổng thành viên nạp',
  'Tổng thành viên rút': 'Tổng thành viên rút',
  'Doanh số từ thành viên': 'Doanh thu từ thành viên',
  'Tổng thành viên đại lý nạp': 'Tổng thành viên đại lý nạp',
  'Tổng thành viên đại lý rút': 'Tổng thành viên đại lý rút',
  'Doanh số từ đại lý': 'Doanh thu từ đại lý',
  'Thu nhập trong tháng': 'Doanh thu trong tháng',
  'Số dư khả dụng': 'Số dư khả dụng',
  'Sao kê tài khoản': 'Sao kê tài khoản',
  'Yểu cầu rút tiền': 'Yêu cầu rút tiền',
  'Ngân hàng nhận': 'Ngân hàng nhận',
  Tỉnh: 'Tỉnh',
  'Thành phố': 'Thành phố',
  'Số tài khoản': 'Số tài khoản',
  'Tên tài khoản': 'Tên tài khoản',
  'Số tiền': 'Số tiền',
  'Ghi nhớ cho lần tiếp theo': 'Ghi nhớ cho lần tiếp theo',
  'Gửi yêu cầu': 'Gửi yêu cầu',
  'Yêu cầu thanh toán đã được gửi': 'Yêu cầu thanh toán đã được gửi',
  'Ngân hàng': 'Ngân hàng',
  'Số dư thời điểm': 'Số dư thời điểm',
  'Mã giao dịch': 'Mã giao dịch',
  'text-node-result-witdraw': '(*) Tùy thuộc vào thời gian ngân hàng xử lý, tiền có thể được nhận sớm hay muộn, vui lòng liên hệ với bộ phận CSKH khi có vấn đề',
  'Sao chép mã giao dịch': 'Sao chép mã giao dịch',
  Đóng: 'Đóng',
  Số: 'Số',
  'Ngày giao dịch': 'Ngày giao dịch',
  'Hoạt động': 'Hoạt động',
  'Số dự trước': 'Số dư trước',
  'Doanh thu tháng này': 'Doanh thu tháng này',
  'Số thành viên hoạt động tháng này': 'Số thành viên hoạt động tháng',
  'Tổng kết doanh thu đại lý': 'Tổng kết doanh thu đại lý',
  'Tổng tiền đã nạp': 'Tổng tiền đã nạp',
  'Tổng tiền đã rút': 'Tổng tiền đã rút',
  'Báo cáo thu nhập': 'Báo cáo thu nhập',
  'Mở khóa quyền đại lý': 'Mở khóa quyền đại lý',
  'text-desc-un-lock-popup': 'Đại lý có thể đăng nhập vào trang quản lý sau khi mở khóa. Bạn có muốn thực hiện mở khóa quyền đại lý?',
  Không: 'Không',
  'text-desc-lock-popup': 'Đại lý sẽ không đăng nhập vào trang quản lý sau khi bị khóa. Bạn có muốn thực hiện khóa?',
  'Cấu hình đại lý': 'Cấu hình đại lý',
  'Doanh thu đặt<br> được trong tháng': 'Doanh thu đạt<br> được trong tháng',
  'Thành viên hoạt động tối thiểu trong tháng': 'Thành viên hoạt động tối thiểu trong tháng',
  'Tỉ lệ % hoa hồng': 'Tỉ lệ % hoa hồng',
  'Doanh số bắt đầu từ': 'Lãi ròng bắt đầu từ',
  'Doanh số vượt qua': 'Lãi ròng vượt qua',
  'Nhập số tiền VND': 'Nhập số tiền VND',
  'Lưu cấu hình': 'Lưu cấu hình',
  Hủy: 'Hủy',
  'Thông tin tài khoản': 'Thông tin tài khoản',
  'Thông tin cá nhân': 'Thông tin cá nhân',
  Tên: 'Tên',
  'Ngôn ngữ': 'Ngôn ngữ',
  'Thông tin đăng nhập': 'Thông tin đăng nhập',
  'Tên đăng nhập': 'Tên đăng nhập',
  'Mật khẩu': 'Mật khẩu',
  'Thông tin tỉ lệ hoa hồng': 'Thông tin tỉ lệ hoa hồng',
  Mức: 'Mức',
  'Tổng lợi nhuận rồng hàng tháng': 'Tổng lãi ròng hàng tháng',
  'Số thành viên hoạt động mỗi tháng': 'Số thành viên hoạt động mỗi tháng',
  'Tỉ lệ hoa hồng': 'Tỉ lệ hoa hồng',
  'Thay đổi': 'Thay đổi',
  'Thay đổi mật khẩu': 'Thay đổi mật khẩu',
  'MK cũ': 'Mật khẩu cũ',
  'MK mới': 'Mật khẩu mới',
  'Nhập lại MK mới': 'Nhập lại mật khẩu mới',
  'Nhập mật khẩu cũ': 'Nhập mật khẩu cũ',
  'Nhập mật khẩu mới': 'Nhập mật khẩu mới',
  'Nhập lại mật khẩu mới': 'Nhập lại mật khẩu mới',
  'Đã sao chép thành công': 'Đã sao chép thành công',
  'Tổng doanh thu': 'Tổng doanh thu',
  'Xin chao': 'Xin chào',
  'Link mời đại lý': 'Link mời đại lý cấp 2',
  'User ID': 'User ID',
  'Khóa quyền đại lý': 'Khóa quyền đại lý cấp 2',
  Khóa: 'Khóa',
  'Thu thập trong tháng': 'Doanh thu trong tháng',
  'Số dư tháng trước': 'Số dư tháng trước',
  'Hiển thị': 'Hiển thị',
  Ngày: 'Ngày',
  'Tổng tiền thành viên nạp': 'Tiền thành viên nạp',
  'Tổng tiền thành viên rút': 'Tiền thành viên rút',
  'Tổng tiền TV Đại lý nạp': 'Tiền thành viên Đại lý nạp',
  'Tổng tiền TV Đại lý rút': 'Tiền thành viên Đại lý rút',
  'Doanh số từ TV': 'Doanh thu từ thành viên',
  'Doanh số': 'Doanh thu',
  'select bank': 'Chọn ngân hàng',
  'Số dư trước': 'Số dư trước',
  withdraw: 'Rút tiền',
  'Data invaid': 'Dữ liệu không hợp lệ',
  username: 'tài khoản',
  ok: 'Đồng ý',
  'Empty record': 'Không có dữ liệu',
  'Thu nhập ròng': 'Thu nhập ròng',
  'Tổng tiền cược': 'Doanh thu Cược',
  'Doanh thu thành viên': 'Doanh thu thành viên',
  'Doanh thu từ đại lý': 'Doanh thu từ đại lý',
  'Tiền đã rút': 'Tiền đã rút',
  'Doanh thu từ TV': 'Doanh thu từ thành viên',
  'Live Chat': 'Live Chat',
  'Tất cả': 'Tất cả',
  'Đã từ chối': 'Đã từ chối',
  'Đã đồng ý': 'Đã đồng ý',
  'Đang chờ': 'Đang chờ',
  'select province': 'Chọn tỉnh/thành phố',
  'Chi nhánh': 'Chi nhánh',
  'Data invalid': 'Dữ liệu không hợp lệ',
  'Thành viên hoạt động': 'Thành viên hoạt động',
  'Kích hoạt': 'Kích hoạt',
  'Gửi lại link kích hoạt email': 'Gửi lại link kích hoạt email',
  'Nhập mã xác thực': 'Nhập mã xác thực',
  'Nhập lại mật khẩu mới không trùng khớp': 'Nhập lại mật khẩu mới không trùng khớp',
  'Vui lòng kiểm tra dữ liệu nhập': 'Vui lòng kiểm tra dữ liệu nhập',
  'before-text-desc-commission': '<p>Hoa hồng là tiền chia doanh thu có được từ người chơi mà đại lý có được thông qua các link mời người chơi tham gia</p><p>Alo88 cam kết các mức chia doanh thu tối thiểu như chi tiết bên dưới. Nếu các đại lý hoạt động hiệu quả cao hơn mong đợi, các chính sách về tỷ lệ hoa hồng sẽ được Alo88 tăng thêm cho đại lý.</p>',
  Success: 'Thành công',
  'Trạng thái': 'Trạng thái',
  'Bị từ chối': 'Bị từ chối'
}
