import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ComponentExtend from '../../ComponentExtend'

class Pagination extends ComponentExtend {
  constructor(props) {
    super(props)

    this.state = {
      fromDate: this.props.fromDate,
      toDate: this.props.toDate
    }
    this.applyCalendar = this.applyCalendar.bind(this)
  }

  applyCalendar(key, value) {
    if (key === 'fromDate') {
      this.setState({
        fromDate: moment(value).format('YYYY-MM-DD')
      })
    } else {
      this.setState({
        toDate: moment(value).format('YYYY-MM-DD')
      })
    }
  }

  render() {
    const {
      urlPage = '',
      total_row = 0,
      page = 1,
      limit = 20
    } = this.props
    let total_page = parseInt((total_row / limit), 10)
    const du = total_row % limit
    if (du > 0) {
      total_page += 1
    }
    let firstNum = 1
    let num = total_page
    if (page < 1 || page > total_page) {
      return null
    }
    if (page === 1) {
      num = ((page + 2) > total_page) ? total_page : (page + 2)
    } else if (page === total_page) {
      firstNum = ((total_page - 2) < 1) ? 1 : (total_page - 2)
    } else {
      firstNum = ((page - 1) < 1) ? 1 : (page - 1)
      num = ((page + 1) > total_page) ? total_page : (page + 1)
    }
    const viewPage = []
    for (let i = firstNum; i <= num; i++) {
      const key = i
      viewPage.push(<Link key={key} className={(i === page) ? 'active' : ''} to={`${urlPage}?page=${i}`}>{i}</Link>)
    }
    if (total_page <= 1) {
      return null
    }
    return (
      <div className="afterLogin-pagination">
        <Link to={`${urlPage}?page=1`}><i className="fa fa-fast-backward" aria-hidden="true" /></Link>
        <Link to={`${urlPage}?page=${((page - 1) < 1) ? 1 : (page - 1)}`}><i className="fa fa-backward" aria-hidden="true" /></Link>
        {viewPage}
        <Link to={`${urlPage}?page=${((page + 1) > total_page) ? total_page : (page + 1)}`}><i className="fa fa-forward" aria-hidden="true" /></Link>
        <Link to={`${urlPage}?page=${total_page}`}><i className="fa fa-fast-forward" aria-hidden="true" /></Link>
      </div>
    )
  }
}

export default Pagination
