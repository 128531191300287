import { call, put, takeLatest } from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import { types } from '../../redux/accounts'
import { types as apiTypes } from '../../redux/api'
import { accountsAPI } from '../../api'

function* doAction(action) {
  try {
    yield put({ type: apiTypes.SHOW_LOADING })
    const { code, data } = yield call(accountsAPI.getUrlInviteOfLinkId, action.payload)
    yield put({ type: apiTypes.HIDE_LOADING })
    switch (code) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }
      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield put({ type: apiTypes.HIDE_LOADING })
  }
}

export default function* watchAction() {
  yield takeLatest(types.GET_URL_INVITE_OF_LINK_ID, doAction)
}
